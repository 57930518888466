@charset "UTF-8";
@font-face {
    font-family: Barlow;
    font-display: swap;
    /*src: url("../fonts/Barlow-SemiBold.woff2") format("woff2");*/
    src: url("../fonts/MazzardH-Regular.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: MazzardH;
    font-display: swap;
    src: url("../fonts/MazzardH-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Barlow;
    font-display: swap;
    /*src: url("../fonts/Barlow-Medium.woff2") format("woff2");*/
    src: url("../fonts/MazzardH-Regular.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Barlow;
    font-display: swap;
    /*src: url("../fonts/Barlow-Regular.woff2") format("woff2");*/
    src: url("../fonts/MazzardH-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: MazzardM;
    font-display: swap;
    src: url("../fonts/MazzardM-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: MazzardM;
    font-display: swap;
    src: url("../fonts/MazzardM-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Oswald;
    font-display: swap;
    src: url("../fonts/Oswald-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: MazzardM;
    font-display: swap;
    src: url("../fonts/MazzardM-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    font-display: swap;
    src: url("../fonts/Poppins-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    font-display: swap;
    src: url("../fonts/Poppins-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: MazzardM;
    font-display: swap;
    src: url("../fonts/MazzardM-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

* {
    padding: 0px;
    margin: 0px;
    border: 0px;
    scroll-behavior: smooth;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

aside,
nav,
footer,
header,
section {
    display: block;
}

html,
body {
    height: 100%;
    min-width: 320px;
}

body {
    line-height: 1;
    font-family: "MazzardM";
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
    font-family: "MazzardM";
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    font-size: inherit;
}

body {
    color: #000;
    font-size: 14px;
}

body.lock {
    overflow: hidden;
    position: relative;
    min-height: 100%;
}

body.lock::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6980392157);
    z-index: 150;
}

.product-select {
    overflow: hidden;
    position: relative;
    min-height: 100%;
}

.product-select::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1000%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    /*// backdrop-filter: blur(2px);*/
    z-index: 150;
}

.wrapper {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    position: relative;
}

.wrapper::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #051223;
}

.wrapper::after {
    position: absolute;
    content: "";
    width: 35.7%;
    padding: 0 0 35.7% 0;
    border-radius: 50%;
    top: 196px;
    left: 110px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(350px);
    z-index: 20;
}

.container {
    max-width: 1592px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
}

.sidebar {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 165px;
    padding: 10px 10px 20px 10px;
    background-color: #051223;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 101;
}

@media (max-width: 991.98px) {
    .sidebar.header-fixed {
        padding: 3px 10px 20px 20px;
    }
}

.sidebar::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(8, 28, 54, 0.6980392157);
}

@media (max-width: 1550px) {
    .sidebar {
        padding: 10px 10px 20px 10px;
        width: 135px;
    }
}

@media (max-width: 1500px) {
    .sidebar {
        padding: 10px 10px 20px 10px;
        width: 112px;
    }
}

@media (max-width: 991.98px) {
    .sidebar {
        background-color: transparent;
        z-index: 0;
        width: 87px;
        padding: 6px 10px 20px 20px;
    }

    .sidebar::before {
        background: transparent;
    }
}

@media (max-width: 479.98px) {
    .sidebar {
        padding: 5px 10px 20px 20px;
    }
}

.sidebar__body {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

@media (max-width: 991.98px) {
    .sidebar__body {
        padding: 20px 0 15px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.0784313725);
    }
}

.sidebar__logo {
    position: relative;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.sidebar__logo:not(:last-child) {
    margin: 0 0 50px 0;
}

@media (max-width: 1700px) {
    .sidebar__logo:not(:last-child) {
        margin: 0 0 40px 0;
    }
}

@media (max-width: 1550px) {
    .sidebar__logo:not(:last-child) {
        margin: 0 0 35px 0;
    }
}

@media (max-width: 1500px) {
    .sidebar__logo:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 1200px) {
    .sidebar__logo:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 991.98px) {
    .sidebar__logo {
        position: absolute;
        left: 20px;
        top: 6px;
    }
}

.sidebar__menu:not(:last-child) {
    margin: 0 0 17px 0;
}

.sidebar__development a,
.sidebar__development {
    font-size: 12px;
    line-height: 158.3333333333%;
    text-align: center;
    color: #97A0A9;
}

.sidebar__development {
    margin-top: auto;
}

@media (max-width: 1500px) {
    .sidebar__development {
        font-size: 11px;
    }
}

@media (max-width: 991.98px) {
    .sidebar__development {
        font-size: 12px;
    }

    .sidebar__development br {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .menu-sidebar__list {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        font-size: 0;
    }
}

.menu-sidebar__item:not(:last-child) {
    margin: 0 0 15px 0;
}

@media (max-width: 991.98px) {
    .menu-sidebar__item:not(:last-child) {
        margin: 0 15px 0 0;
    }
}

@media (max-width: 479.98px) {
    .menu-sidebar__item:not(:last-child) {
        margin: 0 10px 0 0;
    }
}

.menu-sidebar__link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #042148;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.menu-sidebar__link:hover {
    background: #052a5a;
}

.menu-sidebar__link img:not(:last-child) {
    margin: 0 0 9px 0;
}

.menu-sidebar__link span {
    font-size: 15px;
    line-height: 120%;
    color: #8E9BAC;
}

@media (max-width: 1550px) {
    .menu-sidebar__link {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 1500px) {
    .menu-sidebar__link {
        width: 83px;
        height: 83px;
    }

    .menu-sidebar__link span {
        font-size: 11px;
    }
}

@media (max-width: 991.98px) {
    .menu-sidebar__link {
        background: #0D1A28;
    }
}

@media (max-width: 479.98px) {
    .menu-sidebar__link {
        width: 69px;
        height: 69px;
    }

    .menu-sidebar__link span {
        font-size: 13px;
    }

    .menu-sidebar__link img {
        width: 20px;
        height: 20px;
    }
}

.logo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    width: 101px;
    height: 101px;
}

@media (max-width: 1550px) {
    .logo {
        width: 88px;
        height: 88px;
    }
}

@media (max-width: 1500px) {
    .logo {
        width: 73px;
        height: 73px;
    }
}

@media (max-width: 991.98px) {
    .logo {
        width: 50px;
        height: 50px;
    }
}

.logo img {
    max-width: 100%;
}

.btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: #FF6C3D;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    color: #FFFFFF;
}

.btn:hover {
    background-color: #e76134;
}

.btn_small {
    width: 114px;
    height: 114px;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
}

@media (max-width: 1700px) {
    .btn_small {
        width: 106px;
        height: 106px;
    }
}

@media (max-width: 1550px) {
    .btn_small {
        width: 99px;
        height: 99px;
    }
}

@media (max-width: 1200px) {
    .btn_small {
        font-size: 15px;
        width: 92px;
        height: 92px;
    }
}

@media (max-width: 991.98px) {
    .btn_small {
        width: 85px;
        height: 85px;
    }
}

@media (max-width: 767.98px) {
    .btn_small {
        width: 78px;
        height: 78px;
    }
}

@media (max-width: 479.98px) {
    .btn_small {
        font-size: 14px;
        width: 71px;
        height: 71px;
    }
}

.btn_medium {
    width: 173px;
    height: 173px;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.01em;
}

@media (max-width: 1700px) {
    .btn_medium {
        font-size: 15px;
        width: 158px;
        height: 158px;
    }
}

@media (max-width: 1550px) {
    .btn_medium {
        width: 141px;
        height: 141px;
    }
}

@media (max-width: 1200px) {
    .btn_medium {
        font-size: 14px;
        width: 126px;
        height: 126px;
    }
}

@media (max-width: 991.98px) {
    .btn_medium {
        font-size: 13px;
        width: 111px;
        height: 111px;
    }
}

@media (max-width: 767.98px) {
    .btn_medium {
        width: 96px;
        height: 96px;
    }
}

@media (max-width: 479.98px) {
    .btn_medium {
        width: 81px;
        height: 81px;
        font-size: 12px;
    }
}

.btn_big {
    width: 232px;
    height: 232px;
    font-size: 22px;
    font-weight: 600;
    line-height: 118.1818181818%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}

.btn_big img:not(:last-child) {
    margin: 0 0 13px 0;
}

@media (max-width: 479.98px) {
    .btn_big img:not(:last-child) {
        margin: 0 0 10px 0;
    }
}

@media (max-width: 1700px) {
    .btn_big {
        width: 212.5px;
        height: 212.5px;
        font-size: 21px;
    }
}

@media (max-width: 1550px) {
    .btn_big {
        width: 194px;
        height: 194px;
        font-size: 19px;
    }
}

@media (max-width: 1200px) {
    .btn_big {
        width: 175.5px;
        height: 175.5px;
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .btn_big {
        width: 157px;
        height: 157px;
        font-size: 15px;
    }
}

@media (max-width: 767.98px) {
    .btn_big {
        width: 138.5px;
        height: 138.5px;
        font-size: 13px;
    }
}

@media (max-width: 479.98px) {
    .btn_big {
        width: 120px;
        height: 120px;
        font-size: 12px;
    }
}

.btn_white {
    background-color: #FFFFFF;
    color: #FF6C3D;
}

.btn_white:hover {
    background-color: #e2e1e1;
}

.title-h2 {
    font-family: Barlow;
    font-size: 100px;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    text-transform: uppercase;
}

@media (max-width: 1700px) {
    .title-h2 {
        font-size: 88px;
    }
}

@media (max-width: 1550px) {
    .title-h2 {
        font-size: 77px;
    }
}

@media (max-width: 1200px) {
    .title-h2 {
        font-size: 66px;
    }
}

@media (max-width: 991.98px) {
    .title-h2 {
        font-size: 54px;
    }
}

@media (max-width: 767.98px) {
    .title-h2 {
        font-size: 43px;
    }
}

@media (max-width: 479.98px) {
    .title-h2 {
        font-size: 32px;
    }
}

.title-h3 {
    font-family: Barlow;
    font-size: 70px;
    line-height: 117.6470588235%;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    text-transform: uppercase;
}

@media (max-width: 1700px) {
    .title-h3 {
        font-size: 60px;
    }
}

@media (max-width: 1550px) {
    .title-h3 {
        font-size: 66px;
    }
}

@media (max-width: 1200px) {
    .title-h3 {
        font-size: 57px;
    }
}

@media (max-width: 991.98px) {
    .title-h3 {
        font-size: 47px;
    }
}

@media (max-width: 767.98px) {
    .title-h3 {
        font-size: 38px;
    }
}

@media (max-width: 479.98px) {
    .title-h3 {
        font-size: 28px;
    }
}

.title-h3_dark {
    color: #061325;
}

.title-h4 {
    font-family: Barlow;
    font-size: 70px;
    font-weight: 400;
    line-height: 107.1428571429%;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media (max-width: 1700px) {
    .title-h4 {
        font-size: 61px;
    }
}

@media (max-width: 1550px) {
    .title-h4 {
        font-size: 53px;
    }
}

@media (max-width: 1200px) {
    .title-h4 {
        font-size: 45px;
    }
}

@media (max-width: 991.98px) {
    .title-h4 {
        font-size: 36px;
    }
}

@media (max-width: 767.98px) {
    .title-h4 {
        font-size: 28px;
    }
}

@media (max-width: 479.98px) {
    .title-h4 {
        font-size: 20px;
    }
}

.title-h5 {
    font-family: Barlow;
    font-size: 45px;
    font-weight: 600;
    line-height: 122.2222222222%;
    color: #FFFFFF;
    text-transform: uppercase;
}

@media (max-width: 1700px) {
    .title-h5 {
        font-size: 41px;
    }
}

@media (max-width: 1550px) {
    .title-h5 {
        font-size: 37px;
    }
}

@media (max-width: 1200px) {
    .title-h5 {
        font-size: 33px;
    }
}

@media (max-width: 991.98px) {
    .title-h5 {
        font-size: 30px;
    }
}

@media (max-width: 767.98px) {
    .title-h5 {
        font-size: 26px;
    }
}

@media (max-width: 479.98px) {
    .title-h5 {
        font-size: 22px;
    }
}

.label {
    font-family: Barlow;
    font-size: 24px;
    font-weight: 600;
    line-height: 145.8333333333%;
    color: #3167B5;
}

@media (max-width: 1700px) {
    .label {
        font-size: 23px;
    }
}

@media (max-width: 1550px) {
    .label {
        font-size: 22px;
    }
}

@media (max-width: 1200px) {
    .label {
        font-size: 21px;
    }
}

@media (max-width: 991.98px) {
    .label {
        font-size: 20px;
    }
}

@media (max-width: 767.98px) {
    .label {
        font-size: 19px;
    }
}

@media (max-width: 479.98px) {
    .label {
        font-size: 18px;
    }
}

.breadcrumbs {
    margin: 0 0 0 165px;
    position: relative;
    padding: 88px 0 0 35px;
}

@media (max-width: 1550px) {
    .breadcrumbs {
        padding: 78.5px 0 0 25px;
        margin: 0 0 0 135px;
    }
}

@media (max-width: 1500px) {
    .breadcrumbs {
        padding: 76px 0 0 25px;
        margin: 0 0 0 122px;
    }
}

@media (max-width: 991.98px) {
    .breadcrumbs {
        padding: 84px 0 0 20px;
        margin: 0;
    }
}

@media (max-width: 479.98px) {
    .breadcrumbs {
        padding: 72px 0 0 20px;
    }
}

.breadcrumbs__list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
}

.breadcrumbs__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 121.4285714286%;
    color: #FFFFFF;
    position: relative;
}

.breadcrumbs__item:not(:last-child) {
    margin: 0 21px 0 0;
}

.breadcrumbs__item:not(:last-child)::before {
    position: absolute;
    content: "";
    width: 4px;
    height: 6px;
    right: -12px;
    background: url("../img/icons/arrow-breadcrumbs.svg") center no-repeat;
}

.breadcrumbs__link {
    color: #FFFFFF;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.breadcrumbs__link:hover {
    text-decoration: underline;
}

@media (min-width: 991.98px) {
    .animated-block {
        opacity: 0; /* По замовчуванню блок прихований */
        transform: translate(500px, 0); /* Зсуваємо блок вниз на 20px */
        transition: opacity 0.5s ease, transform 1s ease; /* Анімація для opacity і transform */
    }
}

@media (min-width: 991.98px) {
    .animated-block.visible {
        opacity: 1; /* Змінюємо прозорість на 1 для з'явлення */
        transform: translate(0, 0); /* Зсуваємо блок назад в початкове положення */
    }
}

.dropdown {
    position: relative;
}

.dropdown__button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    position: relative;
    height: fit-content;
    min-height: 53px;
    width: 100%;
    border-radius: 7px;
    padding: 5px 41px 5px 17px;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    line-height: 138.8888888889%;
    color: #DFDFDF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    border: 1px solid rgba(255, 255, 255, 0.4);
}

@media (max-width: 1200px) {
    .dropdown__button {
        height: 49px;
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .dropdown__button {
        height: 44px;
        font-size: 16px;
        padding: 5px 41px 5px 15px;
    }
}

.dropdown__button::before {
    position: absolute;
    content: "";
    transform: translate(0, -50%);
    right: 18px;
    width: 14px;
    height: 8.2px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: url("../img/icons/arrow-white.svg") center no-repeat;
    background-size: cover;
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

@media (max-width: 1200px) {
    .dropdown__button::before {
        width: 13px;
        height: 7.5px;
    }
}

@media (max-width: 479.98px) {
    .dropdown__button::before {
        width: 12px;
        height: 7px;
        right: 15px;
    }
}

.dropdown__button.active::before {
    transform: rotate(0);
}

.dropdown__list {
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    right: 0;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: #1D2A3E;
    overflow: hidden;
    z-index: 2;
}

.dropdown__list.visible {
    display: block;
}

@media (max-width: 1200px) {
    .dropdown__list {
        top: 54px;
    }
}

@media (max-width: 479.98px) {
    .dropdown__list {
        top: 49px;
    }
}

.dropdown__list-item {
    display: flex;
    align-items: center;
    height: fit-content;
    min-height: 53px;
    padding: 5px 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    font-size: 18px;
    line-height: 138.8888888889%;
    color: #DFDFDF;
}

.dropdown__list-item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.dropdown__list-item:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
}

@media (max-width: 1200px) {
    .dropdown__list-item {
        height: 49px;
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .dropdown__list-item {
        height: 44px;
        font-size: 16px;
    }
}

.dropdown__input-hidden {
    display: none;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 18, 43, 0.8980392157);
    opacity: 0;
    visibility: hidden;
    transition: all 0.8s ease 0s;
    overflow-y: auto;
    z-index: 1000;
    /*// backdrop-filter: blur(2px);*/
}

.popup.open {
    opacity: 1;
    visibility: visible;
}

.popup.open .popup__content {
    transform: perspective(600px) translate(0, 0) rotateX(0deg);
    opacity: 1;
}

.popup__body {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
}

.popup__content {
    background-color: #fff;
    width: 100%;
    max-width: 890px;
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    transform: perspective(600px) translate(0, -100%) rotateX(45deg);
    opacity: 0;
    transition: all 0.8s ease 0s;
}

.popup__title {
    font-family: Barlow;
    font-size: 50px;
    font-weight: 400;
    line-height: 112.5%;
    text-transform: uppercase;
    color: #2B2B2B;
}

@media (max-width: 1700px) {
    .popup__title {
        font-size: 72px;
    }
}

@media (max-width: 1550px) {
    .popup__title {
        font-size: 64px;
    }
}

@media (max-width: 1200px) {
    .popup__title {
        font-size: 56px;
    }
}

@media (max-width: 991.98px) {
    .popup__title {
        font-size: 48px;
    }
}

@media (max-width: 767.98px) {
    .popup__title {
        font-size: 40px;
    }
}

@media (max-width: 479.98px) {
    .popup__title {
        font-size: 32px;
    }
}

.popup__btn-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    justify-content: flex-end;
}

.popup-authorization .popup__content {
    padding: 75px 70px 50px 85px;
}

@media (max-width: 1700px) {
    .popup-authorization .popup__content {
        padding: 66.5px 62px 45px 75px;
    }
}

@media (max-width: 1550px) {
    .popup-authorization .popup__content {
        padding: 58px 54px 40px 65px;
    }
}

@media (max-width: 1200px) {
    .popup-authorization .popup__content {
        padding: 49.5px 46.5px 36px 55px;
    }
}

@media (max-width: 991.98px) {
    .popup-authorization .popup__content {
        padding: 41px 39px 32px 44.5px;
    }
}

@media (max-width: 767.98px) {
    .popup-authorization .popup__content {
        padding: 32.5px 31.5px 28px 34px;
    }
}

@media (max-width: 479.98px) {
    .popup-authorization .popup__content {
        padding: 24px;
    }
}

@media (max-width: 479.98px) {
    .popup-authorization .inner-popup:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

.popup-authorization .inner-popup .inner-popup__content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
}

.popup-authorization .inner-popup__click {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.popup-authorization .inner-popup__click:hover .inner-popup__link {
    text-decoration: none;
}

.popup-authorization .popup__title:not(:last-child) {
    margin: 0 0 38px 0;
}

@media (max-width: 1200px) {
    .popup-authorization .popup__title:not(:last-child) {
        margin: 0 0 33px 0;
    }
}

@media (max-width: 479.98px) {
    .popup-authorization .popup__title:not(:last-child) {
        margin: 0 0 28px 0;
    }
}

.popup-top-up .popup__content {
    padding: 60px 65px 20px 50px;
}

@media (max-width: 1700px) {
    .popup-top-up .popup__content {
        padding: 70px 60px 30px 63px;
    }
}

@media (max-width: 1550px) {
    .popup-top-up .popup__content {
        padding: 60px 66px 30px 56px;
    }
}

@media (max-width: 1200px) {
    .popup-top-up .popup__content {
        padding: 50px 57px 30px 49.5px;
    }
}

@media (max-width: 991.98px) {
    .popup-top-up .popup__content {
        padding: 40px 48px 30px 43px;
    }
}

@media (max-width: 767.98px) {
    .popup-top-up .popup__content {
        padding: 30px 39px 30px 36.5px;
    }
}

@media (max-width: 479.98px) {
    .popup-top-up .popup__content {
        padding: 20px 30px 30px 30px;
    }
}

@media (max-width: 350px) {
    .popup-top-up .popup__content {
        padding: 20px 25px 25px 25px;
    }
}

.popup-top-up .inner-popup {
    display: block;
}

.popup-top-up .inner-popup:not(:last-child) {
    margin: 0 0 30px 0;
}

@media (max-width: 1200px) {
    .popup-top-up .inner-popup:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 479.98px) {
    .popup-top-up .inner-popup:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

.popup-top-up .popup__title {
    max-width: 672px;
}

.popup-top-up .popup__title:not(:last-child) {
    margin: 0 0 45px 0;
}

@media (max-width: 1550px) {
    .popup-top-up .popup__title:not(:last-child) {
        margin: 0 0 40px 0;
    }
}

@media (max-width: 1200px) {
    .popup-top-up .popup__title:not(:last-child) {
        margin: 0 0 32.5px 0;
    }
}

@media (max-width: 991.98px) {
    .popup-top-up .popup__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 767.98px) {
    .popup-top-up .popup__title:not(:last-child) {
        margin: 0 0 17.5px 0;
    }
}

@media (max-width: 479.98px) {
    .popup-top-up .popup__title:not(:last-child) {
        margin: 0 0 10px 0;
    }
}

.inner-popup {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.inner-popup__image {
    flex: 0 0 90px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 20px 0 0;
}

.inner-popup__image img {
    max-width: 100%;
}

@media (max-width: 1550px) {
    .inner-popup__image {
        flex: 0 0 80px;
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 991.98px) {
    .inner-popup__image {
        flex: 0 0 70px;
        width: 70px;
        height: 70px;
        margin: 0 15px 0 0;
    }
}

@media (max-width: 767.98px) {
    .inner-popup__image {
        flex: 0 0 60px;
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 479.98px) {
    .inner-popup__image {
        flex: 0 0 49px;
        width: 49px;
        height: 49px;
        margin: 0 9px 0 0;
    }
}

.inner-popup__label {
    font-size: 18px;
    font-weight: 600;
    line-height: 122.2222222222%;
    color: #041D3C;
}

.inner-popup__text {
    font-size: 14px;
    line-height: 157.1428571429%;
    color: #666666;
}

.inner-popup__link {
    color: #666666;
    text-decoration: underline;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.inner-popup__payment {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    /* padding: 35px 0; */
    font-size: 24px;
    font-weight: 600;
    color: #2B2B2B;
    border-top: 2px solid #ECECEC;
    border-bottom: 2px solid #ECECEC;
}

.inner-popup__payment span {
    font-size: 30px;
}

@media (max-width: 1700px) {
    .inner-popup__payment {
        padding: 30px 0;
        font-size: 23px;
    }

    .inner-popup__payment span {
        font-size: 29px;
    }
}

@media (max-width: 1550px) {
    .inner-popup__payment {
        font-size: 22px;
    }

    .inner-popup__payment span {
        font-size: 28px;
    }
}

@media (max-width: 1200px) {
    .inner-popup__payment {
        padding: 25px 0;
        font-size: 21px;
    }

    .inner-popup__payment span {
        font-size: 27px;
    }
}

@media (max-width: 991.98px) {
    .inner-popup__payment {
        padding: 20px 0;
        font-size: 20px;
    }

    .inner-popup__payment span {
        font-size: 26px;
    }
}

@media (max-width: 767.98px) {
    .inner-popup__payment {
        font-size: 19px;
    }

    .inner-popup__payment span {
        font-size: 25px;
    }
}

@media (max-width: 479.98px) {
    .inner-popup__payment {
        padding: 15px 0;
        font-size: 18px;
    }

    .inner-popup__payment span {
        font-size: 24px;
    }
}

.inner-popup__close {
    position: relative;
    overflow: visible;
    box-shadow: 0 0 50px 2px rgba(225, 72, 23, 0.5019607843);
}

.papayment-method-inner-popup {
    padding: 25px 0 0 0;
}

.papayment-method-inner-popup:not(:last-child) {
    margin: 0 0 15px 0;
}

@media (max-width: 1200px) {
    .papayment-method-inner-popup {
        padding: 20px 0 0 0;
    }
}

@media (max-width: 991.98px) {
    .papayment-method-inner-popup:not(:last-child) {
        margin: 0 0 10px 0;
    }
}

@media (max-width: 479.98px) {
    .papayment-method-inner-popup {
        padding: 15px 0 0 0;
    }

    .papayment-method-inner-popup:not(:last-child) {
        margin: 0 0 5px 0;
    }
}

.papayment-method-inner-popup__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 122.2222222222%;
    color: #2B2B2B;
    text-align: center;
}

.papayment-method-inner-popup__title:not(:last-child) {
    margin: 0 0 20px 0;
}

@media (max-width: 1550px) {
    .papayment-method-inner-popup__title {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .papayment-method-inner-popup__title {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .papayment-method-inner-popup__title {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .papayment-method-inner-popup__title {
        font-size: 14px;
    }

    .papayment-method-inner-popup__title:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

.papayment-method-inner-popup__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    justify-content: center;
}

@media (max-width: 479.98px) {
    .papayment-method-inner-popup__row {
        justify-content: space-between;
    }
}

.papayment-method-inner-popup__column:not(:last-child) {
    margin: 0 28px 0 0;
}

/*.papayment-method-inner-popup__column:last-child .papayment-method-inner-popup__item {*/
/*    padding: 30px 45px;*/
/*}*/

/*@media (max-width: 991.98px) {*/
/*    .papayment-method-inner-popup__column:last-child .papayment-method-inner-popup__item {*/
/*        padding: 30px 35px;*/
/*    }*/
/*}*/

/*@media (max-width: 767.98px) {*/
/*    .papayment-method-inner-popup__column:last-child .papayment-method-inner-popup__item {*/
/*        padding: 30px;*/
/*    }*/
/*}*/

/*@media (max-width: 479.98px) {*/
/*    .papayment-method-inner-popup__column:last-child .papayment-method-inner-popup__item {*/
/*        padding: 30px 25px;*/
/*    }*/
/*}*/

/*@media (max-width: 400px) {*/
/*    .papayment-method-inner-popup__column:last-child .papayment-method-inner-popup__item {*/
/*        padding: 30px 20px;*/
/*    }*/
/*}*/

/*@media (max-width: 375px) {*/
/*    .papayment-method-inner-popup__column:last-child .papayment-method-inner-popup__item {*/
/*        padding: 30px 17.5px;*/
/*    }*/
/*}*/

/*@media (max-width: 350px) {*/
/*    .papayment-method-inner-popup__column:last-child .papayment-method-inner-popup__item {*/
/*        padding: 30px 15px;*/
/*    }*/
/*}*/

/*@media (max-width: 479.98px) {*/
/*    .papayment-method-inner-popup__column:not(:last-child) {*/
/*        margin: 0 15px 0 0;*/
/*    }*/
/*}*/

.papayment-method-inner-popup__item {
    width: 260px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid #ECECEC;
    height: 120px;
    padding: 30px 15px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.papayment-method-inner-popup__item:hover {
    border: 2px solid #bebebe;
}

@media (max-width: 1550px) {
    .papayment-method-inner-popup__item {
        width: 240px;
        height: 110px;
    }
}

@media (max-width: 1200px) {
    .papayment-method-inner-popup__item {
        width: 220px;
        height: 100px;
    }
}

@media (max-width: 991.98px) {
    .papayment-method-inner-popup__item {
        width: 200px;
        height: 90px;
    }
}

@media (max-width: 767.98px) {
    .papayment-method-inner-popup__item {
        width: 180px;
        height: 80px;
    }
}

@media (max-width: 479.98px) {
    .papayment-method-inner-popup__item {
        width: 160px;
        height: 70px;
        padding: 30px 12.5px;
    }
}

@media (max-width: 400px) {
    .papayment-method-inner-popup__item {
        width: 140px;
        height: 60px;
        padding: 30px 10px;
    }
}

@media (max-width: 375px) {
    .papayment-method-inner-popup__item {
        width: 130px;
        height: 55px;
        padding: 30px 7.5px;
    }
}

@media (max-width: 350px) {
    .papayment-method-inner-popup__item {
        width: 120px;
        height: 50px;
    }
}

.papayment-method-inner-popup__image img {
    max-width: 100%;
}

.users {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
}

.users__swiper {
    width: 100%;
    max-width: 1195px;
    padding: 0 0 55px 0;
}

@media (max-width: 767.98px) {
    .users__swiper {
        padding: 0 0 45px 0 !important;
    }
}

@media (max-width: 479.98px) {
    .users__swiper {
        padding: 0 0 40px 0 !important;
    }
}

.users__item {
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
    padding: 20px 17.5px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    position: relative;
}

.users__item:not(:last-child) {
    margin: 0 0 15px 0;
}

.users__item::before {
    position: absolute;
    /*content: "";*/
    width: 104px;
    height: 104px;
    border-radius: 50%;
    top: 12px;
    left: -27px;
    background: rgba(0, 92, 211, 0.8);
    filter: blur(50px);
    z-index: 2;
}

.users__item::after {
    position: absolute;
    /*content: "";*/
    width: 104px;
    height: 104px;
    border-radius: 50%;
    bottom: 15px;
    left: 2px;
    background: #FF6C3D;
    filter: blur(50px);
    z-index: 1;
}

@media (max-width: 1550px) {
    .users__item {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .users__item {
        font-size: 16px;
        padding: 17.5px 15px;
    }
}

@media (max-width: 767.98px) {
    .users__item {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .users__item {
        font-size: 14px;
        padding: 15px;
    }
}

.users__inner {
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
}

.users__inner::before {
    position: absolute;
    /*content: "";*/
    width: 104px;
    height: 104px;
    border-radius: 50%;
    top: 2px;
    left: 333px;
    background: rgba(0, 92, 211, 0.8);
    filter: blur(50px);
    z-index: 2;
}

@media (max-width: 767.98px) {
    .users__inner::before {
        left: 300px;
    }
}

@media (max-width: 479.98px) {
    .users__inner::before {
        left: auto;
        top: -90%;
        right: 0px;
        filter: blur(80px);
    }
}

.users__inner::after {
    position: absolute;
    /*content: "";*/
    width: 104px;
    height: 104px;
    border-radius: 50%;
    bottom: 25px;
    left: 362px;
    background: #FF6C3D;
    filter: blur(50px);
    z-index: 1;
}

@media (max-width: 767.98px) {
    .users__inner::after {
        left: 330px;
    }
}

@media (max-width: 479.98px) {
    .users__inner::after {
        display: none;
    }
}

@media (max-width: 479.98px) {
    .users__inner {
        justify-content: space-between;
        align-items: flex-start;
    }
}

.users__body {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
}

@media (max-width: 479.98px) {
    .users__body {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 20px 0 0;
    }
}

.users__name-block {
    display: flex;
    align-items: center;
}

.users__name {
    margin: 0 28px 0 0;
}

@media (max-width: 767.98px) {
    .users__name {
        margin: 0 20px 0 0;
    }
}

@media (max-width: 479.98px) {
    .users__name {
        margin: 0 13px 0 0;
    }
}

.users__game {
    margin: 0 28px;
}

@media (max-width: 767.98px) {
    .users__game {
        margin: 0 20px;
    }
}

@media (max-width: 479.98px) {
    .users__game {
        margin: 4px 0 0 0;
    }
}

.users__level {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    font-weight: 500;
    padding: 5px 18px;
    height: 35px;
}

@media (max-width: 479.98px) {
    .users__level {
        padding: 5px 15px;
    }
}

.users__navigation {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transform: translate(0, 35px);
}

.users__btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 0 0 45px;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    overflow: hidden;
    background: url("../img/icons/arrow-white.svg") center no-repeat;
    background-color: #FF6C3D;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.users__btn:hover {
    background-color: #e76134;
}

.users__btn_prev {
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin: 0 6px 0 0;
}

.users__btn_next {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 0 0 0 6px;
}

@media (max-width: 767.98px) {
    .users__btn {
        flex: 0 0 38px;
        width: 38px;
        height: 38px;
    }
}

@media (max-width: 479.98px) {
    .users__btn {
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
    }
}

.users__pagination {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    width: auto !important;
}

.users__pagination .swiper-pagination-bullet {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    font-size: 18px;
    line-height: 177.7777777778%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    opacity: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.users__pagination .swiper-pagination-bullet:hover {
    border: 1px solid rgba(255, 108, 61, 0.4);
    background: linear-gradient(0deg, rgba(255, 108, 61, 0.1), rgba(255, 108, 61, 0.1));
}

@media (max-width: 767.98px) {
    .users__pagination .swiper-pagination-bullet {
        flex: 0 0 38px;
        width: 38px;
        height: 38px;
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .users__pagination .swiper-pagination-bullet {
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
        font-size: 16px;
    }
}

.users__pagination .swiper-pagination-bullet-active {
    border: 1px solid rgba(255, 108, 61, 0.4);
    background: linear-gradient(0deg, rgba(255, 108, 61, 0.1), rgba(255, 108, 61, 0.1));
}

.game-users {
    display: flex;
    align-items: center;
}

.game-users:hover .game-users__name {
    text-decoration-color: #FFFFFF;
}

.game-users:hover .game-users__icon {
    box-shadow: 0 0 5px 1px rgba(255, 255, 255, 0.3);
}

.game-users__icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 0 0 38px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2509803922);
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.game-users__icon img {
    width: 28px;
    height: 26px;
}

@media (max-width: 479.98px) {
    .game-users__icon {
        flex: 0 0 35px;
        width: 35px;
        height: 35px;
    }

    .game-users__icon img {
        -moz-transform: scale(0.92);
        -ms-transform: scale(0.92);
        -webkit-transform: scale(0.92);
        -o-transform: scale(0.92);
        transform: scale(0.92);
    }
}

.game-users__name {
    color: #FFFFFF;
    text-decoration: underline;
    text-decoration-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    text-decoration-thickness: 1px;
}

.points-users {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    margin: 0 0 0 20px;
}

@media (max-width: 767.98px) {
    .points-users {
        margin: 0 0 0 15px;
    }
}

@media (max-width: 479.98px) {
    .points-users {
        flex: 1 1 100%;
        margin: 9px 0 0 0;
        justify-content: space-between;
    }
}

.points-users__numner {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 35px;
    border-radius: 7px;
    overflow: hidden;
    padding: 5px 18px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    margin: 0 20px 0 0;
}

@media (max-width: 767.98px) {
    .points-users__numner {
        margin: 0 15px 0 0;
    }
}

.points-users__game {
    margin: 0 0 0 20px;
}

@media (max-width: 767.98px) {
    .points-users__game {
        margin: 0 0 0 15px;
    }
}

.game-points-users {
    display: flex;
    align-items: center;
}

.game-points-users__image {
    position: relative;
    height: 35px;
    width: 62px;
    border-radius: 7px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin: 0 16px 0 0;
}

.game-points-users__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inner {
    position: relative;
    z-index: 250;
}

.inner__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
}

@media (min-width: 1100px) {
    .inner__row {
        align-items: flex-start;
    }
}

@media (max-width: 1100px) {
    .inner__row {
        flex-direction: column;
    }
}

.inner__menu {
    flex: 0 0 385px;
    margin: 0 20px 0 0;
}

@media (max-width: 1550px) {
    .inner__menu {
        flex: 0 0 350px;
    }
}

@media (max-width: 1200px) {
    .inner__menu {
        flex: 0 0 320px;
    }
}

@media (max-width: 1100px) {
    .inner__menu {
        flex: auto;
    }

    .inner__menu:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 991.98px) {
    .inner__menu:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 767.98px) {
    .inner__menu:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

.inner__content {
    flex: 1 1 100%;
}

.menu-inner {
    border-radius: 30px;
    border: 1px solid #2C4463;
    background-color: rgba(8, 28, 54, 0.7);
    padding: 40px 30px;
}

@media (max-width: 1700px) {
    .menu-inner {
        padding: 37.5px 27.5px;
    }
}

@media (max-width: 1550px) {
    .menu-inner {
        padding: 35px 27.5px;
    }
}

@media (max-width: 1200px) {
    .menu-inner {
        padding: 32.5px 25px;
    }
}

@media (max-width: 1100px) {
    .menu-inner {
        padding: 25px;
    }
}

@media (max-width: 991.98px) {
    .menu-inner {
        padding: 27.5px 22.5px;
    }
}

@media (max-width: 767.98px) {
    .menu-inner {
        padding: 22.5px 20px;
    }
}

@media (max-width: 479.98px) {
    .menu-inner {
        padding: 17.5px 20px;
    }
}

.menu-inner__item:not(:last-child) {
    margin: 0 0 12px 0;
}

@media (max-width: 1100px) {
    .menu-inner__item {
        margin: 0;
    }

    .menu-inner__item:not(:last-child) {
        margin: 0;
    }
}

.link-menu-inner {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    align-items: center;
}

.link-menu-inner:hover .link-menu-inner__image {
    background: linear-gradient(157.23deg, rgb(249, 131, 92) 2.08%, rgba(255, 108, 61, 0) 95.64%);
}

.link-menu-inner:hover .link-menu-inner__image svg {
    fill: rgb(249, 131, 92);
}

.link-menu-inner:hover .link-menu-inner__text {
    color: rgb(249, 131, 92);
}

.link-menu-inner.active .link-menu-inner__image {
    background: linear-gradient(157.23deg, rgba(255, 108, 61, 0.72) 2.08%, rgba(255, 108, 61, 0) 95.64%);
}

.link-menu-inner.active .link-menu-inner__image svg {
    fill: #FF6C3D;
}

.link-menu-inner.active .link-menu-inner__text {
    color: #FF6C3D;
}

.link-menu-inner__image {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(157.23deg, rgba(255, 255, 255, 0.64) 2.08%, rgba(255, 255, 255, 0) 95.64%);
    position: relative;
    margin: 0 14px 0 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.link-menu-inner__image::before {
    position: absolute;
    content: "";
    width: 47px;
    height: 47px;
    border-radius: 50%;
    overflow: hidden;
    background: #081C36;
}

.link-menu-inner__image svg {
    position: relative;
    fill: #fff;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media (max-width: 767.98px) {
    .link-menu-inner__image {
        margin: 0 12px 0 0;
    }
}

@media (max-width: 479.98px) {
    .link-menu-inner__image {
        margin: 0 10px 0 0;
    }
}

.link-menu-inner__image_profile svg {
    width: 18px;
    height: 18px;
}

.link-menu-inner__image_bonuses svg {
    width: 18px;
    height: 18px;
}

.link-menu-inner__image_cart svg {
    width: 20px;
    height: 18px;
}

.link-menu-inner__image_settings svg {
    width: 22px;
    height: 22px;
}

.link-menu-inner__image_inventory svg {
    width: 22px;
    height: 22px;
}

.link-menu-inner__text {
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media (max-width: 1550px) {
    .link-menu-inner__text {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .link-menu-inner__text {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .link-menu-inner__text {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .link-menu-inner__text {
        font-size: 14px;
    }
}

.content-inner {
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 30px;
    position: relative;
}

@media (max-width: 1550px) {
    .content-inner {
        padding: 25px;
    }
}

@media (max-width: 991.98px) {
    .content-inner {
        padding: 20px;
    }
}

@media (max-width: 479.98px) {
    .content-inner {
        padding: 15px;
    }
}

.dropdown-menu-inner {
    position: relative;
}

.dropdown-menu-inner__button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    position: relative;
    height: auto;
    width: 100%;
    cursor: default;
    text-align: left;
    background: none;
}

.dropdown-menu-inner__button::before {
    display: none;
    position: absolute;
    content: "";
    transform: translate(0, -50%);
    right: 20px;
    width: 11px;
    height: 7px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: url("../img/icons/arrow-orange.svg") center no-repeat;
    background-size: cover;
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

@media (max-width: 1100px) {
    .dropdown-menu-inner__button::before {
        display: block;
    }
}

.dropdown-menu-inner__button.active::before {
    transform: rotate(0);
}

@media (max-width: 1100px) {
    .dropdown-menu-inner__button {
        cursor: pointer;
    }
}

.dropdown-menu-inner__list {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 2;
}

@media (max-width: 1100px) {
    .dropdown-menu-inner__list {
        max-height: 0;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        margin: 0;
    }

    .dropdown-menu-inner__list.visible {
        max-height: 175px;
        margin: 12px 0 0 0;
    }
}

.dropdown-menu-inner__list-item {
    display: flex;
    align-items: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media (max-width: 1100px) {
    .dropdown-menu-inner__list-item:not(:last-child) {
        margin: 0 0 12px 0;
    }
}

.ibg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.ibg img {
    display: none;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0 35px 0 200px;
    z-index: 100;
    background-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media (max-width: 1550px) {
    .header {
        padding: 0 25px 0 160px;
    }
}

@media (max-width: 1500px) {
    .header {
        padding: 0 20px 0 132px;
        margin: 0;
    }
}

@media (max-width: 991.98px) {
    .header {
        background-color: #081A31;
        padding: 0 20px;
    }
}

.header.fixed {
    position: fixed;
    background-color: #081A31;
}

.header.fixed .header__row {
    padding: 7px 0;
}

.header.fixed .menu-header.border .menu-header__list {
    border-bottom: 0;
}

@media (max-width: 991.98px) {
    .header.fixed .sidebar__logo {
        top: 3px;
    }
}

.header__body {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
}

.header__inner {
    flex: 1 1 100%;
}

.header__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    padding: 10px 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media (max-width: 1600px) {
    .header__row {
        justify-content: flex-end;
    }
}

@media screen and (min-width: 992px) {
    .header__row .logo {
        display: none;
    }
}

.header__logo-block {
    flex: 0 0 130px;
    margin: 0 35px 0 0;
    padding: 20px 0 0 0;
}

@media (max-width: 1550px) {
    .header__logo-block {
        flex: 0 0 110px;
        margin: 0 30px 0 0;
        padding: 16px 0 0 0;
    }
}

@media (max-width: 1500px) {
    .header__logo-block {
        padding: 10px 0 0 0;
        margin: 0 25px 0 0;
        flex: 0 0 93px;
    }
}

@media (max-width: 991.98px) {
    .header__logo-block {
        padding: 7px 0 0 0;
        margin: 0;
        flex: 0 0 57px;
    }
}

.header__menu-block {
    flex: 1 1 100%;
}

@media screen and (max-width: 1600px) {
    .header__menu-block nav {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .sidebar {
        display: none;
    }
}

.header__menu {
    margin: 0 40px 0 0;
}

@media (max-width: 1650px) {
    .header__menu {
        margin: 0 35px 0 0;
    }
}

@media (max-width: 1600px) {
    .header__menu {
        margin: 0;
    }
}

.header__languages {
    margin: 0 30px 0 0;
}

.header__burger {
    display: none;
    padding: 0 15.25px;
    position: relative;
    text-align: center;
    margin: 0 0 0 30px;
    flex: 0 0 56px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #FF6C3D;
    border: 1px solid #FFAD92;
    cursor: pointer;
}

@media (max-width: 1600px) {
    .header__burger {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        font-size: 0;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
}

.header__burger:hover span:nth-child(1), .header__burger:hover span:nth-child(3) {
    width: 25.5px;
}

.header__burger span {
    width: 25.5px;
    height: 2.65px;
    background-color: #FFFFFF;
    border-radius: 2.5px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.header__burger span:nth-child(1), .header__burger span:nth-child(3) {
    width: 19px;
}

.header__burger span:nth-child(2) {
    margin: 5px 0;
}

@media (max-width: 991.98px) {
    .header__burger {
        flex: 0 0 44px;
        width: 44px;
        height: 44px;
        padding: 0 12px;
    }

    .header__burger span {
        width: 20px;
        height: 2px;
    }

    .header__burger span:nth-child(1), .header__burger span:nth-child(3) {
        width: 15px;
    }

    .header__burger span:nth-child(2) {
        margin: 4.4px 0;
    }

    .header__burger:hover span:nth-child(1), .header__burger:hover span:nth-child(3) {
        width: 20px;
    }
}

@media (max-width: 479.98px) {
    .header__burger {
        margin: 0 0 0 20px;
    }
}

.menu-header.border .menu-header__list {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.menu-header__list {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    padding: 20px 0 0 0;
}

@media (max-width: 1600px) {
    .menu-header__list {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        font-size: 0;
        border-bottom: 0;
        flex-direction: column;
    }
}

.menu-header__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    position: relative;
    margin: 0 0 20px 0;
}

.menu-header__item:not(:last-child) {
    margin: 0 50px 20px 0;
}

.menu-header__item:not(:last-child)::before {
    position: absolute;
    content: "";
    right: -25px;
    width: 1px;
    height: 13px;
    background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 1800px) {
    .menu-header__item:not(:last-child) {
        margin: 0 40px 20px 0;
    }

    .menu-header__item:not(:last-child)::before {
        right: -20px;
    }
}

@media (max-width: 1650px) {
    .menu-header__item:not(:last-child) {
        margin: 0 35px 20px 0;
    }

    .menu-header__item:not(:last-child)::before {
        right: -17.5px;
    }
}

@media (max-width: 1600px) {
    .menu-header__item {
        margin: 0;
        padding: 14px 0;
    }

    .menu-header__item:not(:last-child) {
        margin: 0;
        border-bottom: 1px solid #263548;
    }

    .menu-header__item:not(:last-child)::before {
        display: none;
    }
}

.menu-header__link {
    font-size: 16px;
    font-weight: 500;
    line-height: 118.75%;
    letter-spacing: -0.01em;
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.menu-header__link:hover {
    text-decoration: underline;
}

@media (max-width: 1700px) {
    .menu-header__link {
        font-size: 15px;
    }
}

.languages-header__list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
}

.languages-header__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid transparent;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.languages-header__item.active {
    width: 64px;
    height: 46px;
    border: 1px solid rgba(255, 108, 61, 0.4);
    background: linear-gradient(0deg, rgba(255, 108, 61, 0.05), rgba(255, 108, 61, 0.05));
}

.languages-header__item.active:not(:last-child) {
    margin: 0 5px 0 0;
}

@media (max-width: 991.98px) {
    .languages-header__item.active {
        width: 58px;
        height: 40px;
    }
}

@media screen and (max-width: 650px) {
    .languages-header__list {
        display: none;
    }
}

.languages-header__item.active .languages-header__link {
    color: #FF6C3D;
}

.languages-header__link {
    font-size: 16px;
    font-weight: 500;
    line-height: 118.75%;
    letter-spacing: -0.01em;
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.languages-header__link:hover {
    color: #FF6C3D;
}

@media (max-width: 1550px) {
    .languages-header__link {
        font-size: 15px;
    }
}

@media (max-width: 991.98px) {
    .languages-header__link {
        font-size: 14px;
    }
}

.login-header {
    display: none;
    position: relative;
}

.login-header.active {
    display: block;
}

.login-header__icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    border: 1px solid #868A8C;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    overflow: hidden;
}

.login-header__icon img {
    max-width: 100%;
}

.login-header__icon:hover {
    border: 1px solid #fff;
}

@media (max-width: 1550px) {
    .login-header__icon {
        width: 56px;
        height: 56px;
    }
}

@media (max-width: 991.98px) {
    .login-header__icon {
        width: 44px;
        height: 44px;
    }
}

.login-header__body {
    display: none;
    top: 80px;
    right: -20px;
    position: absolute;
    padding: 50px 50px 40px 25px;
    background: url("../img/login-bg.svg") center no-repeat;
    background-size: contain;
    z-index: 1;
    opacity: 0;
}

.login-header__body.active {
    display: block;
    animation: login 0.5s linear forwards;
}

@media (max-width: 1550px) {
    .login-header__body {
        top: 70px;
    }
}

@media (max-width: 991.98px) {
    .login-header__body {
        top: 60px;
    }
}

.login-header__item:not(:last-child) {
    margin: 0 0 12px 0;
}

.login-header__link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 500;
    line-height: 118.75%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    text-transform: uppercase;
    transform: translate(5px, 0);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.login-header__link span {
    margin: 0 20px 0 0;
}

.login-header__link img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}

.login-header__link:hover {
    text-decoration: underline;
}

@media (max-width: 1550px) {
    .login-header__link {
        font-size: 15px;
    }
}

@media (max-width: 991.98px) {
    .login-header__link {
        font-size: 14px;
    }
}

.balance-header {
    position: relative;
    z-index: 1;
}

.balance-header.active {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.balance-header.active .balance-header__body {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.balance-header__body {
    display: none;
}

.balance-header__body {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

@media (max-width: 650px) {
    .header-mobile .balance-header__body {
        margin: 15px 0 0 0;
    }

    .balance-header.active .balance-header__body {
        display: none;
    }
}

.balance-header__value {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    padding: 5px 14px 5px 15px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    font-size: 16px;
    line-height: 137.5%;
    color: #FFFFFF;
    border-radius: 7px;
    text-transform: uppercase;
    margin: 0 40px 0 0;
    white-space: nowrap;
}

.balance-header__value a {
    font-size: 14px;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
    color: #fff;
    margin-left: 7px;
}

@media (max-width: 1800px) {
    .balance-header__value {
        margin: 0 30px 0 0;
    }
}

@media (max-width: 991.98px) {
    .balance-header__value {
        height: 38px;
        padding: 5px 11px 5px 12px;
        font-size: 14px;
    }
}

@media (max-width: 479.98px) {
    .balance-header__value {
        margin: 0 15px 0 0;
    }
}

.balance-header__id {
    margin: 0 15px 0 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 118.75%;
    letter-spacing: -0.01em;
    color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.balance-header__id:hover {
    color: #FF6C3D;
}

.balance-header__id:hover svg {
    stroke: #FF6C3D;
}

.balance-header__id span {
    margin: 0 0 0 3px;
}

.balance-header__id span svg {
    width: 24px;
    height: 24px;
    stroke: #fff;
    fill: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    display: block;
}

@media (max-width: 1550px) {
    .balance-header__id {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .balance-header__id {
        font-size: 16px;
    }

    .balance-header__id span {
        margin: 4px 0 0 3px;
    }
}

.balance-header__photo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    overflow: hidden;
}

.balance-header__photo:hover {
    border: 1px solid #fff;
}

.balance-header__photo img {
    max-width: 100%;
}

@media (max-width: 1600px) {
    .balance-header__photo {
        width: 52px;
        height: 52px;
    }
}

@media (max-width: 991.98px) {
    .balance-header__photo {
        width: 44px;
        height: 44px;
    }
}

.balance-header__top-up {
    margin: 0 10px 0 0;
}

.balance-header__bonuses {
    margin: 0 25px 0 0;
}

@media (max-width: 1700px) {
    .balance-header__bonuses {
        margin: 0 20px 0 0;
    }
}

@media (max-width: 479.98px) {
    .balance-header__bonuses {
        margin: 0 10px 0 0;
    }
}

.bonuses-balance-header {
    position: relative;
}

@media (min-width: 991.98px) {
    .bonuses-balance-header:hover .bonuses-balance-header__message {
        opacity: 1;
    }

    .bonuses-balance-header:hover .bonuses-balance-header__icon svg {
        fill: #FF6C3D;
    }
}

.bonuses-balance-header__icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 44px;
    height: 44px;
    border-radius: 3px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
}

.bonuses-balance-header__icon svg {
    width: 23px;
    height: 23px;
    fill: #fff;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

@media (max-width: 479.98px) {
    .bonuses-balance-header__icon {
        width: 38px;
        height: 38px;
    }

    .bonuses-balance-header__icon svg {
        width: 20px;
        height: 20px;
    }
}

.bonuses-balance-header__message {
    pointer-events: none;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%, 100%);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 85px;
    height: 38.5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 157.1428571429%;
    color: #FFFFFF;
    background: url("../img/icons/bonuses-bg.svg") center no-repeat;
    background-size: contain;
    opacity: 0;
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
}

.top-up-balance-header {
    position: relative;
}

@media (min-width: 991.98px) {
    .top-up-balance-header:hover .top-up-balance-header__message {
        opacity: 1;
    }
}

.top-up-balance-header__icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 44px;
    height: 44px;
    border-radius: 3px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
}

.top-up-balance-header__icon svg {
    width: 23px;
    height: 23px;
    fill: #FF6C3D;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

@media (max-width: 479.98px) {
    .top-up-balance-header__icon {
        width: 38px;
        height: 38px;
    }

    .top-up-balance-header__icon svg {
        width: 20px;
        height: 20px;
    }
}

.top-up-balance-header__message {
    pointer-events: none;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%, 100%);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 148px;
    height: 38.5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 157.1428571429%;
    color: #FFFFFF;
    background: url("../img/icons/top-up-bg.svg") center no-repeat;
    background-size: contain;
    opacity: 0;
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
}

@keyframes login {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.header-mobile {
    position: fixed;
    top: 0;
    right: -120%;
    height: 100%;
    width: 79%;
    background-color: #15263A;
    z-index: 200;
    padding: 30px 20px;
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
    overflow-y: auto;
}

@media (max-width: 991.98px) {
    .header-mobile {
        padding: 20px;
    }
}

@media (max-width: 1600px) {
    .header-mobile.active {
        right: 0;
    }
}

.top-mobile-header {
    padding: 0 0 30px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.0784313725);
}

@media (max-width: 991.98px) {
    .top-mobile-header {
        padding: 0 0 20px 0;
    }
}

@media screen and (min-width: 992px) {
    .mobile-header__body .sidebar__body {
        display: none;
    }
}

.top-mobile-header__btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.top-mobile-header__btn:hover {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.top-mobile-header__btn img {
    width: 14px;
    height: 8.9px;
}

@media (max-width: 991.98px) {
    .top-mobile-header__btn {
        width: 44px;
        height: 44px;
    }

    .top-mobile-header__btn img {
        width: 11px;
        height: 7px;
    }
}

.footer {
    position: relative;
    margin: 0 0 0 165px;
    z-index: 21;
}

@media (max-width: 1550px) {
    .footer {
        margin: 0 0 0 135px;
    }
}

@media (max-width: 1500px) {
    .footer {
        margin: 0 0 0 122px;
    }
}

@media (max-width: 991.98px) {
    .footer {
        margin: 0;
    }
}

.footer__body {
    border-radius: 50px;
    overflow: hidden;
    background: rgba(8, 28, 54, 0.6980392157);
    padding: 30px 50px;
}

@media (max-width: 1700px) {
    .footer__body {
        padding: 28px 44px 26px 45px;
    }
}

@media (max-width: 1550px) {
    .footer__body {
        padding: 27px 38px 22px 40px;
    }
}

@media (max-width: 1200px) {
    .footer__body {
        padding: 26px 31.5px 18.5px 35px;
    }
}

@media (max-width: 991.98px) {
    .footer__body {
        padding: 24px 26px 15px 30px;
    }
}

@media (max-width: 767.98px) {
    .footer__body {
        padding: 22px 20.5px 11.5px 25px;
    }
}

@media (max-width: 479.98px) {
    .footer__body {
        padding: 20px 15px 8px 20px;
    }
}

@media (max-width: 350px) {
    .footer__body {
        padding: 20px 12px 8px 17px;
    }
}

.footer__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 991.98px) {
    .footer__row {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 991.98px) {
    .footer__logo {
        order: 1;
        margin: 0 0 20px 0;
    }
}

.footer__copyright {
    padding: 0 0 0 80px;
    font-size: 18px;
    line-height: 177.7777777778%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

@media (max-width: 1700px) {
    .footer__copyright {
        font-size: 17px;
        padding: 0 0 0 100px;
    }
}

@media (max-width: 1550px) {
    .footer__copyright {
        font-size: 16px;
        padding: 0 0 0 120px;
    }
}

@media (max-width: 1200px) {
    .footer__copyright {
        padding: 0;
    }
}

@media (max-width: 991.98px) {
    .footer__copyright {
        font-size: 15px;
        order: 3;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 479.98px) {
    .footer__copyright {
        font-size: 14px;
    }
}

@media (max-width: 991.98px) {
    .footer__socials {
        order: 2;
        margin: 0 0 10px 0;
    }
}

.socials-footer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
}

.socials-footer__column {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.socials-footer__column:nth-child(1) {
    padding: 0 27px 0 0;
    position: relative;
}

.socials-footer__column:nth-child(1)::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    right: 0;
}

@media (max-width: 991.98px) {
    .socials-footer__column:nth-child(1) {
        padding: 0 22px 0 0;
    }
}

@media (max-width: 479.98px) {
    .socials-footer__column:nth-child(1) {
        padding: 0 17px 0 0;
    }
}

@media (max-width: 350px) {
    .socials-footer__column:nth-child(1) {
        padding: 0 12px 0 0;
    }
}

.socials-footer__column:nth-child(2) {
    padding: 0 0 0 27px;
}

@media (max-width: 991.98px) {
    .socials-footer__column:nth-child(2) {
        padding: 0 0 0 22px;
    }
}

@media (max-width: 479.98px) {
    .socials-footer__column:nth-child(2) {
        padding: 0 0 0 17px;
    }
}

@media (max-width: 350px) {
    .socials-footer__column:nth-child(2) {
        padding: 0 0 0 12px;
    }
}

.socials-footer__item:not(:last-child) {
    margin: 0 15px 0 0;
}

@media (max-width: 991.98px) {
    .socials-footer__item:not(:last-child) {
        margin: 0 12.5px 0 0;
    }
}

@media (max-width: 479.98px) {
    .socials-footer__item:not(:last-child) {
        margin: 0 10px 0 0;
    }
}

@media (max-width: 350px) {
    .socials-footer__item:not(:last-child) {
        margin: 0 8px 0 0;
    }
}

.socials-footer__icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.socials-footer__icon:hover {
    box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.6);
}

@media (max-width: 350px) {
    .socials-footer__icon {
        width: 40px;
        height: 40px;
    }

    .socials-footer__icon svg {
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        -o-transform: scale(0.9);
        transform: scale(0.9);
    }
}

.socials-footer__icon svg {
    max-width: 100%;
    height: auto;
    fill: #fff;
}

.socials-footer__icon_face {
    width: 19px;
    height: 15px;
    fill: #5865F2 !important;
}

.socials-footer__icon_email {
    width: 19px;
    height: 15px;
}

.socials-footer__icon_twitter {
    width: 19px;
    height: 19px;
}

.socials-footer__icon_facebook {
    width: 9px;
    height: 17px;
}

.socials-footer__icon_instagram {
    width: 17px;
    height: 17px;
}

.select-category {
    padding: 0 0 55px 0;
    position: relative;
}

.select-category::before {
    position: absolute;
    content: "";
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -50px;
    right: -150px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(300px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .select-category::before {
        animation: circle 6s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .select-category::before {
        display: none;
    }
}

@media (max-width: 1700px) {
    .select-category {
        padding: 0 0 49px 0;
    }
}

@media (max-width: 1550px) {
    .select-category {
        padding: 0 0 43px 0;
    }
}

@media (max-width: 1200px) {
    .select-category {
        padding: 0 0 36.5px 0;
    }
}

@media (max-width: 991.98px) {
    .select-category {
        padding: 0 0 31px 0;
    }
}

@media (max-width: 767.98px) {
    .select-category {
        padding: 0 0 25.5px 0;
    }
}

@media (max-width: 479.98px) {
    .select-category {
        padding: 0 0 20px 0;
    }
}

.select-category__main {
    padding: 105px 0 70px 0;
    margin: 0 0 0 165px;
}

@media (max-width: 1700px) {
    .select-category__main {
        padding: 93px 0 60px 0;
    }
}

@media (max-width: 1550px) {
    .select-category__main {
        padding: 80px 0 50px 0;
        margin: 0 0 0 135px;
    }
}

@media (max-width: 1500px) {
    .select-category__main {
        margin: 0 0 0 122px;
    }
}

@media (max-width: 1200px) {
    .select-category__main {
        padding: 67px 0 40px 0;
    }
}

@media (max-width: 991.98px) {
    .select-category__main {
        padding: 54px 0 30px 0;
        margin: 0;
    }
}

@media (max-width: 767.98px) {
    .select-category__main {
        padding: 41px 0 20px 0;
    }
}

@media (max-width: 479.98px) {
    .select-category__main {
        padding: 28px 0 10px 0;
    }
}

.select-category__body {
    position: relative;
    z-index: 25;
}

.select-category__title:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1200px) {
    .select-category__title:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 479.98px) {
    .select-category__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

.select-category__row {
    display: flex;
    font-size: 0;
    gap: 8px;
}

.select-category__column {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 991px) {
    .select-category__row {
        flex-wrap: wrap;
    }
}

.item-donaciones__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.item-donaciones__btn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.select-category__column .item-select-category {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.select-category__column._active .item-select-category {
    border: 2px solid #FF6C3D;
}

@media (max-width: 1500px) {
    .select-category__column {
        flex: 0 1 25%;
    }
}

@media (max-width: 1350px) {
    .select-category__column {
        flex: 0 1 33.333%;
    }
}

@media (max-width: 991.98px) {
    .select-category__column {
        flex: 0 1 calc(50% - 5px);
    }
}

@media (max-width: 600px) {
    .select-category__column {
        flex: 1 1 100%;
    }
}

.select-category__btn {
    transform: translate(0, -50%);
    position: relative;
    overflow: visible;
    width: 92px;
    height: 92px;
    font-size: 14px;
}

.select-category__btn::before {
    position: absolute;
    content: "";
    width: 107px;
    height: 107px;
    border-radius: 50%;
    background: rgba(225, 72, 23, 0.5019607843);
    top: 50%;
    filter: blur(60px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .select-category__btn::before {
        animation: circle 6s linear infinite;
    }
}

@media (max-width: 991.98px) {
    .select-category__btn::before {
        display: none;
    }
}

@media (max-width: 1550px) {
    .select-category__btn {
        width: 87px;
        height: 87px;
    }
}

@media (max-width: 991.98px) {
    .select-category__btn {
        width: 82px;
        height: 82px;
    }
}

@media (max-width: 767.98px) {
    .select-category__btn {
        width: 76px;
        height: 76px;
    }
}

@media (max-width: 479.98px) {
    .select-category__btn {
        width: 71px;
        height: 71px;
    }
}

.item-select-category {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    border-radius: 30px;
    border: 1px solid #39495D;
    padding: 4px;
}

@media (max-width: 479.98px) {
    .item-select-category {
        padding: 10px 10px 55px 10px;
    }
}

.item-select-category__image-block {
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    padding: 0 0 56.5% 0;
}

.item-select-category__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.item-select-category__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-select-category__image::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(48, 71, 104, 0) 0%, rgba(11, 27, 50, 0.7) 100%);
}

.item-select-category__label {
    position: absolute;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    line-height: 116.6666666667%;
    text-align: center;
    color: #FFFFFF;
    left: 50%;
    bottom: 22px;
    transform: translate(-50%, 0);
}

@media (max-width: 1700px) {
    .item-select-category__label {
        bottom: 18.5px;
    }
}

@media (max-width: 1200px) {
    .item-select-category__label {
        bottom: 15px;
    }
}

@media (max-width: 767.98px) {
    .item-select-category__label {
        bottom: 11.5px;
    }
}

@media (max-width: 479.98px) {
    .item-select-category__label {
        bottom: 8px;
    }
}

.categories {
    position: relative;
}

.categories::before {
    position: absolute;
    content: "";
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -54px;
    right: -111px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(250px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .categories::before {
        animation: circle 5s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .categories::before {
        filter: blur(350px);
    }
}

.categories__main {
    margin: 0 0 0 165px;
    padding: 100px 0 80px 0;
}

@media (max-width: 1700px) {
    .categories__main {
        padding: 90px 0 73px 0;
    }
}

@media (max-width: 1550px) {
    .categories__main {
        margin: 0 0 0 135px;
        padding: 80px 0 67.5px 0;
    }
}

@media (max-width: 1500px) {
    .categories__main {
        margin: 0 0 0 122px;
        padding: 70px 0 62px 0;
    }
}

@media (max-width: 1200px) {
    .categories__main {
        padding: 60px 0 56.5px 0;
    }
}

@media (max-width: 991.98px) {
    .categories__main {
        margin: 0;
        padding: 50px 0 51px 0;
    }
}

@media (max-width: 767.98px) {
    .categories__main {
        padding: 40px 0 45.5px 0;
    }
}

@media (max-width: 479.98px) {
    .categories__main {
        padding: 30px 0 40px 0;
    }
}

.categories__body {
    position: relative;
    z-index: 25;
}

.categories__title:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1200px) {
    .categories__title:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 479.98px) {
    .categories__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

.categories__inner {
    padding: 30px 30px 20px 30px;
    border-radius: 30px;
    overflow: hidden;
    border: 1px solid #39495E;
    background: linear-gradient(0deg, rgba(8, 28, 54, 0.7), rgba(8, 28, 54, 0.7));
}

@media (max-width: 1550px) {
    .categories__inner {
        padding: 25px 25px 12.5px 25px;
    }
}

@media (max-width: 991.98px) {
    .categories__inner {
        padding: 20px 20px 7.5px 20px;
    }
}

@media (max-width: 479.98px) {
    .categories__inner {
        padding: 15px 15px 0 15px;
    }
}

.categories__panel {
    margin: 0 0 20px 0;
}

.categories__info-panel {
    margin: 0 0 20px 0;
}

.panel-categories {
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 18px;
    border-radius: 15px;
    overflow: hidden;
}

@media (max-width: 767.98px) {
    .panel-categories {
        padding: 15px;
    }
}

.panel-categories__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 767.98px) {
    .panel-categories__row {
        flex-direction: column;
    }
}

@media (max-width: 767.98px) {
    .panel-categories__menu:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 420px) {
    .menu-panel-categories {
        width: 100%;
    }
}

.menu-panel-categories__list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
}

@media (max-width: 420px) {
    .menu-panel-categories__list {
        width: 100%;
        justify-content: space-between;
    }
}

.menu-panel-categories__item:not(:last-child) {
    margin: 0 20px 0 0;
}

.menu-panel-categories__link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.menu-panel-categories__link:hover span:first-child {
    background: linear-gradient(0deg, rgba(8, 28, 54, 0.4), rgba(8, 28, 54, 0.4));
}

.menu-panel-categories__link:hover span:last-child {
    text-decoration: underline;
}

.menu-panel-categories__link span:first-child {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 1px solid #324258;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(8, 28, 54, 0.7), rgba(8, 28, 54, 0.7));
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin: 0 9px 0 0;
}

.menu-panel-categories__link span:first-child img {
    width: 15px;
    height: 15px;
}

@media (max-width: 420px) {
    .menu-panel-categories__link span:first-child {
        margin: 0 0 5px 0;
    }
}

.menu-panel-categories__link span:last-child {
    font-size: 16px;
    line-height: 137.5%;
    color: #FFFFFF;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media (max-width: 420px) {
    .menu-panel-categories__link span:last-child {
        font-size: 15px;
    }
}

@media (max-width: 420px) {
    .menu-panel-categories__link {
        flex-direction: column;
    }
}

.login-panel-categories {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

@media (max-width: 420px) {
    .login-panel-categories {
        width: 100%;
        justify-content: space-between;
    }
}

.login-panel-categories__text {
    font-size: 16px;
    line-height: 137.5%;
    color: #FFFFFF;
    margin: 0 23px 0 0;
}

@media (max-width: 420px) {
    .login-panel-categories__text {
        font-size: 15px;
        margin: 0 10px 0 0;
    }
}

.login-panel-categories__btn {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 46px;
    padding: 5px 22px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #FF6C3D;
    font-family: Barlow;
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.login-panel-categories__btn:hover {
    background-color: #e76134;
}

.info-panel-categories__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 767.98px) {
    .info-panel-categories__row {
        flex-direction: column;
    }
}

@media (max-width: 479.98px) {
    .info-panel-categories__row {
        align-items: flex-start;
    }
}

@media (max-width: 767.98px) {
    .info-panel-categories__user {
        margin: 0 0 10px 0;
    }
}

.user-info-panel-categories {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.user-info-panel-categories__image {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 63px;
    height: 63px;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    overflow: hidden;
    margin: 0 13px 0 0;
}

.user-info-panel-categories__image img {
    width: 100%;
    height: 100%;
}

@media (max-width: 479.98px) {
    .user-info-panel-categories__image {
        width: 53px;
        height: 53px;
    }

    .user-info-panel-categories__image img {
        width: 35px;
        height: 35px;
    }
}

.user-info-panel-categories__name {
    font-size: 16px;
    line-height: 137.5%;
    color: #FFFFFF;
}

@media (max-width: 479.98px) {
    .user-info-panel-categories__name {
        font-size: 15px;
    }
}

.user-info-panel-categories__id {
    font-size: 13px;
    line-height: 169.2307692308%;
    color: #9D9D9D;
}

.user-info-panel-categories__agreement {
    font-size: 13px;
    line-height: 169.2307692308%;
    color: #FFFFFF;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.user-info-panel-categories__agreement:hover {
    text-decoration: underline;
}

.balance-info-panel-categories {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-content: center;
}

@media (max-width: 767.98px) {
    .balance-info-panel-categories {
        flex-direction: column;
    }
}

.balance-info-panel-categories__balance-now {
    margin: 0 0 0 20px;
}

@media (max-width: 767.98px) {
    .balance-info-panel-categories__balance-now {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 479.98px) {
    .balance-info-panel-categories__balance-now {
        margin: 0 0 12px 0;
    }
}

.balance-info-panel-categories__top-up {
    margin: 0 0 0 20px;
}

@media (max-width: 767.98px) {
    .balance-info-panel-categories__top-up {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 479.98px) {
    .balance-info-panel-categories__top-up {
        margin: 0 0 12px 0;
    }
}

.balance-info-panel-categories__bonuses {
    margin: 0 0 0 12px;
}

@media (max-width: 767.98px) {
    .balance-info-panel-categories__bonuses {
        margin: 0;
    }
}

.balance-now-info-panel-categories {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.balance-now-info-panel-categories__text {
    display: inline-block;
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
    margin: 0 9px 0 0;
}

@media (max-width: 1550px) {
    .balance-now-info-panel-categories__text {
        font-size: 17px;
    }
}

@media (max-width: 1200px) {
    .balance-now-info-panel-categories__text {
        font-size: 16px;
    }
}

@media (max-width: 991.98px) {
    .balance-now-info-panel-categories__text {
        font-size: 15px;
    }
}

@media (max-width: 767.98px) {
    .balance-now-info-panel-categories__text {
        font-size: 14px;
    }
}

@media (max-width: 479.98px) {
    .balance-now-info-panel-categories__text {
        font-size: 13px;
    }
}

.balance-now-info-panel-categories__value {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 46px;
    padding: 12px 14px 12px 15px;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    overflow: hidden;
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
}

@media (max-width: 1550px) {
    .balance-now-info-panel-categories__value {
        font-size: 17px;
    }
}

@media (max-width: 1200px) {
    .balance-now-info-panel-categories__value {
        font-size: 16px;
    }
}

@media (max-width: 991.98px) {
    .balance-now-info-panel-categories__value {
        font-size: 15px;
    }
}

@media (max-width: 767.98px) {
    .balance-now-info-panel-categories__value {
        font-size: 14px;
        height: 42px;
    }
}

@media (max-width: 479.98px) {
    .balance-now-info-panel-categories__value {
        font-size: 13px;
        padding: 5px 13px;
        height: 38px;
    }
}

.top-up-info-panel-categories {
    height: 46px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: space-between;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.5019607843);
    background: transparent;
    overflow: hidden;
    padding: 5px 21px 5px 7px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.top-up-info-panel-categories:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
}

@media (max-width: 767.98px) {
    .top-up-info-panel-categories {
        height: 42px;
        text-align: left;
        justify-content: flex-start;
    }
}

@media (max-width: 479.98px) {
    .top-up-info-panel-categories {
        height: 38px;
    }
}

.top-up-info-panel-categories__icon {
    width: 31px;
    height: 31px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 3px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    margin: 0 12px 0 0;
}

.top-up-info-panel-categories__icon img {
    width: 17px;
    height: 17px;
}

@media (max-width: 767.98px) {
    .top-up-info-panel-categories__icon {
        width: 27.5px;
        height: 27.5px;
    }

    .top-up-info-panel-categories__icon img {
        width: 15px;
        height: 15px;
    }
}

@media (max-width: 479.98px) {
    .top-up-info-panel-categories__icon {
        width: 24px;
        height: 24px;
        margin: 0 10px 0 0;
    }

    .top-up-info-panel-categories__icon img {
        width: 13px;
        height: 13px;
    }
}

.top-up-info-panel-categories__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 157.1428571429%;
    color: #FFFFFF;
}

@media (max-width: 479.98px) {
    .top-up-info-panel-categories__text {
        font-size: 13px;
    }
}

.bonuses-info-panel-categories {
    height: 46px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: space-between;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.5019607843);
    overflow: hidden;
    padding: 5px 21px 5px 7px;
    background: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.bonuses-info-panel-categories:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
}

@media (max-width: 767.98px) {
    .bonuses-info-panel-categories {
        height: 42px;
        text-align: left;
        justify-content: flex-start;
    }
}

@media (max-width: 479.98px) {
    .bonuses-info-panel-categories {
        height: 38px;
    }
}

.bonuses-info-panel-categories__icon {
    width: 31px;
    height: 31px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 3px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    margin: 0 12px 0 0;
}

.bonuses-info-panel-categories__icon img {
    width: 17px;
    height: 17px;
}

@media (max-width: 767.98px) {
    .bonuses-info-panel-categories__icon {
        width: 27.5px;
        height: 27.5px;
    }

    .bonuses-info-panel-categories__icon img {
        width: 15px;
        height: 15px;
    }
}

@media (max-width: 479.98px) {
    .bonuses-info-panel-categories__icon {
        width: 24px;
        height: 24px;
        margin: 0 10px 0 0;
    }

    .bonuses-info-panel-categories__icon img {
        width: 13px;
        height: 13px;
    }
}

.bonuses-info-panel-categories__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 157.1428571429%;
    color: #FFFFFF;
}

@media (max-width: 479.98px) {
    .bonuses-info-panel-categories__text {
        font-size: 13px;
    }
}

.cards-categories__top {
    margin: 0 0 15px 0;
}

@media (max-width: 767.98px) {
    .cards-categories__top {
        margin: 0 0 20px 0;
    }
}

.cards-categories__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.cards-categories__column a {
    height: 100%;
}
.cards-categories__column {
    padding: 0 8px;
    flex: 0 1 20%;
    margin: 0 0 18px 0;
}

@media (max-width: 1550px) {
    .cards-categories__column {
        flex: 0 1 25%;
    }
}

@media (max-width: 1200px) {
    .cards-categories__column {
        flex: 0 1 33.333%;
    }
}

@media (max-width: 767.98px) {
    .cards-categories__column {
        flex: 0 1 50%;
    }
}

@media (max-width: 479.98px) {
    .cards-categories__column {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 350px) {
    .cards-categories__column {
        flex: 1 1 100%;
    }
}

.top-cards-categories {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: flex-start;
    justify-content: space-between;
}

@media (max-width: 767.98px) {
    .top-cards-categories {
        flex-direction: column;
    }
}

@media (max-width: 767.98px) {
    .top-cards-categories__filter {
        margin: 0 0 12px 0;
    }
}

@media (min-width: 767.98px) {
    .top-cards-categories__order {
        margin: 0 0 0 20px;
    }
}

@media (max-width: 420px) {
    .top-cards-categories__order {
        width: 100%;
    }
}

.filter-top-cards-categories__input {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-content: center;
    height: 46px;
    width: 100%;
    max-width: 290px;
    padding: 5px 16px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1019607843);
    font-size: 14px;
    line-height: 157.1428571429%;
    color: #FFFFFF;
    margin: 0 0 20px 0;
}

.filter-top-cards-categories__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 479.98px) {
    .filter-top-cards-categories__input {
        height: 44px;
        margin: 0 0 15px 0;
    }
}

.filter-top-cards-categories__items {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-wrap: wrap;
    align-items: center;
}

.filter-top-cards-categories__item {
    margin: 0 10px 10px 0;
}

.filter-top-cards-categories__item.active .filter-top-cards-categories__link {
    border: 1px solid rgba(255, 255, 255, 0.7568627451);
}

.filter-top-cards-categories__link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.3019607843);
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 122.2222222222%;
    color: #FFFFFF;
    position: relative;
    z-index: 1;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.filter-top-cards-categories__link:hover {
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -ms-transform: scale(1.03);
    -o-transform: scale(1.03);
    transform: scale(1.03);
}
.filter-top-cards-categories__link:active {
    -webkit-transform: scale(0.97);
    -moz-transform: scale(0.97);
    -ms-transform: scale(0.97);
    -o-transform: scale(0.97);
    transform: scale(0.97);
}

.filter-top-cards-categories__link::before {
    position: absolute;
    content: "";
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: 11px;
    z-index: -1;
}

@media (max-width: 1200px) {
    .filter-top-cards-categories__link {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .filter-top-cards-categories__link {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .filter-top-cards-categories__link {
        height: 47px;
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .filter-top-cards-categories__link {
        height: 44px;
        font-size: 14px;
    }
}

.filter-top-cards-categories__item:nth-child(2n + 2) .filter-top-cards-categories__link::before {
    background: rgba(0, 55, 128, 0.8);
    left: 15px;
    filter: blur(10px);
}

.filter-top-cards-categories__item:nth-child(2n + 1) .filter-top-cards-categories__link::before {
    background: rgba(255, 108, 61, 0.3019607843);
    right: 20px;
    filter: blur(10px);
}

.order-top-cards-categories {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

@media (max-width: 479.98px) {
    .order-top-cards-categories {
        flex-direction: column;
        align-items: flex-start;
    }
}

.order-top-cards-categories__text {
    white-space: nowrap;
    font-size: 16px;
    line-height: 137.5%;
    color: #FFFFFF;
}

@media (max-width: 479.98px) {
    .order-top-cards-categories__text {
        margin: 0 0 8px 0;
    }
}

.order-top-cards-categories__items {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

@media (max-width: 420px) {
    .order-top-cards-categories__items {
        justify-content: space-between;
        width: 100%;
    }
}

.order-top-cards-categories__item {
    margin: 0 0 0 13px;
}

@media (max-width: 479.98px) {
    .order-top-cards-categories__item:first-child {
        margin: 0;
    }
}

@media (max-width: 420px) {
    .order-top-cards-categories__item {
        margin: 0 0 0 7px;
    }
}

.item-order-top-cards-categories {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 46px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    overflow: hidden;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 5px 18px 5px 13px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.item-order-top-cards-categories:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
}

.item-order-top-cards-categories.active {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
}

@media (max-width: 479.98px) {
    .item-order-top-cards-categories {
        height: 44px;
    }
}

.item-order-top-cards-categories__arrows {
    flex: 0 0 18px;
    width: 18px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
}

.item-order-top-cards-categories__arrow.hide {
    display: none;
}

.item-order-top-cards-categories__arrow-down {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.item-order-top-cards-categories__label {
    font-size: 14px;
    color: #FFFFFF;
}

.item-cards-categories {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    border-radius: 15px;
    padding: 24px 20px 20px 20px;
    background-color: #526074;
    border: 1px solid #FFFFFF;
    overflow: hidden;
    width: 100%;
}

/*.item-cards-categories::before, .item-cards-categories::after {*/
/*    position: absolute;*/
/*    content: "";*/
/*    width: 319px;*/
/*    height: 319px;*/
/*    border-radius: 50%;*/
/*    filter: blur(100px);*/
/*}*/

/*.item-cards-categories::before {*/
/*    background: rgba(0, 55, 128, 0.8);*/
/*}*/

/*.item-cards-categories::after {*/
/*    background: rgba(255, 108, 61, 0.8);*/
/*}*/

@media (max-width: 1700px) {
    .item-cards-categories {
        padding: 22px 17.5px 19px 17.5px;
    }
}

@media (max-width: 1550px) {
    .item-cards-categories {
        padding: 20px 17.5px 18px 17.5px;
    }
}

@media (max-width: 1200px) {
    .item-cards-categories {
        padding: 17.5px 17.5px 17px 17.5px;
    }
}

@media (max-width: 991.98px) {
    .item-cards-categories {
        padding: 15px 15px 16px 15px;
    }
}

@media (max-width: 767.98px) {
    .item-cards-categories {
        padding: 12.5px 12.5px 15px 12.5px;
    }
}

@media (max-width: 479.98px) {
    .item-cards-categories {
        padding: 10px 10px 14px 10px;
    }
}

.item-cards-categories_1::before {
    bottom: 100px;
    right: 104px;
}

.item-cards-categories_1::after {
    left: 150px;
    bottom: 41.5px;
}

.item-cards-categories_2::before {
    bottom: 105px;
    left: -7px;
}

.item-cards-categories_2::after {
    top: 78px;
    left: 67px;
}

.item-cards-categories_3::before {
    bottom: 80px;
    left: 63px;
}

.item-cards-categories_3::after {
    top: 66px;
    right: 66px;
}

.item-cards-categories_4::before {
    top: 86px;
    right: 103px;
}

.item-cards-categories_4::after {
    bottom: 89px;
    left: 36px;
}

.item-cards-categories_5::before {
    bottom: 105px;
    left: -7px;
}

.item-cards-categories_5::after {
    top: 78px;
    left: 67px;
}

.item-cards-categories__body {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    align-items: center;
}

/*@media (max-width: 1700px) {*/
/*    .item-cards-categories__body {*/
/*        padding: 0 25px;*/
/*    }*/
/*}*/

/*@media (max-width: 1550px) {*/
/*    .item-cards-categories__body {*/
/*        padding: 0 20px;*/
/*    }*/
/*}*/

/*@media (max-width: 1200px) {*/
/*    .item-cards-categories__body {*/
/*        padding: 0 15px;*/
/*    }*/
/*}*/

/*@media (max-width: 991.98px) {*/
/*    .item-cards-categories__body {*/
/*        padding: 0 10px;*/
/*    }*/
/*}*/

/*@media (max-width: 767.98px) {*/
/*    .item-cards-categories__body {*/
/*        padding: 0 5px;*/
/*    }*/
/*}*/

/*@media (max-width: 479.98px) {*/
/*    .item-cards-categories__body {*/
/*        padding: 0;*/
/*    }*/
/*}*/

.item-cards-categories__name {
    font-size: 24px;
    line-height: 145.8333333333%;
    text-align: center;
    color: #FFFFFF;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.item-cards-categories__name:not(:last-child) {
    margin: 0 0 6px 0;
}

.item-cards-categories__name:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

@media (max-width: 1700px) {
    .item-cards-categories__name {
        font-size: 22px;
    }
}

@media (max-width: 1550px) {
    .item-cards-categories__name {
        font-size: 21;
    }
}

@media (max-width: 1200px) {
    .item-cards-categories__name {
        font-size: 19px;
    }
}

@media (max-width: 991.98px) {
    .item-cards-categories__name {
        font-size: 18px;
    }
}

@media (max-width: 767.98px) {
    .item-cards-categories__name {
        font-size: 16px;
    }
}

@media (max-width: 479.98px) {
    .item-cards-categories__name {
        font-size: 15px;
    }
}

.item-cards-categories__image {
    border-radius: 12px;
    overflow: hidden;
    margin: 0 0 13px 0;
}

.item-cards-categories__image:hover img {
    -moz-transform: scale(1.015);
    -ms-transform: scale(1.015);
    -webkit-transform: scale(1.015);
    -o-transform: scale(1.015);
    transform: scale(1.015);
}

.item-cards-categories__image img {
    max-width: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    height: 150px;
    object-fit: contain;
}

@media (max-width: 479.98px) {
    .item-cards-categories__image {
        margin: 0 0 10px 0;
    }
}

.bottom-item-cards-categories {
    position: relative;
    z-index: 1;
}

.bottom-item-cards-categories__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.bottom-item-cards-categories__number {
    font-size: 18px;
    font-weight: 600;
    line-height: 122.2222222222%;
    color: #FFFFFF;
}

@media (max-width: 1550px) {
    .bottom-item-cards-categories__number {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .bottom-item-cards-categories__number {
        font-size: 16px;
    }
}

@media (max-width: 479.98px) {
    .bottom-item-cards-categories__number {
        font-size: 15px;
    }
}

.bottom-item-cards-categories__price {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    /*width: 100%;*/
    white-space: nowrap;
}

@media (max-width: 1550px) {
    .bottom-item-cards-categories__price {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .bottom-item-cards-categories__price {
        font-size: 16px;
    }
}

@media (max-width: 479.98px) {
    .bottom-item-cards-categories__price {
        font-size: 15px;
    }
}

.bottom-item-cards-categories__price_old {
    font-size: 16px;
    font-weight: 400;
    text-decoration: line-through;
    text-decoration-color: #DCDCDC;
}

@media (max-width: 991.98px) {
    .bottom-item-cards-categories__price_old {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .bottom-item-cards-categories__price_old {
        font-size: 14px;
    }
}

.bottom-item-cards-categories__price_now {
    margin: 0 0 0 8px;
}

.select-product {
    position: fixed;
    top: 0;
    right: -120%;
    z-index: 200;
    width: 50%;
    height: 100%;
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
}

.select-product.active {
    right: 0;
}

.select-product__body {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #031228;
    width: 100%;
    height: 100%;
    padding: 30px 110px 50px 30px;
    overflow-x: hidden;
    overflow-y: auto;
}

@media (max-width: 1700px) {
    .select-product {
        width: 56.5%;
    }

    .select-product__body {
        padding: 28.3px 95px 46px 28.3px;
    }
}

@media (max-width: 1550px) {
    .select-product {
        width: 62.5%;
    }

    .select-product__body {
        padding: 26.65px 80px 43px 26.65px;
    }
}

@media (max-width: 1200px) {
    .select-product {
        width: 68%;
    }

    .select-product__body {
        padding: 25px 65px 40px 25px;
    }
}

@media (max-width: 991.98px) {
    .select-product {
        width: 74%;
    }

    .select-product__body {
        padding: 23.3px 50px 36px 23.3px;
    }
}

@media (max-width: 767.98px) {
    .select-product {
        width: 79.5%;
    }

    .select-product__body {
        padding: 21.65px 35px 33px 21.65px;
    }
}

@media (max-width: 479.98px) {
    .select-product {
        width: 85%;
    }

    .select-product__body {
        padding: 20px 20px 30px 20px;
    }
}

.select-product__btn-close {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.select-product__btn-close:not(:last-child) {
    margin: 0 0 40px 0;
}

.select-product__btn-close:hover {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.select-product__btn-close img {
    width: 14px;
    height: 8.9px;
}

@media (max-width: 1550px) {
    .select-product__btn-close:not(:last-child) {
        margin: 0 0 35px 0;
    }
}

@media (max-width: 1200px) {
    .select-product__btn-close:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 991.98px) {
    .select-product__btn-close {
        width: 44px;
        height: 44px;
    }

    .select-product__btn-close:not(:last-child) {
        margin: 0 0 25px 0;
    }

    .select-product__btn-close img {
        width: 11px;
        height: 7px;
    }
}

@media (max-width: 767.98px) {
    .select-product__btn-close:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 479.98px) {
    .select-product__btn-close:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

.select-product__form {
    padding: 0 0 0 38px;
}

@media (max-width: 1700px) {
    .select-product__form {
        padding: 0 0 0 29px;
    }
}

@media (max-width: 1550px) {
    .select-product__form {
        padding: 0 0 0 21.7px;
    }
}

@media (max-width: 1200px) {
    .select-product__form {
        padding: 0 0 0 15.35px;
    }
}

@media (max-width: 991.98px) {
    .select-product__form {
        padding: 0 0 0 19px;
    }
}

@media (max-width: 767.98px) {
    .select-product__form {
        padding: 0 0 0 12.7px;
    }
}

@media (max-width: 479.98px) {
    .select-product__form {
        padding: 0 0 0 0;
    }
}

.select-product__title:not(:last-child) {
    margin: 0 0 30px 0;
}

@media (max-width: 1550px) {
    .select-product__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 991.98px) {
    .select-product__title:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 479.98px) {
    .select-product__title:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

.select-product__control-panel:not(:last-child) {
    margin: 0 0 38px 0;
}

@media (max-width: 1550px) {
    .select-product__control-panel:not(:last-child) {
        margin: 0 0 33px 0;
    }
}

@media (max-width: 991.98px) {
    .select-product__control-panel:not(:last-child) {
        margin: 0 0 28px 0;
    }
}

@media (max-width: 767.98px) {
    .select-product__control-panel:not(:last-child) {
        margin: 0 0 23px 0;
    }
}

@media (max-width: 479.98px) {
    .select-product__control-panel:not(:last-child) {
        margin: 0 0 18px 0;
    }
}

.select-product__about-product {
    padding: 0 0 23.5px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 991.98px) {
    .select-product__about-product {
        padding: 0 0 19px 0;
    }
}

@media (max-width: 479.98px) {
    .select-product__about-product {
        padding: 0 0 15px 0;
    }
}

.select-product__about-product_no-authorization {
    border-bottom: 0;
    padding: 0;
}

.select-product__name {
    font-size: 26px;
    color: #FFFFFF;
}

.select-product__name:not(:last-child) {
    margin: 0 0 14px 0;
}

@media (max-width: 1550px) {
    .select-product__name {
        font-size: 25px;
    }

    .select-product__name:not(:last-child) {
        margin: 0 0 12px 0;
    }
}

@media (max-width: 991.98px) {
    .select-product__name {
        font-size: 24px;
    }

    .select-product__name:not(:last-child) {
        margin: 0 0 10px 0;
    }
}

@media (max-width: 767.98px) {
    .select-product__name {
        font-size: 23px;
    }

    .select-product__name:not(:last-child) {
        margin: 0 0 8px 0;
    }
}

@media (max-width: 479.98px) {
    .select-product__name {
        font-size: 22px;
    }

    .select-product__name:not(:last-child) {
        margin: 0 0 6px 0;
    }
}

.select-product__description {
    font-size: 18px;
    line-height: 138.8888888889%;
    color: #DFDFDF;
}

@media (max-width: 1550px) {
    .select-product__description {
        font-size: 17px;
    }
}

@media (max-width: 1200px) {
    .select-product__description {
        font-size: 16px;
    }
}

@media (max-width: 991.98px) {
    .select-product__description {
        font-size: 15px;
    }
}

@media (max-width: 767.98px) {
    .select-product__description {
        font-size: 14px;
    }
}

@media (max-width: 479.98px) {
    .select-product__description {
        font-size: 13px;
    }
}

.select-product__info:not(:last-child) {
    margin: 0 0 8px 0;
}

@media (max-width: 1550px) {
    .select-product__info:not(:last-child) {
        margin: 0 0 5px 0;
    }
}

@media (max-width: 991.98px) {
    .select-product__info:not(:last-child) {
        margin: 0 0 2px 0;
    }
}

@media (max-width: 767.98px) {
    .select-product__info:not(:last-child) {
        margin: 0;
    }
}

.select-product__parameters:not(:last-child) {
    margin: 28px 0;
}

@media (max-width: 1200px) {
    .select-product__parameters:not(:last-child) {
        margin: 24px 0;
    }
}

@media (max-width: 479.98px) {
    .select-product__parameters:not(:last-child) {
        margin: 21px 0;
    }
}

.select-product__rare {
    margin: 0 0 44px 0;
}

@media (max-width: 1550px) {
    .select-product__rare {
        margin: 0 0 38px 0;
    }
}

@media (max-width: 991.98px) {
    .select-product__rare {
        margin: 0 0 32.5px 0;
    }
}

@media (max-width: 479.98px) {
    .select-product__rare {
        margin: 0 0 27px 0;
    }
}

.select-product__characteristics:not(:last-child) {
    margin: 0 0 15px 0;
}

.select-product__bottom {
    padding: 30px 0 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 1200px) {
    .select-product__bottom {
        padding: 25px 0 0 0;
    }
}

@media (max-width: 479.98px) {
    .select-product__bottom {
        padding: 20px 0 0 0;
    }
}

.control-panel-select-product__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: space-between;
}

.control-panel-select-product__row_no-authorization {
    justify-content: flex-end;
}

.control-panel-select-product__btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    width: 44px;
    height: 44px;
}

.control-panel-select-product__btn:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
}

.control-panel-select-product__btn_edit {
    border: 1px solid #FF6C3D;
}

.control-panel-select-product__btn_edit img {
    width: 19px;
    height: 19px;
}

.control-panel-select-product__btn_close {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.control-panel-select-product__btn_close img {
    width: 15px;
    height: 15px;
}

.info-select-product {
    padding: 25px 0 0 0;
}

@media (max-width: 479.98px) {
    .info-select-product {
        padding: 22px 0 0 0;
    }
}

.info-select-product__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-wrap: wrap;
    align-items: center;
}

@media (max-width: 479.98px) {
    .info-select-product__row {
        margin: 0 -20px;
    }
}

.info-select-product__item {
    margin: 0 60px 20px 0;
}

@media (max-width: 1200px) {
    .info-select-product__item {
        margin: 0 45px 20px 0;
    }
}

@media (max-width: 991.98px) {
    .info-select-product__item {
        margin: 0 35px 20px 0;
    }
}

@media (max-width: 479.98px) {
    .info-select-product__item {
        margin: 0 0 15px 0;
        padding: 0 20px;
        text-align: left;
    }
}

.info-select-product__image {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    overflow: hidden;
}

.info-select-product__image img {
    width: 22px;
    height: 22px;
}

.info-select-product__label {
    white-space: nowrap;
    font-size: 15px;
    line-height: 166.6666666667%;
    color: #CBCBCB;
    margin: 0 0 3px 0;
}

@media (max-width: 479.98px) {
    .info-select-product__label {
        font-size: 14px;
        margin: 0 0 2px 0;
    }
}

.info-select-product__value {
    white-space: nowrap;
    font-size: 18px;
    line-height: 138.8888888889%;
    color: #DFDFDF;
}

@media (max-width: 1550px) {
    .info-select-product__value {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .info-select-product__value {
        font-size: 16px;
    }
}

@media (max-width: 479.98px) {
    .info-select-product__value {
        font-size: 15px;
    }
}

.info-select-product__value_discount {
    padding: 8px 17px 8px 13px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}

.info-select-product__value_discount-text {
    font-size: 13px;
    line-height: 161.5384615385%;
    color: #A2A2A2;
    white-space: wrap;
}

.info-select-product__value_discount-total {
    font-size: 15px;
    line-height: 166.6666666667%;
    color: #DFDFDF;
    white-space: wrap;
}

.parameters-select-product {
    width: 100%;
    max-width: 516px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
}

.label-parameters-select-product {
    padding: 24px 38px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}

@media (max-width: 1700px) {
    .label-parameters-select-product {
        padding: 23px 35px;
    }
}

@media (max-width: 1550px) {
    .label-parameters-select-product {
        padding: 23px 32px;
    }
}

@media (max-width: 1200px) {
    .label-parameters-select-product {
        padding: 22px 29px;
    }
}

@media (max-width: 991.98px) {
    .label-parameters-select-product {
        padding: 21px 26px;
    }
}

@media (max-width: 767.98px) {
    .label-parameters-select-product {
        padding: 20px 23px;
    }
}

@media (max-width: 479.98px) {
    .label-parameters-select-product {
        padding: 19px 20px;
    }
}

.label-parameters-select-product__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    margin: 0 -10px;
}

.label-parameters-select-product__column {
    padding: 0 10px;
    flex: 0 1 50%;
}

.label-parameters-select-product__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.label-parameters-select-product__item_level .label-parameters-select-product__icon {
    width: 23px;
    height: 23px;
}

@media (max-width: 1200px) {
    .label-parameters-select-product__item_level .label-parameters-select-product__icon {
        width: 19.5px;
        height: 19.5px;
    }
}

@media (max-width: 479.98px) {
    .label-parameters-select-product__item_level .label-parameters-select-product__icon {
        width: 16px;
        height: 16px;
    }
}

.label-parameters-select-product__item_domestication .label-parameters-select-product__icon {
    width: 20px;
    height: 20px;
}

@media (max-width: 1200px) {
    .label-parameters-select-product__item_domestication .label-parameters-select-product__icon {
        width: 17px;
        height: 17px;
    }
}

@media (max-width: 479.98px) {
    .label-parameters-select-product__item_domestication .label-parameters-select-product__icon {
        width: 14px;
        height: 14px;
    }
}

.label-parameters-select-product__item_gender .label-parameters-select-product__icon {
    width: 19px;
    height: 20px;
}

@media (max-width: 1200px) {
    .label-parameters-select-product__item_gender .label-parameters-select-product__icon {
        width: 16px;
        height: 17px;
    }
}

@media (max-width: 479.98px) {
    .label-parameters-select-product__item_gender .label-parameters-select-product__icon {
        width: 13px;
        height: 14px;
    }
}

.label-parameters-select-product__item_neutered .label-parameters-select-product__icon {
    width: 19px;
    height: 19px;
}

@media (max-width: 1200px) {
    .label-parameters-select-product__item_neutered .label-parameters-select-product__icon {
        width: 16px;
        height: 16px;
    }
}

@media (max-width: 479.98px) {
    .label-parameters-select-product__item_neutered .label-parameters-select-product__icon {
        width: 13px;
        height: 13px;
    }
}

.label-parameters-select-product__item_colored .label-parameters-select-product__icon {
    width: 25px;
    height: 25px;
}

@media (max-width: 1200px) {
    .label-parameters-select-product__item_colored .label-parameters-select-product__icon {
        width: 22px;
        height: 22px;
    }
}

@media (max-width: 479.98px) {
    .label-parameters-select-product__item_colored .label-parameters-select-product__icon {
        width: 19px;
        height: 19px;
    }
}

.label-parameters-select-product__item_saddle .label-parameters-select-product__icon {
    width: 24px;
    height: 24px;
}

@media (max-width: 1200px) {
    .label-parameters-select-product__item_saddle .label-parameters-select-product__icon {
        width: 19.5px;
        height: 19.5px;
    }
}

@media (max-width: 479.98px) {
    .label-parameters-select-product__item_saddle .label-parameters-select-product__icon {
        width: 15px;
        height: 15px;
    }
}

.label-parameters-select-product__icon {
    position: relative;
    margin: 0 10px 0 0;
}

.label-parameters-select-product__icon img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 479.98px) {
    .label-parameters-select-product__icon {
        margin: 0 9px 0 0;
    }
}

.label-parameters-select-product__text {
    font-size: 16px;
    color: #DFDFDF;
}

@media (max-width: 1550px) {
    .label-parameters-select-product__text {
        font-size: 15px;
    }
}

@media (max-width: 991.98px) {
    .label-parameters-select-product__text {
        font-size: 14px;
    }
}

@media (max-width: 479.98px) {
    .label-parameters-select-product__text {
        font-size: 13px;
    }
}

.value-parameters-select-product {
    padding: 11px 38px;
}

@media (max-width: 1700px) {
    .value-parameters-select-product {
        padding: 12px 35px;
    }
}

@media (max-width: 1550px) {
    .value-parameters-select-product {
        padding: 13px 32px;
    }
}

@media (max-width: 1200px) {
    .value-parameters-select-product {
        padding: 14px 29px;
    }
}

@media (max-width: 991.98px) {
    .value-parameters-select-product {
        padding: 15px 26px;
    }
}

@media (max-width: 767.98px) {
    .value-parameters-select-product {
        padding: 16px 23px;
    }
}

@media (max-width: 479.98px) {
    .value-parameters-select-product {
        padding: 17px 20px;
    }
}

.value-parameters-select-product__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    margin: 0 -10px;
}

.value-parameters-select-product__column {
    padding: 0 10px;
    flex: 0 1 50%;
}

.value-parameters-select-product__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 156.25%;
    color: #DFDFDF;
}

.value-parameters-select-product__item img {
    width: 16px;
    height: 16px;
}

@media (max-width: 767.98px) {
    .value-parameters-select-product__item img {
        width: 13px;
        height: 13px;
    }
}

@media (max-width: 1550px) {
    .value-parameters-select-product__item {
        font-size: 15px;
    }
}

@media (max-width: 991.98px) {
    .value-parameters-select-product__item {
        font-size: 14px;
    }
}

@media (max-width: 479.98px) {
    .value-parameters-select-product__item {
        font-size: 13px;
    }
}

.rare-select-product__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 110%;
    color: #FFFFFF;
    /*margin: 0 0 18px -8px;*/
    margin: 20px 0;
}

@media (max-width: 1550px) {
    .rare-select-product__title {
        font-size: 19px;
        margin: 0 0 16px 8px;
    }
}

@media (max-width: 1200px) {
    .rare-select-product__title {
        font-size: 18px;
        margin: 0 0 15px 8px;
    }
}

@media (max-width: 991.98px) {
    .rare-select-product__title {
        font-size: 17px;
        margin: 0 0 14px 8px;
    }
}

@media (max-width: 767.98px) {
    .rare-select-product__title {
        font-size: 16px;
        margin: 0 0 13px 8px;
    }
}

@media (max-width: 479.98px) {
    .rare-select-product__title {
        font-size: 15px;
        margin: 0 0 12px 8px;
    }
}

.rare-select-product__item:not(:last-child) {
    margin: 0 0 3px 0;
}

.rare-select-product__input {
    display: none;
}

.rare-select-product__input:checked + .label-rare-select-product {
    border: 1px solid rgba(255, 108, 61, 0.6);
    background: #4F2D2F;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
    -o-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
}

.label-rare-select-product {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    padding: 6.5px 13px 6.5px 8px;
    border-radius: 150px;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    padding: 6.5px 11.5px 6.5px 8px;
    cursor: pointer;
}

.label-rare-select-product:hover {
    border: 1px solid rgba(255, 108, 61, 0.3);
    background: rgba(79, 45, 47, 0.6);
}

.rare-select-product__options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}
.rare-select-product__title {
    grid-column-start: 1;
    grid-column-end: 3;
}

@media (max-width: 767.98px) {
    .label-rare-select-product {
        padding: 6.5px 10px 6.5px 8px;
    }
    .rare-select-product__options {
        grid-template-columns: 1fr;
    }
    .rare-select-product__title {
        grid-column-start: 1;
        grid-column-end: 2;
        margin: 10px 0;
    }
}

@media (max-width: 479.98px) {
    .label-rare-select-product {
        padding: 6.5px 8px 6.5px 8px;
    }
}

@media (max-width: 400px) {
    .label-rare-select-product {
        width: 100%;
    }
}

.label-rare-select-product__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

@media (max-width: 400px) {
    .label-rare-select-product__row {
        width: 100%;
    }
}

.label-rare-select-product__image {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    overflow: hidden;
    margin: 0 14px 0 0;
}

.label-rare-select-product__image img {
    width: 38px;
    height: 38px;
}

@media (max-width: 991.98px) {
    .label-rare-select-product__image {
        flex: 0 0 51px;
        width: 51px;
        height: 51px;
    }

    .label-rare-select-product__image img {
        width: 32px;
        height: 32px;
    }
}

@media (max-width: 479.98px) {
    .label-rare-select-product__image {
        flex: 0 0 42px;
        width: 42px;
        height: 42px;
    }

    .label-rare-select-product__image img {
        width: 26px;
        height: 26px;
    }
}

@media (max-width: 350px) {
    .label-rare-select-product__image {
        margin: 0 8px 0 0;
    }
}

.label-rare-select-product__name {
    font-size: 16px;
    line-height: 156.25%;
    color: #DFDFDF;
    margin: 0 13px 0 0;
}

@media (max-width: 479.98px) {
    .label-rare-select-product__name {
        font-size: 15px;
    }
}

@media (max-width: 400px) {
    .label-rare-select-product__name {
        margin: 0 5px 0 0;
        flex: 1 1 100%;
    }
}

.label-rare-select-product__price {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 5.5px 15px;
    font-size: 16px;
    line-height: 156.25%;
    text-transform: uppercase;
    color: #DFDFDF;
    white-space: nowrap;
}

@media (max-width: 1200px) {
    .label-rare-select-product__price {
        font-size: 15px;
        padding: 5px 12.5px;
    }
}

@media (max-width: 479.98px) {
    .label-rare-select-product__price {
        font-size: 14px;
        padding: 4.5px 10px;
    }
}

@media (max-width: 400px) {
    .label-rare-select-product__price {
        flex: 0 0 88px;
        width: 88px;
    }
}

@media (max-width: 350px) {
    .label-rare-select-product__price {
        flex: 0 0 78px;
        width: 78px;
    }
}

.characteristics-select-product {
    padding: 25px 0 0 0;
}

@media (max-width: 1300px) {
    .characteristics-select-product_no-authorization .characteristics-select-product__column {
        flex: 0 1 50%;
    }
}

@media (max-width: 991.98px) {
    .characteristics-select-product_no-authorization .characteristics-select-product__column {
        flex: 0 1 68%;
    }
}

@media (max-width: 600px) {
    .characteristics-select-product_no-authorization .characteristics-select-product__column {
        flex: 1 1 100%;
    }
}

.characteristics-select-product_no-authorization .characteristics-select-product__column_quatity {
    flex: 0 1 20%;
}

@media (max-width: 1300px) {
    .characteristics-select-product_no-authorization .characteristics-select-product__column_quatity {
        flex: 0 1 35%;
    }
}

@media (max-width: 991.98px) {
    .characteristics-select-product_no-authorization .characteristics-select-product__column_quatity {
        flex: 0 1 68%;
    }
}

.characteristics-select-product_no-authorization .characteristics-select-product__column_price {
    flex: 0 1 10%;
}

@media (max-width: 1300px) {
    .characteristics-select-product_no-authorization .characteristics-select-product__column_price {
        flex: 0 1 15%;
    }
}

@media (max-width: 991.98px) {
    .characteristics-select-product_no-authorization .characteristics-select-product__column_price {
        flex: 0 1 30%;
    }
}

@media (max-width: 479.98px) {
    .characteristics-select-product {
        padding: 22px 0 0 0;
    }
}

.characteristics-select-product__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 110%;
    color: #FFFFFF;
}

@media (max-width: 1550px) {
    .characteristics-select-product__title {
        font-size: 19px;
    }
}

@media (max-width: 1200px) {
    .characteristics-select-product__title {
        font-size: 18px;
    }
}

@media (max-width: 479.98px) {
    .characteristics-select-product__title {
        font-size: 17px;
    }
}

@media (max-width: 767.98px) {
    .characteristics-select-product__title {
        font-size: 16px;
    }
}

@media (max-width: 479.98px) {
    .characteristics-select-product__title {
        font-size: 15px;
    }
}

.characteristics-select-product__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -12.5px 0 -12.5px;
}

.characteristics-select-product__column {
    padding: 0 12.5px;
    flex: 0 1 33.333%;
    margin: 0 0 18px 0;
}

@media (max-width: 991.98px) {
    .characteristics-select-product__column {
        flex: 0 1 50%;
    }
}

@media (max-width: 500px) {
    .characteristics-select-product__column {
        flex: 1 1 100%;
    }
}

@media (max-width: 479.98px) {
    .characteristics-select-product__column {
        margin: 0 0 15px 0;
    }
}

.characteristics-select-product__label {
    font-size: 15px;
    line-height: 166.6666666667%;
    color: #CBCBCB;
}

.characteristics-select-product__label:not(:last-child) {
    margin: 0 0 7px 0;
}

.characteristics-select-product__input {
    width: 100%;
    height: 53px;
    pointer-events: none;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    overflow: hidden;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 5px 25px;
    text-align: center;
    font-size: 18px;
    line-height: 138.8888888889%;
    color: #DFDFDF;
}

@media (max-width: 1200px) {
    .characteristics-select-product__input {
        height: 49px;
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .characteristics-select-product__input {
        height: 44px;
        font-size: 16px;
        padding: 5px 25px;
    }
}

.characteristics-select-product__input-block {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.characteristics-select-product__minus {
    position: absolute;
    font-size: 24px;
    color: #fff;
    left: 20px;
    cursor: pointer;
    background-color: transparent;
}

.characteristics-select-product__plus {
    position: absolute;
    font-size: 24px;
    color: #fff;
    right: 20px;
    cursor: pointer;
    background-color: transparent;
}

.characteristics-select-product__price {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    height: 53px;
    font-size: 18px;
    line-height: 138.8888888889%;
    white-space: nowrap;
    color: #DFDFDF;
}

@media (max-width: 1200px) {
    .characteristics-select-product__price {
        height: 49px;
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .characteristics-select-product__price {
        height: 44px;
        font-size: 16px;
    }
}

.bottom-select-product_no-authorization {
    padding: 0;
    border-top: 0;
}

.bottom-select-product_no-authorization .bottom-select-product__row {
    justify-content: flex-end;
}

.bottom-select-product__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: space-between;
}

.bottom-select-product__price {
    margin: 0 20px 0 0;
}

.bottom-select-product__btn {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-transition: background-color 0.3s ease 0s;
    -moz-transition: background-color 0.3s ease 0s;
    -ms-transition: background-color 0.3s ease 0s;
    -o-transition: background-color 0.3s ease 0s;
    transition: background-color 0.3s ease 0s;
    background-color: #FF6C3D;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    color: #FFFFFF;
}

.bottom-select-product__row .error {
    display: block;
    margin-top: 10px;
    color: red;
}

.bottom-select-product__row {
    color: white;
    font-size: 14px;
    flex-direction: column;
    align-items: flex-end;
}

.bought-success {
    font-size: 14px;
    color: lawngreen;
}

.bottom-select-product__btn:hover {
    background-color: #e76134;
}

.bottom-select-product__btn.loading {
    -webkit-transform: rotate(900deg);
    -moz-transform: rotate(900deg);
    -ms-transform: rotate(900deg);
    -o-transform: rotate(900deg);
    transform: rotate(900deg);
    -webkit-transition: all 10s linear;
    -moz-transition: all 10s linear;
    -ms-transition: all 10s linear;
    -o-transition: all 10s linear;
    transition: all 10s linear;
    background-color: #6b311e;
}

.bottom-select-product__btn::before {
    position: absolute;
    content: "";
    width: 80px;
    height: 80px;
    top: 12px;
    border-radius: 50%;
    background: rgba(225, 72, 23, 0.5019607843);
    filter: blur(20px);
    z-index: -1;
}

.price-bottom-select-product__label {
    font-size: 15px;
    line-height: 166.6666666667%;
    color: #CBCBCB;
}

.price-bottom-select-product__label:not(:last-child) {
    margin: 0 0 3px 0;
}

@media (max-width: 479.98px) {
    .price-bottom-select-product__label {
        font-size: 14px;
    }
}

.price-bottom-select-product__value {
    font-size: 24px;
    line-height: 104.1666666667%;
    color: #DFDFDF;
}

@media (max-width: 1550px) {
    .price-bottom-select-product__value {
        font-size: 23px;
    }
}

@media (max-width: 991.98px) {
    .price-bottom-select-product__value {
        font-size: 22px;
    }
}

@media (max-width: 767.98px) {
    .price-bottom-select-product__value {
        font-size: 21px;
    }
}

@media (max-width: 479.98px) {
    .price-bottom-select-product__value {
        font-size: 20px;
    }
}

.roulette {
    position: relative;
}

.roulette::before {
    position: absolute;
    content: "";
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -54px;
    right: -111px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(250px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .roulette::before {
        animation: circle 5s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .roulette::before {
        filter: blur(350px);
    }
}

.roulette__main {
    margin: 0 0 0 165px;
    padding: 100px 0 80px 0;
}

@media (max-width: 1700px) {
    .roulette__main {
        padding: 90px 0 73px 0;
    }
}

@media (max-width: 1550px) {
    .roulette__main {
        margin: 0 0 0 135px;
        padding: 80px 0 67.5px 0;
    }
}

@media (max-width: 1500px) {
    .roulette__main {
        margin: 0 0 0 122px;
        padding: 70px 0 62px 0;
    }
}

@media (max-width: 1200px) {
    .roulette__main {
        padding: 60px 0 56.5px 0;
    }
}

@media (max-width: 991.98px) {
    .roulette__main {
        margin: 0;
        padding: 50px 0 51px 0;
    }
}

@media (max-width: 767.98px) {
    .roulette__main {
        padding: 40px 0 45.5px 0;
    }
}

@media (max-width: 479.98px) {
    .roulette__main {
        padding: 30px 0 40px 0;
    }
}

.roulette__body {
    position: relative;
    z-index: 25;
}

.roulette__title:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1200px) {
    .roulette__title:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 479.98px) {
    .roulette__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

.roulette__image-block {
    position: relative;
    border-radius: 35px;
    overflow: hidden;
    border: 3px solid #505E70;
    padding: 0 0 25.35% 0;
}

.roulette__image-block:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1550px) {
    .roulette__image-block:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 991.98px) {
    .roulette__image-block {
        padding: 0 0 33% 0;
    }

    .roulette__image-block:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 767.98px) {
    .roulette__image-block {
        padding: 0 0 40% 0;
    }
}

@media (max-width: 479.98px) {
    .roulette__image-block {
        padding: 0 0 47.6% 0;
    }

    .roulette__image-block:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

.roulette__image {
    position: absolute;
    top: 0;
    left: -1%;
    width: 102%;
    height: 100%;
}

.roulette__image::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 10, 22, 0.7) 0%, rgba(8, 28, 54, 0) 100%);
}

.roulette__select-category {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.roulette__select-category:not(:last-child) {
    margin: 0 0 30px 0;
}

.roulette__select-category .select-category__column {
    margin: 0 0 30px 0;
}

.roulette__select-category .select-category__btn {
    transform: translate(0, 0);
    margin: -46px 0 0 0;
}

@media (max-width: 1550px) {
    .roulette__select-category .select-category__btn {
        height: 87px;
        margin: -43.5px 0 0 0;
    }
}

@media (max-width: 991.98px) {
    .roulette__select-category .select-category__btn {
        height: 82px;
        margin: -41px 0 0 0;
    }
}

@media (max-width: 767.98px) {
    .roulette__select-category .select-category__btn {
        height: 76px;
        margin: -38px 0 0 0;
    }
}

@media (max-width: 479.98px) {
    .roulette__select-category .select-category__btn {
        height: 71px;
        margin: -35.5px 0 0 0;
    }
}

.roulette__filter:not(:last-child) {
    margin: 0 0 50px 0;
}

@media (max-width: 1200px) {
    .roulette__filter:not(:last-child) {
        margin: 0 0 45px 0;
    }
}

@media (max-width: 479.98px) {
    .roulette__filter:not(:last-child) {
        margin: 0 0 40px 0;
    }
}

.roulette__users {
    position: relative;
}

.roulette__users::before {
    position: absolute;
    /*content: "";*/
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -184px;
    right: -140px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(250px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .roulette__users::before {
        animation: circle 5s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .roulette__users::before {
        filter: blur(350px);
    }
}

.filter-roulette {
    position: relative;
    z-index: 1;
}

.filter-roulette__menu:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 767.98px) {
    .filter-roulette__menu:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

.filter-roulette__categories:not(:last-child) {
    margin: 0 0 20px 0;
}

.filter-roulette__games:not(:last-child) {
    margin: 0 0 30px 0;
}

@media (max-width: 1200px) {
    .filter-roulette__games:not(:last-child) {
        margin: 0 0 24px 0;
    }
}

@media (max-width: 479.98px) {
    .filter-roulette__games:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

.filter-roulette__btn-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    justify-content: center;
}

.filter-roulette__btn {
    box-shadow: 0 0 60px 2px rgba(225, 72, 23, 0.5019607843);
    cursor: pointer;
}

.menu-filter-roulette {
    padding: 0 0 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.menu-filter-roulette__list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -5px;
}

@media (max-width: 479.98px) {
    .menu-filter-roulette__list {
        justify-content: center;
    }
}

.menu-filter-roulette__item {
    padding: 0 5px;
    margin: 0 0 10px 0;
}

.menu-filter-roulette__link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 44px;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: #1E385B;
    padding: 5px 19px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
    line-height: 137.5%;
    color: #FFFFFF;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media (max-width: 479.98px) {
    .menu-filter-roulette__link {
        height: 41px;
        padding: 5px 13.5px;
        font-size: 15px;
    }
}

.menu-filter-roulette__link:hover {
    border: 1px solid rgba(255, 108, 61, 0.6);
    background: rgba(255, 108, 61, 0.6);
}

.menu-filter-roulette__link.active {
    border: 1px solid #FF6C3D;
    background: #FF6C3D;
}

.categories-filter-roulette__items {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-wrap: wrap;
    align-items: center;
}

.categories-filter-roulette__item button {
    margin-left: 9px;
    margin-top: -2px;
    background: transparent;
    border: none;
}
.categories-filter-roulette__item {
    margin: 0 10px 10px 0;
    display: flex;
    align-items: center;
}

.categories-filter-roulette__link.active {
    border: 1px solid rgba(255, 255, 255, 0.7568627451);
}

/*.categories-filter-roulette__link::before {*/
/*    position: absolute;*/
/*    content: "";*/
/*    width: 80px;*/
/*    height: 80px;*/
/*    border-radius: 50%;*/
/*    top: 11px;*/
/*    z-index: -1;*/
/*}*/

@media (max-width: 1200px) {
    .categories-filter-roulette__link {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .categories-filter-roulette__link {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .categories-filter-roulette__link {
        height: 47px;
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .categories-filter-roulette__link {
        height: 44px;
        font-size: 14px;
    }
}

.categories-filter-roulette__link_blue::before {
    background: rgba(0, 55, 128, 0.8);
    left: 15px;
    filter: blur(10px);
}

.categories-filter-roulette__link_orange::before {
    background: rgba(255, 108, 61, 0.3019607843);
    right: 20px;
    filter: blur(16px);
}

.games-filter-roulette__title:not(:last-child) {
    margin: 0 0 30px 0;
}

@media (max-width: 1200px) {
    .games-filter-roulette__title:not(:last-child) {
        margin: 0 0 24px 0;
    }
}

@media (max-width: 479.98px) {
    .games-filter-roulette__title:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

.games-filter-roulette__body {
    border-radius: 35px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 20px;
}

.games-filter-roulette__row {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    border-radius: 35px;
    padding: 20px;
    pointer-events: none;
}

.games-filter-roulette__row::before {
    position: absolute;
    content: "";
    width: 69px;
    height: 69px;
    border-radius: 20px;
    left: 41%;
    top: 18px;
    background: #FF6C3D;
    filter: blur(50px);
}

@media screen and (max-width: 576px) {
    .games-filter-roulette__element::before,
    .games-filter-roulette__element::after,
    .games-filter-roulette__row::after,
    .games-filter-roulette__row::before {
        display: none;
    }
}

.games-filter-roulette__row::after {
    position: absolute;
    content: "";
    width: 69px;
    height: 69px;
    border-radius: 20px;
    right: 41%;
    top: 18px;
    background: #FF6C3D;
    filter: blur(50px);
}

@media (max-width: 1200px) {
    .games-filter-roulette__row {
        padding: 15px;
    }
}

.games-filter-roulette__element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 35px;
}

.games-filter-roulette__element::before {
    position: absolute;
    content: "";
    width: 69px;
    height: 69px;
    border-radius: 20px;
    left: 41%;
    bottom: 18px;
    background: #FF6C3D;
    filter: blur(50px);
}

.games-filter-roulette__element::after {
    position: absolute;
    content: "";
    width: 69px;
    height: 69px;
    border-radius: 20px;
    right: 41%;
    bottom: 18px;
    background: #FF6C3D;
    filter: blur(50px);
}

.games-filter-roulette__column {
    flex: 0 1 20%;
    z-index: 1;
}

.games-filter-roulette__column:not(:last-child) {
    margin: 0 20px 0 0;
}

@media (max-width: 1200px) {
    .games-filter-roulette__column:not(:last-child) {
        margin: 0 10px 0 0;
    }
}

@media (max-width: 767.98px) {
    .games-filter-roulette__column:not(:last-child) {
        margin: 0 0px 0 0;
    }

    .games-filter-roulette__column:nth-child(3) {
        margin: 0 10px 0 0;
    }

    .games-filter-roulette__column:nth-child(4) {
        margin: 0 10px 0 0;
    }
}

@media (max-width: 767.98px) {
    .games-filter-roulette__column {
        flex: 0 1 33.333%;
    }
}

@media (max-width: 767.98px) {
    .games-filter-roulette__column_hide {
        display: none;
    }
}

.games-filter-roulette__column_border {
    position: relative;
}

.games-filter-roulette__column_border::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    border: 3px solid #FF6C3D;
    clip-path: inset(0 82% 82% 0);
}

@media (max-width: 767.98px) {
    .games-filter-roulette__column_border::before {
        clip-path: inset(0 82% 88% 0);
    }
}

@media (max-width: 479.98px) {
    .games-filter-roulette__column_border::before {
        /*border-radius: 15px;*/
    }
}

.games-filter-roulette__column_border::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    border: 3px solid #FF6C3D;
    clip-path: inset(0 0 82% 82%);
}

@media (max-width: 767.98px) {
    .games-filter-roulette__column_border::after {
        clip-path: inset(0 0 88% 82%);
    }
}

@media (max-width: 479.98px) {
    .games-filter-roulette__column_border::after {
        /*border-radius: 15px;*/
    }
}

.games-filter-roulette__column_border span {
    position: absolute;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.games-filter-roulette__column_border span::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 3px solid #FF6C3D;
    clip-path: inset(82% 82% 0 0);
}

@media (max-width: 767.98px) {
    .games-filter-roulette__column_border span::before {
        clip-path: inset(88% 82% 0 0);
    }
}

@media (max-width: 479.98px) {
    .games-filter-roulette__column_border span::before {
        /*border-radius: 15px;*/
    }
}

.games-filter-roulette__column_border span::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    border: 3px solid #FF6C3D;
    clip-path: inset(82% 0 0 82%);
}

@media (max-width: 767.98px) {
    .games-filter-roulette__column_border span::after {
        clip-path: inset(88% 0 0 82%);
    }
}

@media (max-width: 479.98px) {
    .games-filter-roulette__column_border span::after {
        /*border-radius: 15px;*/
    }
}

.games-filter-roulette__slider {
    position: relative;
    border-radius: 35px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 20px;
    margin: 0 0 30px 0;
}

@media (max-width: 1200px) {
    .games-filter-roulette__slider {
        padding: 15px;
        margin: 0 0 25px 0;
    }
}

@media (max-width: 479.98px) {
    .games-filter-roulette__slider {
        width: 140%;
        transform: translate(-16%, 0);
        border-radius: 0;
        margin: 0 0 20px 0;
    }
}

.title-games-filter-roulette {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.title-games-filter-roulette__icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    margin: 0 13px 0 0;
}

.title-games-filter-roulette__icon img {
    width: 22px;
    height: 22px;
}

@media (max-width: 1200px) {
    .title-games-filter-roulette__icon {
        flex: 0 0 40px;
        width: 40px;
        height: 40px;
        margin: 0 11px 0 0;
    }

    .title-games-filter-roulette__icon img {
        width: 17.5px;
        height: 17.5px;
    }
}

@media (max-width: 479.98px) {
    .title-games-filter-roulette__icon {
        flex: 0 0 30px;
        width: 30px;
        height: 30px;
        margin: 0 9px 0 0;
    }

    .title-games-filter-roulette__icon img {
        width: 13px;
        height: 13px;
    }
}

.title-games-filter-roulette__text {
    font-family: Barlow;
    font-size: 40px;
    font-weight: 400;
    line-height: 137.5%;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media (max-width: 1700px) {
    .title-games-filter-roulette__text {
        font-size: 37px;
    }
}

@media (max-width: 1550px) {
    .title-games-filter-roulette__text {
        font-size: 34px;
    }
}

@media (max-width: 1200px) {
    .title-games-filter-roulette__text {
        font-size: 31px;
    }
}

@media (max-width: 991.98px) {
    .title-games-filter-roulette__text {
        font-size: 29px;
    }
}

@media (max-width: 767.98px) {
    .title-games-filter-roulette__text {
        font-size: 26px;
    }
}

@media (max-width: 479.98px) {
    .title-games-filter-roulette__text {
        font-size: 24px;
    }
}

.item-games-filter-roulette {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    padding: 0 0 119.2% 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media (max-width: 767.98px) {
    .item-games-filter-roulette {
        padding: 0 0 156% 0;
    }
}

@media (max-width: 479.98px) {
    .item-games-filter-roulette {
        border-radius: 15px;
    }
}

.item-games-filter-roulette__image {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}

.item-games-filter-roulette__image::before {
    display: none;
    position: absolute;
    content: "Lot name";
    width: 90%;
    height: 48px;
    left: 5%;
    bottom: 10px;
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.2);
    font-size: 16px;
    font-weight: 500;
    line-height: 137.5%;
    color: #FFFFFF;
    bottom: 10px;
}

.item-games-filter-roulette__name {
    position: absolute;
    bottom: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    width: 90%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 13px;
    border-radius: 11px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    line-height: 137.5%;
    color: #FFFFFF;
}

.item-games-filter-roulette__name::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .4);
    border-radius: 11px;
    /*filter: blur(45px);*/
}

.item-games-filter-roulette__name span {
    position: relative;
    z-index: 1;
}

@media (max-width: 991.98px) {
    .item-games-filter-roulette__name {
        padding: 12px 0;
    }
}

@media (max-width: 479.98px) {
    .item-games-filter-roulette__name {
        width: 87%;
        padding: 11px 0;
        font-size: 15px;
        bottom: 5px;
    }
}

@media (max-width: 350px) {
    .item-games-filter-roulette__name {
        font-size: 14px;
    }
}

.user-page {
    position: relative;
}

.user-page::before {
    position: absolute;
    content: "";
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -54px;
    right: -111px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(250px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .user-page::before {
        animation: circle 5s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .user-page::before {
        filter: blur(350px);
    }
}

.user-page__main {
    margin: 0 0 0 165px;
    padding: 100px 0 80px 0;
}

@media (max-width: 1700px) {
    .user-page__main {
        padding: 90px 0 73px 0;
    }
}

@media (max-width: 1550px) {
    .user-page__main {
        margin: 0 0 0 135px;
        padding: 80px 0 67.5px 0;
    }
}

@media (max-width: 1500px) {
    .user-page__main {
        margin: 0 0 0 122px;
        padding: 70px 0 62px 0;
    }
}

@media (max-width: 1200px) {
    .user-page__main {
        padding: 60px 0 56.5px 0;
    }
}

@media (max-width: 991.98px) {
    .user-page__main {
        margin: 0;
        padding: 50px 0 51px 0;
    }
}

@media (max-width: 767.98px) {
    .user-page__main {
        padding: 40px 0 45.5px 0;
    }
}

@media (max-width: 479.98px) {
    .user-page__main {
        padding: 30px 0 40px 0;
    }
}

.user-page__body {
    position: relative;
    z-index: 25;
}

.user-page__title:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1200px) {
    .user-page__title:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 479.98px) {
    .user-page__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

.user-page__card:not(:last-child) {
    margin: 0 0 40px 0;
}

@media (max-width: 767.98px) {
    .user-page__card:not(:last-child) {
        margin: 0 0 35px 0;
    }
}

.card-user-page {
    padding: 30px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}

@media (max-width: 1550px) {
    .card-user-page {
        padding: 25px;
    }
}

@media (max-width: 991.98px) {
    .card-user-page {
        padding: 20px;
    }
}

@media (max-width: 479.98px) {
    .card-user-page {
        padding: 15px;
    }
}

.card-user-page__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: space-between;
}

.card-user-page__column {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

@media (max-width: 479.98px) {
    .card-user-page__column {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.card-user-page__image {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    flex: 0 0 100px;
    width: 100px;
    height: 100px;
    border-radius: 15px;
    overflow: hidden;
    margin: 0 20px 0 0;
}

.card-user-page__image img {
    width: 67px;
    height: 67px;
}

@media (max-width: 1550px) {
    .card-user-page__image {
        flex: 0 0 90px;
        width: 90px;
        height: 90px;
    }
}

@media (max-width: 991.98px) {
    .card-user-page__image {
        flex: 0 0 80px;
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 767.98px) {
    .card-user-page__image {
        flex: 0 0 70px;
        width: 70px;
        height: 70px;
        margin: 0 17.5px 0 0;
    }
}

@media (max-width: 479.98px) {
    .card-user-page__image {
        flex: 0 0 60px;
        width: 60px;
        height: 60px;
        margin: 0 15px 0 0;
    }
}

.card-user-page__name {
    font-size: 22px;
    color: #FFFFFF;
}

@media (max-width: 1700px) {
    .card-user-page__name {
        font-size: 21px;
    }
}

@media (max-width: 1550px) {
    .card-user-page__name {
        font-size: 20px;
    }
}

@media (max-width: 1200px) {
    .card-user-page__name {
        font-size: 19px;
    }
}

@media (max-width: 991.98px) {
    .card-user-page__name {
        font-size: 18px;
    }
}

@media (max-width: 767.98px) {
    .card-user-page__name {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .card-user-page__name {
        font-size: 16px;
        margin: 0 0 7px 0;
    }
}

.card-user-page__id {
    font-size: 20px;
    line-height: 110%;
    color: #9D9D9D;
}

@media (max-width: 1700px) {
    .card-user-page__id {
        font-size: 19px;
    }
}

@media (max-width: 1550px) {
    .card-user-page__id {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    .card-user-page__id {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .card-user-page__id {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .card-user-page__id {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .card-user-page__id {
        font-size: 14px;
    }
}

.history-user-page__title {
    font-weight: 400;
}

.history-user-page__title:not(:last-child) {
    margin: 0 0 20px 0;
}

@media (max-width: 1700px) {
    .history-user-page__title {
        font-size: 42px;
    }
}

@media (max-width: 1550px) {
    .history-user-page__title {
        font-size: 40px;
    }
}

@media (max-width: 1200px) {
    .history-user-page__title {
        font-size: 38px;
    }
}

@media (max-width: 991.98px) {
    .history-user-page__title {
        font-size: 36px;
    }
}

@media (max-width: 767.98px) {
    .history-user-page__title {
        font-size: 34px;
    }
}

@media (max-width: 479.98px) {
    .history-user-page__title {
        font-size: 32px;
    }
}

.history-user-page__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.history-user-page__column {
    padding: 0 10px;
    flex: 0 1 50%;
    margin: 0 0 20px 0;
}

@media (max-width: 1200px) {
    .history-user-page__column {
        flex: 1 1 100%;
    }
}

@media (max-width: 479.98px) {
    .history-user-page__column {
        margin: 0 0 15px 0;
    }
}

.profile {
    position: relative;
}

.profile::before {
    position: absolute;
    content: "";
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -54px;
    right: -111px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(250px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .profile::before {
        animation: circle 5s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .profile::before {
        filter: blur(350px);
    }
}

.profile__main {
    margin: 0 0 0 165px;
    padding: 100px 0 80px 0;
}

@media (max-width: 1700px) {
    .profile__main {
        padding: 90px 0 73px 0;
    }
}

@media (max-width: 1550px) {
    .profile__main {
        margin: 0 0 0 135px;
        padding: 80px 0 67.5px 0;
    }
}

@media (max-width: 1500px) {
    .profile__main {
        margin: 0 0 0 122px;
        padding: 70px 0 62px 0;
    }
}

@media (max-width: 1200px) {
    .profile__main {
        padding: 60px 0 56.5px 0;
    }
}

@media (max-width: 991.98px) {
    .profile__main {
        margin: 0;
        padding: 50px 0 51px 0;
    }
}

@media (max-width: 767.98px) {
    .profile__main {
        padding: 40px 0 45.5px 0;
    }
}

@media (max-width: 479.98px) {
    .profile__main {
        padding: 30px 0 40px 0;
    }
}

.profile__body {
    position: relative;
    z-index: 25;
}

.profile__title:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1200px) {
    .profile__title:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 479.98px) {
    .profile__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

.profile__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: flex-start;
    justify-content: space-between;
}

.profile__row:not(:last-child) {
    margin: 0 0 30px 0;
}

@media (max-width: 1400px) {
    .profile__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .info-profile {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}
@media (max-width: 600px) {
    .profile__row {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .profile__user:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 479.98px) {
    .profile__user:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

.profile .content-inner {
    padding: 30px;
}

@media (max-width: 1550px) {
    .profile .content-inner {
        padding: 25px;
    }
}

@media (max-width: 991.98px) {
    .profile .content-inner {
        padding: 20px;
    }
}

@media (max-width: 479.98px) {
    .profile .content-inner {
        padding: 10px;
    }
}

.profile .bonuses__replenishment:not(:last-child) {
    margin: 0 0 30px 0;
}

.user-profile {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.user-profile__image {
    flex: 0 0 100px;
    width: 100px;
    height: 100px;
    border-radius: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 2px solid;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    margin: 0 20px 0 0;
    overflow: hidden;
}

.user-profile__image img {
    width: 100%;
    height: 100%;
}

@media (max-width: 1550px) {
    .user-profile__image {
        flex: 0 0 90px;
        width: 90px;
        height: 90px;
    }
}

@media (max-width: 991.98px) {
    .user-profile__image {
        flex: 0 0 80px;
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 767.98px) {
    .user-profile__image {
        flex: 0 0 70px;
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 479.98px) {
    .user-profile__image {
        flex: 0 0 60px;
        width: 60px;
        height: 60px;
        margin-right: 10px;
    }
}

.user-profile__image.green {
    border-color: rgba(4, 198, 0, 0.4);
}

.user-profile__image.yellow {
    border-color: yellow;
}

.user-profile__image.blue {
    border-color: blue;
}

.user-profile__name {
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
}

.user-profile__name:not(:last-child) {
    margin: 0 0 15px 0;
}

@media (max-width: 1200px) {
    .user-profile__name {
        font-size: 17px;
    }

    .user-profile__name:not(:last-child) {
        margin: 0 0 10px 0;
    }
}

@media (max-width: 479.98px) {
    .user-profile__name {
        font-size: 16px;
    }

    .user-profile__name:not(:last-child) {
        margin: 0 0 5px 0;
    }
}

.user-profile__level {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 55px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    padding: 5px 20px;
    font-size: 18px;
    line-height: 137.5%;
    color: #FFFFFF;
    border-radius: 4px;
}

.user-profile__level span {
    font-weight: 600;
    margin: 0 0 0 5px;
}

@media (max-width: 1550px) {
    .user-profile__level {
        font-size: 17px;
        height: 51.5px;
    }
}

@media (max-width: 1200px) {
    .user-profile__level {
        font-size: 16px;
        height: 48px;
    }
}

@media (max-width: 991.98px) {
    .user-profile__level {
        font-size: 15px;
        height: 44;
    }
}

@media (max-width: 767.98px) {
    .user-profile__level {
        font-size: 14px;
        height: 41px;
    }
}

@media (max-width: 479.98px) {
    .user-profile__level {
        font-size: 13px;
        height: 38px;
    }
}

@media (max-width: 350px) {
    .user-profile__level {
        padding: 5px 15px;
    }
}

.info-profile {
    margin-top: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    align-items: flex-end;
}

@media (max-width: 600px) {
    .info-profile {
        width: 100%;
        justify-content: space-between;
        padding: 0;
    }
}

.info-profile__id {
    font-size: 16px;
    line-height: 137.5%;
    color: #9D9D9D;
}

.info-profile__id:not(:last-child) {
    margin: 0 0 10px 0;
}

.replenishment-bonuses__btn.exit-button {
    padding: 5px 10px;
    height: auto;
    margin-top: 10px;
    text-transform: uppercase;
    cursor: pointer;
}

@media (min-width: 601px) {
    .user-profile .exit-button {
        display: none;
    }
}

@media (max-width: 600px) {
    .user-profile .exit-button {
        margin-right: auto;
        font-size: 12px;
    }

    .user-profile__body {
        margin-top: 10px;
    }

    .info-profile .replenishment-bonuses__btn.exit-button {
        display: none;
    }

    .profile__user:not(:last-child) {
        width: 100%;
        flex-wrap: wrap;
    }
}

@media (max-width: 1200px) {
    .info-profile__id {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .info-profile__id {
        font-size: 14px;
    }
}

.balance-info-profile {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    font-size: 18px;
    color: #FFFFFF;
}

@media (max-width: 1550px) {
    .balance-info-profile {
        font-size: 17px;
    }
}

@media (max-width: 1200px) {
    .balance-info-profile {
        font-size: 16px;
    }
}

@media (max-width: 991.98px) {
    .balance-info-profile {
        font-size: 15px;
    }
}

@media (max-width: 767.98px) {
    .balance-info-profile {
        font-size: 14px;
    }
}

@media (max-width: 479.98px) {
    .balance-info-profile {
        font-size: 13px;
    }
}

.balance-info-profile__text {
    margin: 0 10px 0 0;
}

@media (max-width: 350px) {
    .balance-info-profile__text {
        margin: 0 7px 0 0;
    }
}

.balance-info-profile__value {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 44px;
    padding: 5px 14px 5px 15px;
    text-transform: uppercase;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    font-size: 18px;
}

@media (max-width: 1550px) {
    .balance-info-profile__value {
        font-size: 17px;
    }
}

@media (max-width: 1200px) {
    .balance-info-profile__value {
        font-size: 16px;
    }
}

@media (max-width: 991.98px) {
    .balance-info-profile__value {
        font-size: 15px;
    }
}

@media (max-width: 767.98px) {
    .balance-info-profile__value {
        font-size: 14px;
        height: 41px;
    }
}

@media (max-width: 479.98px) {
    .balance-info-profile__value {
        font-size: 13px;
        height: 38px;
        padding: 5px 12px 5px 13px;
    }
}

@media (max-width: 350px) {
    .balance-info-profile__value {
        padding: 5px 10px;
    }
}

.history-profile {
    padding: 0 0 35px 0;
}

/*@media screen and (min-width: 1024px) {*/
/*    .history-profile {*/
/*        max-width: 60vw;*/
/*    }*/

/*}*/

.history-profile__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-wrap: wrap;
    margin: 0 -12px;
}

.history-profile__column {
    /*padding: 0 12px;*/
    flex: 0 1 50%;
    /*margin: 0 0 20px 0;*/
}

@media (max-width: 1650px) {
    .history-profile__column {
        flex: 1 1 100%;
    }
}

@media (max-width: 479.98px) {
    .history-profile__column {
        /*margin: 0 0 15px 0;*/
    }
}

.history-profile__navigation {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0 0;
    z-index: 1;
}

.history-profile__btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 0 0 45px;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    overflow: hidden;
    background: url("../img/icons/arrow-white.svg") center no-repeat;
    background-color: #FF6C3D;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.history-profile__btn:hover {
    background-color: #e76134;
}

.history-profile__btn_prev {
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin: 0 6px 0 0;
}

.history-profile__btn_next {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 0 0 0 6px;
}

@media (max-width: 767.98px) {
    .history-profile__btn {
        flex: 0 0 38px;
        width: 38px;
        height: 38px;
    }
}

@media (max-width: 479.98px) {
    .history-profile__btn {
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
    }
}

.history-profile__pagination {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    width: auto !important;
}

.history-profile__pagination .swiper-pagination-bullet {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    font-size: 18px;
    line-height: 177.7777777778%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    opacity: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.history-profile__pagination .swiper-pagination-bullet:hover {
    border: 1px solid rgba(255, 108, 61, 0.4);
    background: linear-gradient(0deg, rgba(255, 108, 61, 0.1), rgba(255, 108, 61, 0.1));
}

@media (max-width: 767.98px) {
    .history-profile__pagination .swiper-pagination-bullet {
        flex: 0 0 38px;
        width: 38px;
        height: 38px;
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .history-profile__pagination .swiper-pagination-bullet {
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
        font-size: 16px;
    }
}

.history-profile__pagination .swiper-pagination-bullet-active {
    border: 1px solid rgba(255, 108, 61, 0.4);
    background: linear-gradient(0deg, rgba(255, 108, 61, 0.1), rgba(255, 108, 61, 0.1));
}

.item-history-profile {
    padding: 25px 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    border-radius: 7px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    position: relative;
}

.item-history-profile::before, .item-history-profile::after {
    position: absolute;
    content: "";
    width: 104px;
    height: 104px;
    border-radius: 50%;
    filter: blur(50px);
}

.item-history-profile::before {
    background: rgba(0, 92, 211, 0.8);
    top: 12px;
    left: -27px;
    z-index: -1;
}

.item-history-profile::after {
    left: 15px;
    bottom: 2px;
    background-color: #FF6C3D;
    z-index: -2;
}

@media (max-width: 1400px) {
    .item-history-profile {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }
    .item-history-profile__date::before {
        display: none;
    }
    .amount-item-history-profile {
        margin-top: 10px;
    }
}
@media (max-width: 767.98px) {
    .item-history-profile {
        padding: 20px 15px;
    }
}

@media (max-width: 550px) {
    .item-history-profile {
        padding: 17.5px 12.5px;
    }
}

@media (max-width: 500px) {
    .item-history-profile {
        flex-direction: column;
    }
}

@media (max-width: 479.98px) {
    .item-history-profile {
        padding: 15px 12.5px;
    }
}

.item-history-profile__date {
    position: relative;
    margin: 0 45px 0 0;
}

.item-history-profile__date::before {
    position: absolute;
    content: "";
    width: 1.5px;
    height: 100%;
    top: 0;
    right: -23.5px;
    background-color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 550px) {
    .item-history-profile__date {
        margin: 0 35px 0 0;
    }

    .item-history-profile__date::before {
        right: -18.5px;
    }
}

@media (max-width: 500px) {
    .item-history-profile__date {
        margin: 0 0 30px 0;
    }

    .item-history-profile__date::before {
        left: 0;
        right: auto;
        top: auto;
        bottom: -15px;
        width: 100%;
        height: 1.5px;
    }
}

.date-item-history-profile {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
}

@media (max-width: 1550px) {
    .date-item-history-profile {
        font-size: 17px;
    }
}

@media (max-width: 500px) {
    .date-item-history-profile {
        width: 100%;
    }
}

.date-item-history-profile__label {
    white-space: nowrap;
    margin: 0 5px 0 0;
    white-space: nowrap;
}

.date-item-history-profile__value {
    font-weight: 600;
}

.amount-item-history-profile {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
}

@media (max-width: 1550px) {
    .amount-item-history-profile {
        font-size: 17px;
    }
}

@media (max-width: 500px) {
    .amount-item-history-profile {
        width: 100%;
    }
}

.amount-item-history-profile__label {
    white-space: nowrap;
    margin: 0 5px 0 0;
}

.amount-item-history-profile__value {
    white-space: nowrap;
    font-weight: 600;
}

.bonuses {
    position: relative;
}

.bonuses::before {
    position: absolute;
    content: "";
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -54px;
    right: -111px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(250px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .bonuses::before {
        animation: circle 5s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .bonuses::before {
        filter: blur(350px);
    }
}

.bonuses__main {
    margin: 0 0 0 165px;
    padding: 100px 0 80px 0;
}

@media (max-width: 1700px) {
    .bonuses__main {
        padding: 90px 0 73px 0;
    }
}

@media (max-width: 1550px) {
    .bonuses__main {
        margin: 0 0 0 135px;
        padding: 80px 0 67.5px 0;
    }
}

@media (max-width: 1500px) {
    .bonuses__main {
        margin: 0 0 0 122px;
        padding: 70px 0 62px 0;
    }
}

@media (max-width: 1200px) {
    .bonuses__main {
        padding: 60px 0 56.5px 0;
    }
}

@media (max-width: 991.98px) {
    .bonuses__main {
        margin: 0;
        padding: 50px 0 51px 0;
    }
}

@media (max-width: 767.98px) {
    .bonuses__main {
        padding: 40px 0 45.5px 0;
    }
}

@media (max-width: 479.98px) {
    .bonuses__main {
        padding: 30px 0 40px 0;
    }
}

.bonuses__body {
    position: relative;
    z-index: 25;
}

.bonuses__title:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1200px) {
    .bonuses__title:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 479.98px) {
    .bonuses__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (min-width: 1100px) {
    .bonuses__inner .inner__row {
        align-items: flex-start;
    }
}

.bonuses__inner .content-inner {
    overflow: visible;
}

.bonuses__discount:not(:last-child) {
    margin: 0 0 20px 0;
}

@media (max-width: 1200px) {
    .bonuses__discount:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 479.98px) {
    .bonuses__discount:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

.discount-bonuses {
    width: 100%;
    max-width: 680px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid #3D4C5F;
    background: linear-gradient(0deg, rgba(8, 28, 54, 0.7), rgba(8, 28, 54, 0.7));
    padding: 25px 30px;
}

@media (max-width: 1200px) {
    .discount-bonuses {
        padding: 20px 25px;
    }
}

@media (max-width: 991.98px) {
    .discount-bonuses {
        padding: 15px 20px;
    }
}

@media (max-width: 479.98px) {
    .discount-bonuses {
        padding: 10px 15px;
    }
}

.discount-bonuses__body {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    margin: 0 0 30px 0;
}

@media (max-width: 991.98px) {
    .discount-bonuses__body {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 35px 0;
    }
}

.discount-bonuses__text {
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
    margin: 0 20px 0 0;
}

@media (max-width: 1200px) {
    .discount-bonuses__text {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .discount-bonuses__text {
        font-size: 16px;
        margin: 0 0 12px 0;
    }
}

.discount-bonuses__info {
    font-size: 12px;
    line-height: 157.1428571429%;
    color: #C9C9C9;
}

.progress-discount-bonuses {
    width: 100%;
    position: relative;
}

.progress-discount-bonuses .progress__line {
    /*.progress-discount-bonuses::before {*/
    position: absolute;
    content: "";
    width: 28%;
    height: 6px;
    border-radius: 120px 0 0 120px;
    top: 0;
    left: 0;
    background-color: #FF6C3D;
    z-index: 1;
}

.progress-discount-bonuses__line {
    width: 100%;
    height: 6px;
    border-radius: 120px;
    background-color: #fff;
    position: relative;
}

.progress-discount-bonuses__line .from {
    position: absolute;
    content: "0%";
    font-size: 14px;
    line-height: 157.1428571429%;
    color: #E0E0E0;
    left: -2px;
    bottom: -28px;
}

@media (max-width: 479.98px) {
    .progress-discount-bonuses__line .from {
        font-size: 13px;
    }
}

.progress-discount-bonuses__line .to {
    position: absolute;
    content: "100%";
    font-size: 14px;
    line-height: 157.1428571429%;
    color: #E0E0E0;
    right: 0;
    bottom: -28px;
}

@media (max-width: 479.98px) {
    .progress-discount-bonuses__line .to {
        font-size: 13px;
    }
}

.progress-discount-bonuses__point {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #fff;
    border: 4px solid #EB5D30;
    top: -4px;
    left: 27%;
    z-index: 1;
}

.progress-discount-bonuses__point .amount {
    position: absolute;
    content: "27%";
    font-size: 14px;
    line-height: 157.1428571429%;
    color: #E0E0E0;
    left: -100%;
    bottom: -28px;
}

@media (max-width: 479.98px) {
    .progress-discount-bonuses__point .amount {
        font-size: 13px;
    }
}

.replenishment-bonuses__title {
    font-family: Barlow;
    font-size: 30px;
    font-weight: 400;
    line-height: 116.6666666667%;
    color: #fff;
    text-transform: uppercase;
}

.replenishment-bonuses__title:not(:last-child) {
    margin: 0 0 22px 0;
}

@media (max-width: 1550px) {
    .replenishment-bonuses__title {
        font-size: 28px;
    }

    .replenishment-bonuses__title:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 1200px) {
    .replenishment-bonuses__title {
        font-size: 26px;
    }

    .replenishment-bonuses__title:not(:last-child) {
        margin: 0 0 17px 0;
    }
}

@media (max-width: 991.98px) {
    .replenishment-bonuses__title {
        font-size: 24px;
    }

    .replenishment-bonuses__title:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 767.98px) {
    .replenishment-bonuses__title {
        font-size: 22px;
    }

    .replenishment-bonuses__title:not(:last-child) {
        margin: 0 0 12px 0;
    }
}

@media (max-width: 479.98px) {
    .replenishment-bonuses__title {
        font-size: 20px;
    }

    .replenishment-bonuses__title:not(:last-child) {
        margin: 0 0 10px 0;
    }
}

.replenishment-bonuses__text {
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
}

.replenishment-bonuses__text:not(:last-child) {
    margin: 0 0 10px 0;
}

@media (max-width: 1550px) {
    .replenishment-bonuses__text {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .replenishment-bonuses__text {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .replenishment-bonuses__text {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .replenishment-bonuses__text {
        font-size: 14px;
    }
}

.replenishment-bonuses__calculator:not(:last-child) {
    margin: 0 0 20px 0;
}

.replenishment-bonuses__btn {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 55px;
    padding: 5px 25px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #FF6C3D;
    font-family: Barlow;
    font-size: 15px;
    font-weight: 600;
    line-height: 160%;
    color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.replenishment-bonuses__btn:hover {
    background-color: #e76134;
}

@media (max-width: 1550px) {
    .replenishment-bonuses__btn {
        height: 53px;
    }
}

@media (max-width: 1200px) {
    .replenishment-bonuses__btn {
        height: 50px;
    }
}

@media (max-width: 991.98px) {
    .replenishment-bonuses__btn {
        height: 48px;
        padding: 5px 22.5px;
    }
}

@media (max-width: 767.98px) {
    .replenishment-bonuses__btn {
        height: 46px;
    }
}

@media (max-width: 479.98px) {
    .replenishment-bonuses__btn {
        height: 44px;
        padding: 5px 20px;
    }
}

.calculator-replenishment-bonuses {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.calculator-replenishment-bonuses__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.calculator-replenishment-bonuses__item span {
    font-size: 16px;
    line-height: 137.5%;
    color: #FFFFFF;
    margin: 0 0 0 12px;
}

@media (max-width: 767.98px) {
    .calculator-replenishment-bonuses__item span {
        font-size: 15px;
    }
}

@media (max-width: 350px) {
    .calculator-replenishment-bonuses__item span {
        margin: 0 0 0 10px;
    }
}

.calculator-replenishment-bonuses__input::-webkit-outer-spin-button,
.calculator-replenishment-bonuses__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.calculator-replenishment-bonuses__input {
    height: 55px;
    width: 78px;
    padding: 5px 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    font-size: 16px;
    line-height: 137.5%;
    color: #FFFFFF;
}

.calculator-replenishment-bonuses__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 1550px) {
    .calculator-replenishment-bonuses__input {
        height: 50px;
        width: 73px;
    }
}

@media (max-width: 991.98px) {
    .calculator-replenishment-bonuses__input {
        height: 45px;
        width: 67.5px;
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .calculator-replenishment-bonuses__input {
        height: 40px;
        width: 62px;
    }
}

@media (max-width: 350px) {
    .calculator-replenishment-bonuses__input {
        width: 56px;
    }
}

.calculator-replenishment-bonuses__equal {
    flex: 0 0 37px;
    width: 37px;
    height: 37px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    font-size: 20px;
    color: #FFFFFF;
    margin: 0 20px 0 12px;
}

@media (max-width: 1550px) {
    .calculator-replenishment-bonuses__equal {
        font-size: 19px;
    }
}

@media (max-width: 991.98px) {
    .calculator-replenishment-bonuses__equal {
        flex: 0 0 33.5px;
        width: 33.5px;
        height: 33.5px;
        font-size: 18px;
    }
}

@media (max-width: 767.98px) {
    .calculator-replenishment-bonuses__equal {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .calculator-replenishment-bonuses__equal {
        flex: 0 0 30px;
        width: 30px;
        height: 30px;
        font-size: 16px;
        margin: 0 17px 0 14px;
    }
}

@media (max-width: 350px) {
    .calculator-replenishment-bonuses__equal {
        margin: 0 12px 0 10px;
    }
}

.calculator-replenishment-bonuses__valuta {
    padding: 0 8px 0 2px;
}

.calculator-replenishment-bonuses__valuta .dropdown__button {
    padding: 0 0 0 10px;
    width: 48px;
    height: auto;
    background: transparent;
    border-radius: 0;
    border: 0;
}

.calculator-replenishment-bonuses__valuta .dropdown__button::before {
    right: 0;
}

@media (max-width: 350px) {
    .calculator-replenishment-bonuses__valuta .dropdown__button {
        width: 44px;
    }
}

.calculator-replenishment-bonuses__valuta .dropdown__list {
    top: 30px;
}

.calculator-replenishment-bonuses__valuta .dropdown__list-item {
    height: auto;
    border: 0;
    border-radius: 0;
    padding: 0 0 0 10px;
}

.purchases {
    position: relative;
}

.purchases::before {
    position: absolute;
    content: "";
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -54px;
    right: -111px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(250px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .purchases::before {
        animation: circle 5s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .purchases::before {
        filter: blur(350px);
    }
}

.purchases__main {
    margin: 0 0 0 165px;
    padding: 100px 0 80px 0;
}

@media (max-width: 1700px) {
    .purchases__main {
        padding: 90px 0 73px 0;
    }
}

@media (max-width: 1550px) {
    .purchases__main {
        margin: 0 0 0 135px;
        padding: 80px 0 67.5px 0;
    }
}

@media (max-width: 1500px) {
    .purchases__main {
        margin: 0 0 0 122px;
        padding: 70px 0 62px 0;
    }
}

@media (max-width: 1200px) {
    .purchases__main {
        padding: 60px 0 56.5px 0;
    }
}

@media (max-width: 991.98px) {
    .purchases__main {
        margin: 0;
        padding: 50px 0 51px 0;
    }
}

@media (max-width: 767.98px) {
    .purchases__main {
        padding: 40px 0 45.5px 0;
    }
}

@media (max-width: 479.98px) {
    .purchases__main {
        padding: 30px 0 40px 0;
    }
}

.purchases__body {
    position: relative;
    z-index: 25;
}

.purchases__title:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1200px) {
    .purchases__title:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 479.98px) {
    .purchases__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (min-width: 1100px) {
    .purchases__inner .inner__row {
        align-items: flex-start;
    }
}

.purchases__inner .content-inner {
    border-radius: 0;
    overflow: hidden;
    border: 0;
    background: transparent;
    padding: 0;
}

.purchases__inner .replenishment-bonuses__title {
    text-align: center;
}

.purchases__items {
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 30px;
}

@media (max-width: 1550px) {
    .purchases__items {
        padding: 25px;
    }
}

@media (max-width: 991.98px) {
    .purchases__items {
        padding: 20px;
    }
}

@media (max-width: 479.98px) {
    .purchases__items {
        padding: 15px;
    }
}

.purchases__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-wrap: wrap;
    margin: 0 -18.5px;
}

@media (max-width: 1500px) {
    .purchases__row {
        margin: 0 -12.5px;
    }
}

@media (max-width: 767.98px) {
    .purchases__row {
        margin: 0 -10px;
    }
}

@media (max-width: 479.98px) {
    .purchases__row {
        margin: 0 -7.5px;
    }
}

.purchases__column {
    /*padding: 0 18.5px;*/
    flex: 16.667%;
    /*margin: 0 0 25px 0;*/
}

@media (max-width: 1450px) {
    .purchases__column {
        flex: 0 1 25%;
        /*padding: 12.5px;*/
    }
}

@media (max-width: 991.98px) {
    .purchases__column {
        /*margin: 0 0 20px 0;*/
    }
}

@media (max-width: 767.98px) {
    .purchases__column {
        /*padding: 0 10px;*/
    }
}

@media (max-width: 600px) {
    .purchases__column {
        flex: 0 1 33.333%;
    }
}

@media (max-width: 479.98px) {
    .purchases__column {
        /*padding: 0 7.5px;*/
        flex: 0 1 50%;
    }
}

.purchases__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
}

.purchases__users {
    margin: 100px 0 40px 0;
}

.purchases__users::before {
    display: none;
}

.roulette__main .roulette__users {
    max-width: 1100px;
    margin: 0 auto;
}

.roulette__users.purchases__users {
    display: block;
}

.item-purchases__image-block {
    position: relative;
    padding: 0 0 98.7% 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}

.item-purchases__image-block:not(:last-child) {
    margin: 0 0 12px 0;
}

.item-purchases__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
}

.item-purchases__image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.item-purchases__name {
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FFFFFF;
}

.item-purchases__name:not(:last-child) {
    margin: 0 0 10px 0;
}

@media (max-width: 1200px) {
    .item-purchases__name {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .item-purchases__name {
        font-size: 16px;
    }
}

.item-purchases__bottom {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 122.2222222222%;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 0 3px;
}

@media (max-width: 1200px) {
    .item-purchases__bottom {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .item-purchases__bottom {
        font-size: 16px;
    }
}

.item-purchases__line {
    width: 1px;
    height: 16px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    margin: 0 15px;
}

.item-purchases__btn {
    margin: 7px 0 0 0;
    font-size: 18px;
    line-height: 122.2222222222%;
    color: #FF6C3D;
    text-decoration: underline;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: transparent;
    border: none;
    text-align: left;
}

.item-purchases__btn:hover {
    text-decoration: none;
}

@media (max-width: 1200px) {
    .item-purchases__btn {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .item-purchases__btn {
        font-size: 16px;
    }
}

.purchases-slider__navigation {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    transform: translate(0, 30px);
    z-index: 1;
}

.purchases-slider__btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 0 0 45px;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    overflow: hidden;
    background: url("../img/icons/arrow-white.svg") center no-repeat;
    background-color: #FF6C3D;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.purchases-slider__btn:hover {
    background-color: #e76134;
}

.purchases-slider__btn_prev {
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin: 0 6px 0 0;
}

.purchases-slider__btn_next {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 0 0 0 6px;
}

@media (max-width: 767.98px) {
    .purchases-slider__btn {
        flex: 0 0 38px;
        width: 38px;
        height: 38px;
    }
}

@media (max-width: 479.98px) {
    .purchases-slider__btn {
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
    }
}

.purchases-slider__pagination {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    width: auto !important;
}

.users__pagination .swiper-pagination-bullet {
    margin: 0 4px;
}

.purchases-slider__pagination .swiper-pagination-bullet {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    font-size: 18px;
    line-height: 177.7777777778%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    opacity: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin: 0 4px;
}

.users__pagination span:after,
.purchases-slider__pagination span:after {
    content: counter(resNewNums);
}

.users__pagination span,
.purchases-slider__pagination span {
    counter-increment: resNewNums;
}

.users__pagination,
.purchases-slider__pagination {
    counter-reset: resNewNums;
}

.purchases-slider__pagination .swiper-pagination-bullet:hover {
    border: 1px solid rgba(255, 108, 61, 0.4);
    background: linear-gradient(0deg, rgba(255, 108, 61, 0.1), rgba(255, 108, 61, 0.1));
}

@media (max-width: 767.98px) {
    .purchases-slider__pagination .swiper-pagination-bullet {
        flex: 0 0 38px;
        width: 38px;
        height: 38px;
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .purchases-slider__pagination .swiper-pagination-bullet {
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
        font-size: 16px;
    }
}

.purchases-slider__pagination .swiper-pagination-bullet-active {
    border: 1px solid rgba(255, 108, 61, 0.4);
    background: linear-gradient(0deg, rgba(255, 108, 61, 0.1), rgba(255, 108, 61, 0.1));
}

.inventory {
    position: relative;
}

.inventory::before {
    position: absolute;
    content: "";
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -54px;
    right: -111px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(250px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .inventory::before {
        animation: circle 5s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .inventory::before {
        filter: blur(350px);
    }
}

.inventory__main {
    margin: 0 0 0 165px;
    padding: 100px 0 80px 0;
}

@media (max-width: 1700px) {
    .inventory__main {
        padding: 90px 0 73px 0;
    }
}

@media (max-width: 1550px) {
    .inventory__main {
        margin: 0 0 0 135px;
        padding: 80px 0 67.5px 0;
    }
}

@media (max-width: 1500px) {
    .inventory__main {
        margin: 0 0 0 122px;
        padding: 70px 0 62px 0;
    }
}

@media (max-width: 1200px) {
    .inventory__main {
        padding: 60px 0 56.5px 0;
    }
}

@media (max-width: 991.98px) {
    .inventory__main {
        margin: 0;
        padding: 50px 0 51px 0;
    }
}

@media (max-width: 767.98px) {
    .inventory__main {
        padding: 40px 0 45.5px 0;
    }
}

@media (max-width: 479.98px) {
    .inventory__main {
        padding: 30px 0 40px 0;
    }
}

.inventory__body {
    position: relative;
    z-index: 25;
}

.inventory__title:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1200px) {
    .inventory__title:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 479.98px) {
    .inventory__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

.inventory__inner .content-inner {
    border-radius: 0;
    overflow: hidden;
    border: 0;
    background: transparent;
    padding: 0 0 40px 0;
}

@media (max-width: 479.98px) {
    .inventory__inner .content-inner {
        padding: 0 0 35px 0;
    }
}

.inventory__inner .replenishment-bonuses__title {
    text-align: center;
}

.inventory__inner .purchases__items {
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 30px;
}

@media (max-width: 1550px) {
    .inventory__inner .purchases__items {
        padding: 25px;
    }
}

@media (max-width: 991.98px) {
    .inventory__inner .purchases__items {
        padding: 20px;
    }
}

@media (max-width: 479.98px) {
    .inventory__inner .purchases__items {
        padding: 15px;
    }
}

.home {
    position: relative;
    padding: 0 0 55px 0;
}

@media (max-width: 1700px) {
    .home {
        padding: 0 0 49px 0;
    }
}

@media (max-width: 1550px) {
    .home {
        padding: 0 0 43px 0;
    }
}

@media (max-width: 1200px) {
    .home {
        padding: 0 0 36.5px 0;
    }
}

@media (max-width: 991.98px) {
    .home {
        padding: 0 0 31px 0;
    }
}

@media (max-width: 767.98px) {
    .home {
        padding: 0 0 25.5px 0;
    }
}

@media (max-width: 479.98px) {
    .home {
        padding: 0 0 20px 0;
    }
}

.main-block {
    position: relative;
    width: 100%;
}

.main-block__inner {
    position: relative;
    width: 100%;
    padding: 0 0 71% 0;
}

@media (max-width: 991.98px) {
    .main-block__inner {
        padding: 0 0 80% 0;
    }
}

@media (max-width: 767.98px) {
    .main-block__inner {
        padding: 0 0 90% 0;
    }
}

@media (max-width: 600px) {
    .main-block__inner {
        padding: 0 0 100% 0;
    }
}

@media (max-width: 479.98px) {
    .main-block__inner {
        padding: 0 0 124% 0;
    }
}

@media (max-width: 350px) {
    .main-block__inner {
        padding: 0 0 138% 0;
    }
}

.main-block__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main-block__image_img::before {
    position: absolute;
    content: "";
    width: 696px;
    height: 696px;
    border-radius: 50%;
    bottom: -306px;
    right: -252px;
    background: #051223;
    filter: blur(125px);
}

@media (min-width: 991.98px) {
    .main-block__image_img::before {
        animation: circle 6s linear infinite;
    }
}

@media (max-width: 767.98px) {
    .main-block__image_img::before {
        display: none;
    }
}

.main-block__image_video::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: 1;
}

.main-block__image::after {
    position: absolute;
    content: "";
    width: 36.25%;
    height: 50.35%;
    border-radius: 50%;
    bottom: -244px;
    left: -141px;
    background-color: #051223;
    filter: blur(100px);
}

@media (min-width: 991.98px) {
    .main-block__image::after {
        animation: circle 6s linear infinite;
    }
}

.main-block__image video {
    position: absolute;
    top: -17.5%;
    left: 0;
    width: 100%;
    height: 135%;
    object-fit: cover;
}

.main-block__picture-block {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.main-block__picture {
    position: absolute;
    z-index: 1;
    background-size: contain;
}

.main-block__picture_dragon {
    width: 52.45%;
    height: 58.32%;
    right: 5%;
    bottom: 10%;
    background-position: bottom;
}

@media (max-width: 991.98px) {
    .main-block__picture_dragon {
        width: 55%;
        height: 60%;
        bottom: 8.5%;
    }
}

@media (max-width: 767.98px) {
    .main-block__picture_dragon {
        bottom: 7%;
    }
}

@media (max-width: 479.98px) {
    .main-block__picture_dragon {
        right: 1%;
        width: 70%;
        bottom: 5%;
    }
}

.main-block__picture_cloud-1 {
    width: 21.77%;
    height: 19.8%;
    right: 60%;
    bottom: 20%;
}

@media (max-width: 991.98px) {
    .main-block__picture_cloud-1 {
        right: 67%;
        bottom: 20%;
        width: 28%;
        height: 22%;
    }
}

@media (max-width: 767.98px) {
    .main-block__picture_cloud-1 {
        bottom: 19%;
    }
}

@media (max-width: 479.98px) {
    .main-block__picture_cloud-1 {
        width: 33%;
        bottom: 2%;
        right: 80%;
    }
}

.main-block__picture_bird {
    width: 15.67%;
    height: 20.18%;
    right: 60%;
    bottom: 32%;
}

@media (max-width: 991.98px) {
    .main-block__picture_bird {
        right: 69%;
        bottom: 31%;
    }
}

@media (max-width: 767.98px) {
    .main-block__picture_bird {
        width: 20%;
        bottom: 31%;
    }
}

@media (max-width: 479.98px) {
    .main-block__picture_bird {
        right: 82%;
        bottom: 12%;
    }
}

.main-block__picture_cloud-2 {
    width: 21.77%;
    height: 19.8%;
    right: 48%;
    bottom: 37%;
}

@media (max-width: 991.98px) {
    .main-block__picture_cloud-2 {
        width: 28%;
        height: 22%;
        right: 50%;
        bottom: 37%;
    }
}

@media (max-width: 767.98px) {
    .main-block__picture_cloud-2 {
        bottom: 33%;
    }
}

@media (max-width: 479.98px) {
    .main-block__picture_cloud-2 {
        width: 33%;
        right: 63%;
        bottom: 15%;
    }
}

.content-main-block {
    padding: 234px 0 0 0px;
    margin: 0 0 0 165px;
    position: absolute;
    top: 0;
    left: 0;
    /*width: 100%;*/
    width: auto;
    height: 100%;
    z-index: 25;
}

@media (max-width: 1800px) {
    .content-main-block {
        padding: 210px 0 0 0px;
    }
}

@media (max-width: 1700px) {
    .content-main-block {
        padding: 195px 0 0 0px;
    }
}

@media (max-width: 1550px) {
    .content-main-block {
        margin: 0 0 0 135px;
        padding: 170px 0 0 0px;
    }
}

@media (max-width: 1500px) {
    .content-main-block {
        margin: 0 0 0 122px;
    }
}

@media (max-width: 1300px) {
    .content-main-block {
        padding: 150px 0 0 0px;
    }
}

@media (max-width: 991.98px) {
    .content-main-block {
        padding: 135px 0 0 0px;
        margin: 0;
    }
}

@media (max-width: 767.98px) {
    .content-main-block {
        padding: 120px 0 0 0px;
    }
}

@media (max-width: 479.98px) {
    .content-main-block {
        padding: 100px 0 0 0px;
    }
}

.content-main-block__body {
    padding: 0 86.5px;
}

@media (max-width: 1550px) {
    .content-main-block__body {
        padding: 0 65px;
    }
}

@media (max-width: 1300px) {
    .content-main-block__body {
        padding: 0 35px;
    }
}

@media (max-width: 991.98px) {
    .content-main-block__body {
        padding: 0 20px;
    }
}

@media (max-width: 767.98px) {
    .content-main-block__body {
        padding: 0;
    }
}

.content-main-block__title {
    font-family: Barlow;
    font-size: 150px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    text-transform: uppercase;
}

.content-main-block__title:not(:last-child) {
    margin: 0 0 28px 0;
}

.content-main-block__title span {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
}

@media (max-width: 1800px) {
    .content-main-block__title {
        font-size: 130px;
    }

    .content-main-block__title span {
        height: 90px;
    }

    .content-main-block__title:not(:last-child) {
        margin: 0 0 26px 0;
    }
}

@media (max-width: 1700px) {
    .content-main-block__title {
        font-size: 110px;
    }

    .content-main-block__title span {
        height: 77px;
    }

    .content-main-block__title:not(:last-child) {
        margin: 0 0 22.5px 0;
    }
}

@media (max-width: 1550px) {
    .content-main-block__title {
        font-size: 90px;
    }

    .content-main-block__title span {
        height: 67px;
    }

    .content-main-block__title:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 1200px) {
    .content-main-block__title {
        font-size: 70px;
    }

    .content-main-block__title span {
        height: 51px;
    }

    .content-main-block__title:not(:last-child) {
        margin: 0 0 17.5px 0;
    }
}

@media (max-width: 991.98px) {
    .content-main-block__title {
        font-size: 60px;
    }

    .content-main-block__title span {
        height: 44px;
    }

    .content-main-block__title:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 767.98px) {
    .content-main-block__title {
        font-size: 50px;
    }

    .content-main-block__title span {
        height: 36px;
    }

    .content-main-block__title:not(:last-child) {
        margin: 0 0 12.5px 0;
    }
}

@media (max-width: 479.98px) {
    .content-main-block__title {
        font-size: 40px;
    }

    .content-main-block__title span {
        height: 28px;
    }

    .content-main-block__title:not(:last-child) {
        margin: 0 0 10px 0;
    }
}

@media (max-width: 375px) {
    .content-main-block__title {
        font-size: 34px;
    }

    .content-main-block__title span {
        height: 25px;
    }
}

.content-main-block__subtitle {
    font-size: 20px;
    line-height: 175%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.content-main-block__subtitle:not(:last-child) {
    margin: 0 0 28px 0;
}

@media (max-width: 991.98px) {
    .content-main-block__subtitle br {
        display: none;
    }
}

@media (max-width: 1700px) {
    .content-main-block__subtitle {
        font-size: 19px;
    }
}

@media (max-width: 1500px) {
    .content-main-block__subtitle {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    .content-main-block__subtitle {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .content-main-block__subtitle {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .content-main-block__subtitle {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .content-main-block__subtitle {
        font-size: 14px;
    }

    .content-main-block__subtitle:not(:last-child) {
        margin: 0 0 16px 0;
    }
}

@media (max-width: 400px) {
    .content-main-block__subtitle {
        font-size: 13px;
    }
}

.sobre-nosotros {
    margin: -7% 0 0 165px;
    position: relative;
    z-index: 30;
}

@media (max-width: 1550px) {
    .sobre-nosotros {
        margin: -7% 0 0 135px;
    }
}

@media (max-width: 1500px) {
    .sobre-nosotros {
        margin: -7% 0 0 122px;
    }
}

@media (max-width: 991.98px) {
    .sobre-nosotros {
        margin: -7% 0 0 0;
    }
}

.sobre-nosotros__body {
    padding: 94px 103px 23px 94px;
    border-radius: 50px;
    background: #081A31;
}

@media (max-width: 1700px) {
    .sobre-nosotros__body {
        padding: 83px 87px 20px 80px;
    }
}

@media (max-width: 1550px) {
    .sobre-nosotros__body {
        padding: 73px 72px 17px 70px;
    }
}

@media (max-width: 1200px) {
    .sobre-nosotros__body {
        padding: 63px 57px 15px 60px;
    }
}

@media (max-width: 991.98px) {
    .sobre-nosotros__body {
        padding: 53px 42px 12px 50px;
    }
}

@media (max-width: 767.98px) {
    .sobre-nosotros__body {
        padding: 43px 27px 10px 40px;
    }
}

@media (max-width: 479.98px) {
    .sobre-nosotros__body {
        padding: 33px 12px 8px 30px;
    }
}

@media (max-width: 350px) {
    .sobre-nosotros__body {
        padding: 30px 12px 7px 20px;
    }
}

.sobre-nosotros__label:not(:last-child) {
    margin: 0 0 9px 0;
}

.sobre-nosotros__title {
    max-width: 1050px;
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sobre-nosotros__title:not(:last-child) {
    margin: 0 0 35px 0;
}

.sobre-nosotros__title p {
    display: flex;
    flex: 1 1 100%;
}

.sobre-nosotros__title p:first-child {
    margin: 0 0 26px 0;
}

@media (max-width: 1700px) {
    .sobre-nosotros__title p:first-child {
        margin: 0 0 24px 0;
    }
}

@media (max-width: 1550px) {
    .sobre-nosotros__title p:first-child {
        margin: 0 0 21px 0;
    }
}

@media (max-width: 1200px) {
    .sobre-nosotros__title p:first-child {
        margin: 0 0 19px 0;
    }
}

@media (max-width: 991.98px) {
    .sobre-nosotros__title p:first-child {
        margin: 0 0 16px 0;
    }
}

@media (max-width: 767.98px) {
    .sobre-nosotros__title p:first-child {
        margin: 0 0 13px 0;
    }
}

@media (max-width: 479.98px) {
    .sobre-nosotros__title p:first-child {
        margin: 0 0 11px 0;
    }
}

.sobre-nosotros__title p:last-child {
    /*justify-content: flex-end;*/
}

@media (max-width: 479.98px) {
    .sobre-nosotros__title p:last-child {
        justify-content: flex-start;
    }
}

@media (max-width: 1700px) {
    .sobre-nosotros__title {
        max-width: 940px;
    }

    .sobre-nosotros__title:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 1550px) {
    .sobre-nosotros__title {
        max-width: 815px;
    }

    .sobre-nosotros__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 1200px) {
    .sobre-nosotros__title {
        max-width: 705px;
    }

    .sobre-nosotros__title:not(:last-child) {
        margin: 0 0 21px 0;
    }
}

@media (max-width: 991.98px) {
    .sobre-nosotros__title {
        max-width: 585px;
    }

    .sobre-nosotros__title:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 767.98px) {
    .sobre-nosotros__title {
        max-width: 470px;
    }

    .sobre-nosotros__title:not(:last-child) {
        margin: 0 0 11px 0;
    }
}

@media (max-width: 479.98px) {
    .sobre-nosotros__title {
        max-width: none;
    }

    .sobre-nosotros__title:not(:last-child) {
        margin: 0 0 6px 0;
    }
}

@media (max-width: 350px) {
    .sobre-nosotros__title {
        font-size: 27px;
    }
}

.sobre-nosotros__subtitle {
    max-width: 1042px;
    font-family: Mazzard;
    font-size: 20px;
    line-height: 175%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.sobre-nosotros__subtitle:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 1700px) {
    .sobre-nosotros__subtitle {
        font-size: 19px;
    }
}

@media (max-width: 1550px) {
    .sobre-nosotros__subtitle {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    .sobre-nosotros__subtitle {
        font-size: 17px;
    }

    .sobre-nosotros__subtitle:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 991.98px) {
    .sobre-nosotros__subtitle {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .sobre-nosotros__subtitle {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .sobre-nosotros__subtitle {
        font-size: 14px;
    }

    .sobre-nosotros__subtitle:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

.sobre-nosotros__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    justify-content: space-between;
    margin: 0 -10px;
}

@media (max-width: 1550px) {
    .sobre-nosotros__row {
        flex-wrap: wrap;
    }
}

.sobre-nosotros__column {
    padding: 0 10px;
    flex: 0 1 33.333%;
    margin: 0 0 20px 0;
}

@media (max-width: 1500px) {
    .sobre-nosotros__column {
        flex: 0 1 50%;
    }
}

@media (max-width: 767.98px) {
    .sobre-nosotros__column {
        flex: 1 1 100%;
    }
}

.item-sobre-nosotros__image {
    width: 80px;
    height: 80px;
}

.item-sobre-nosotros__image:not(:last-child) {
    margin: 0 0 27px 0;
}

.item-sobre-nosotros__image img {
    max-width: 100%;
}

@media (max-width: 1700px) {
    .item-sobre-nosotros__image {
        width: 73px;
        height: 73px;
    }

    .item-sobre-nosotros__image:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 1550px) {
    .item-sobre-nosotros__image {
        width: 67px;
        height: 67px;
    }

    .item-sobre-nosotros__image:not(:last-child) {
        margin: 0 0 22px 0;
    }
}

@media (max-width: 1200px) {
    .item-sobre-nosotros__image {
        width: 61px;
        height: 61px;
    }

    .item-sobre-nosotros__image:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 991.98px) {
    .item-sobre-nosotros__image {
        width: 55px;
        height: 55px;
    }

    .item-sobre-nosotros__image:not(:last-child) {
        margin: 0 0 18px 0;
    }
}

@media (max-width: 767.98px) {
    .item-sobre-nosotros__image {
        width: 49px;
        height: 49px;
    }

    .item-sobre-nosotros__image:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 479.98px) {
    .item-sobre-nosotros__image {
        width: 43px;
        height: 43px;
    }

    .item-sobre-nosotros__image:not(:last-child) {
        margin: 0 0 13px 0;
    }
}

.item-sobre-nosotros__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 116.6666666667%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.item-sobre-nosotros__title:not(:last-child) {
    margin: 0 0 7px 0;
}

@media (max-width: 1700px) {
    .item-sobre-nosotros__title {
        font-size: 23px;
    }
}

@media (max-width: 1550px) {
    .item-sobre-nosotros__title {
        font-size: 22px;
    }
}

@media (max-width: 1200px) {
    .item-sobre-nosotros__title {
        font-size: 21px;
    }
}

@media (max-width: 991.98px) {
    .item-sobre-nosotros__title {
        font-size: 20px;
    }
}

@media (max-width: 767.98px) {
    .item-sobre-nosotros__title {
        font-size: 19px;
    }

    .item-sobre-nosotros__title:not(:last-child) {
        margin: 0 0 4px 0;
    }
}

@media (max-width: 479.98px) {
    .item-sobre-nosotros__title {
        font-size: 18px;
    }

    .item-sobre-nosotros__title:not(:last-child) {
        margin: 0 0 2px 0;
    }
}

.item-sobre-nosotros__text {
    font-family: Mazzard;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #D3CECE;
}

@media (max-width: 1200px) {
    .item-sobre-nosotros__text {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .item-sobre-nosotros__text {
        font-size: 14px;
    }
}

.servidores {
    padding: 90px 0 0 0;
    margin: 0 0 0 165px;
}

@media (max-width: 1700px) {
    .servidores {
        padding: 82px 0 0 0;
    }
}

@media (max-width: 1550px) {
    .servidores {
        padding: 73px 0 0 0;
        margin: 0 0 0 135px;
    }
}

@media (max-width: 1500px) {
    .servidores {
        margin: 0 0 0 122px;
    }
}

@media (max-width: 1200px) {
    .servidores {
        padding: 65px 0 0 0;
    }
}

@media (max-width: 991.98px) {
    .servidores {
        padding: 56px 0 0 0;
        margin: 0;
    }
}

@media (max-width: 767.98px) {
    .servidores {
        padding: 48px 0 0 0;
    }
}

@media (max-width: 479.98px) {
    .servidores {
        padding: 40px 0 0 0;
    }
}

.servidores__body {
    border-radius: 50px;
    background-color: #FFFFFF;
    padding: 90px 100px 70px 100px;
}

@media (max-width: 1700px) {
    .servidores__body {
        padding: 80px 87.5px 60px 87.5px;
    }
}

@media (max-width: 1550px) {
    .servidores__body {
        padding: 71px 75px 51px 75px;
    }
}

@media (max-width: 1200px) {
    .servidores__body {
        padding: 62px 62.5px 42px 62.5px;
    }
}

@media (max-width: 991.98px) {
    .servidores__body {
        padding: 53px 50px 33px 50px;
    }
}

@media (max-width: 767.98px) {
    .servidores__body {
        padding: 44px 37.5px 24px 37.5px;
    }
}

@media (max-width: 479.98px) {
    .servidores__body {
        padding: 35px 25px 20px 25px;
    }
}

@media (max-width: 350px) {
    .servidores__body {
        padding: 35px 20px 20px 20px;
    }
}

.servidores__label:not(:last-child) {
    margin: 0 0 8px 0;
}

.servidores__title {
    line-height: 1;
}

.servidores__title span {
    display: block;
}

.servidores__title span:first-child {
    margin: 0 0 5px 0;
}

.servidores__title span:nth-child(2) {
    font-size: 65px;
    margin-top: 20px;
}

@media (max-width: 1700px) {
    .servidores__title span:nth-child(2) {
        font-size: 58px;
    }
}

@media (max-width: 1550px) {
    .servidores__title span:nth-child(2) {
        font-size: 52px;
    }
}

@media (max-width: 1200px) {
    .servidores__title span:nth-child(2) {
        font-size: 46px;
    }
}

@media (max-width: 991.98px) {
    .servidores__title span:nth-child(2) {
        font-size: 40px;
    }
}

@media (max-width: 767.98px) {
    .servidores__title span:nth-child(2) {
        font-size: 34px;
        padding: 0;
    }
}

@media (max-width: 479.98px) {
    .servidores__title span:nth-child(2) {
        font-size: 28px;
    }
}

@media (max-width: 350px) {
    .servidores__title span:nth-child(2) {
        font-size: 25px;
    }
}

.servidores__title:not(:last-child) {
    margin: 0 0 20px 0;
}

@media (max-width: 1700px) {
    .servidores__title {
        margin: 0 0 17px 0;
    }
}

@media (max-width: 1550px) {
    .servidores__title {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 1200px) {
    .servidores__title {
        margin: 0 0 12px 0;
    }
}

@media (max-width: 991.98px) {
    .servidores__title {
        margin: 0 0 10px 0;
    }
}

@media (max-width: 767.98px) {
    .servidores__title {
        margin: 0 0 7px 0;
    }
}

@media (max-width: 479.98px) {
    .servidores__title {
        margin: 0 0 5px 0;
    }
}

@media (max-width: 350px) {
    .servidores__title {
        font-size: 26px;
    }
}

.servidores__subtitle {
    font-size: 20px;
    line-height: 175%;
    letter-spacing: -0.01em;
    color: #061325;
}

.servidores__subtitle:not(:last-child) {
    margin: 0 0 28px 0;
}

@media (max-width: 1550px) {
    .servidores__subtitle:not(:last-child) {
        margin: 0 0 24px 0;
    }
}

@media (max-width: 1200px) {
    .servidores__subtitle {
        font-size: 19px;
    }
}

@media (max-width: 991.98px) {
    .servidores__subtitle:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 479.98px) {
    .servidores__subtitle {
        font-size: 18px;
        line-height: 122.2222222222%;
    }

    .servidores__subtitle:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

.servidores__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    gap: 9px;
}

.servidores__column {
    margin: 0 0 20px 0;
    width: 100%;
    overflow: hidden;
}

@media (max-width: 1500px) {
    .servidores__column {
        flex: 0 1 25%;
    }
}

@media (max-width: 1300px) {
    .servidores__column {
        flex: 0 1 33.333%;
    }
}

@media (max-width: 991.98px) {
    .servidores__column {
        flex: 0 1 calc(50% - 5px);
    }

    .servidores__row {
        flex-wrap: wrap;
    }
}

@media (max-width: 650px) {
    .servidores__column {
        flex: 1 1 100%;
    }
}

.item-servidores {
    border-radius: 20px;
    border: 1.5px solid #FF6C3D;
    padding: 6px 4.5px 22px 4.5px;
    overflow: hidden;
    /*height: 100%;*/
}

@media (max-width: 767.98px) {
    .item-servidores {
        padding: 6px 4.5px 19px 4.5px;
    }
}

@media (max-width: 479.98px) {
    .item-servidores {
        padding: 6px 4.5px 15px 4.5px;
    }
}

.item-servidores__body {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
    align-items: center;
}

.item-servidores__image {
    width: 100%;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    padding: 0 0 56.8% 0;
}

.item-servidores__image:not(:last-child) {
    margin: 0 0 15px 0;
}

.item-servidores__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-servidores__content {
    width: 100%;
    padding: 0 14.5px;
}

@media (max-width: 350px) {
    .item-servidores__content {
        padding: 0 5px;
    }
}

.item-servidores__info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.servidores__item .item-servidores__links{
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.servidores__item.servidores__item_active .item-servidores__links {
    margin: 17px 0 0 0;
    padding: 13px 0 0 0;
    border-top: 1.5px solid #051E3D;
}
.servidores__item.servidores__item_active .item-servidores__info .item-servidores__btn img {
    transform: rotateX(-180deg);
}

.item-servidores__info.active .item-servidores__btn img {
    transform: rotateX(-180deg);
}

.item-servidores__name {
    font-size: 18px;
    font-weight: 600;
    line-height: 133.3333333333%;
    color: #061325;
    margin: 0 10px 0 0;
}

@media (max-width: 1200px) {
    .item-servidores__name {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .item-servidores__name {
        font-size: 16px;
    }
}

.item-servidores__btn {
    flex: 0 0 31px;
    width: 31px;
    height: 31px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #FF6C3D;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    transform: rotateX(180deg);
}

.item-servidores__btn:hover {
    background-color: #e76134;
}

.item-servidores__btn img {
    width: 11px;
    height: 7px;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.item-servidores__links {
    width: 100%;
    position: relative;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: none;
}

.item-servidores__link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.item-servidores__link:not(:last-child) {
    margin: 0 0 11px 0;
}

.item-servidores__link img {
    flex: 0 0 44px;
    width: 44px;
    height: 44px;
    margin: 0 10px 0 0;
}

@media (max-width: 1500px) {
    .item-servidores__link img {
        flex: 0 0 51px;
        width: 51px;
        height: 51px;
        margin: 0 12px 0 0;
    }
}

@media (max-width: 350px) {
    .item-servidores__link img {
        margin: 0 8px 0 0;
    }
}

.item-servidores__link span {
    /*max-width: 150px;*/
    font-size: 12px;
    line-height: 146.1538461538%;
    color: #122540;
    overflow-wrap: break-word;
    hyphens: manual;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.item-servidores__link span:hover {
    text-decoration: underline;
}

@media (max-width: 1700px) {
    .item-servidores__link span {
        max-width: 130px;
    }
}

@media (max-width: 1500px) {
    .item-servidores__link span {
        max-width: 150px;
    }
}

@media (max-width: 1400px) {
    .item-servidores__link span {
        max-width: 130px;
    }
}

@media (max-width: 1300px) {
    .item-servidores__link span {
        max-width: 170px;
    }
}

@media (max-width: 1150px) {
    .item-servidores__link span {
        max-width: 150px;
    }
}

@media (max-width: 1100px) {
    .item-servidores__link span {
        max-width: 130px;
    }
}

@media (max-width: 991.98px) {
    .item-servidores__link span {
        max-width: 170px;
    }
}

@media (max-width: 700px) {
    .item-servidores__link span {
        max-width: 150px;
    }
}

@media (max-width: 650px) {
    .item-servidores__link span {
        max-width: 170px;
    }
}

@media (max-width: 350px) {
    .item-servidores__link span {
        max-width: 150px;
    }
}

.news {
    position: relative;
    margin: 0 0 0 165px;
    padding: 56px 0 0 0;
    z-index: 1;
}

@media (max-width: 1550px) {
    .news {
        margin: 0 0 0 135px;
    }
}

@media (max-width: 1500px) {
    .news {
        margin: 0 0 0 122px;
        padding: 50px 0 0 0;
    }
}

@media (max-width: 991.98px) {
    .news {
        margin: 0;
        padding: 45px 0 0 0;
    }
}

@media (max-width: 479.98px) {
    .news {
        padding: 40px 0 0 0;
    }
}

.news::before {
    position: absolute;
    /*content: "";*/
    width: 655px;
    height: 655px;
    border-radius: 50%;
    background: rgba(0, 96, 222, 0.5019607843);
    right: -200px;
    top: -250px;
    filter: blur(500px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .news::before {
        animation: circle 6s linear infinite;
    }
}

@media (max-width: 991.98px) {
    .news::before {
        display: none;
    }
}

.news::after {
    position: absolute;
    /*content: "";*/
    width: 577px;
    height: 577px;
    border-radius: 50%;
    background: #0060DE;
    right: 150px;
    bottom: -200px;
    filter: blur(250px);
    pointer-events: none;
}

@media (min-width: 991.98px) {
    .news::after {
        animation: circle 6s linear infinite;
    }
}

@media (max-width: 991.98px) {
    .news::after {
        filter: blur(400px);
        right: 0;
    }
}

@media (max-width: 479.98px) {
    .news::after {
        filter: blur(600px);
        display: none;
    }
}

.news__inner {
    position: relative;
    z-index: 1;
}

.news__inner .filter-top-cards-categories__items {
    margin: 0 0 10px 0;
}

.news__body {
    padding: 45px 50px 30px 50px;
    border-radius: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3019607843);
}

@media (max-width: 1550px) {
    .news__body {
        padding: 40px 45px 30px 45px;
    }
}

@media (max-width: 1200px) {
    .news__body {
        padding: 40px 40px 30px 40px;
    }
}

@media (max-width: 991.98px) {
    .news__body {
        padding: 35px 35px 30px 35px;
        margin: 0 0 80px 0;
    }
}

@media (max-width: 767.98px) {
    .news__body {
        padding: 30px;
        margin: 0 0 73px 0;
    }
}

@media (max-width: 479.98px) {
    .news__body {
        padding: 25px 15px;
    }
}

.news__today:not(:last-child) {
    margin: 0 0 35px 0;
}

@media (max-width: 991.98px) {
    .news__today:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 479.98px) {
    .news__today:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

.news__slider {
    position: relative;
}

.news__btn {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #FF6C3D;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 20%;
    z-index: 1;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.news__btn:hover {
    background-color: #e76134;
}

.news__btn::before {
    position: absolute;
    content: "";
    width: 16px;
    height: 10px;
    background: url("../img/icons/arrow-white.svg") center no-repeat;
    background-size: 100%;
}

.news__btn_prev {
    left: 0;
    transform: translate(-50%, 0);
}

.news__btn_prev::before {
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

@media (max-width: 991.98px) {
    .news__btn_prev {
        left: 50%;
        transform: translate(-120%, 80px);
    }
}

@media (max-width: 767.98px) {
    .news__btn_prev {
        transform: translate(-117%, 60px);
    }
}

.news__btn_next {
    right: 0;
    transform: translate(50%, 0);
}

.news__btn_next::before {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

@media (max-width: 991.98px) {
    .news__btn_next {
        right: 50%;
        transform: translate(120%, 80px);
    }
}

@media (max-width: 767.98px) {
    .news__btn_next {
        transform: translate(117%, 60px);
    }
}

@media (max-width: 1300px) {
    .news__btn {
        width: 52px;
        height: 52px;
    }

    .news__btn::before {
        width: 13.85px;
        height: 8.65px;
    }
}

@media (max-width: 767.98px) {
    .news__btn {
        width: 44px;
        height: 44px;
    }

    .news__btn::before {
        width: 11.75px;
        height: 7.35px;
    }
}

@media (max-width: 1100px) {
    .news__btn {
        top: 20%;
    }
}

@media (max-width: 991.98px) {
    .news__btn {
        top: 100%;
    }
}

.today-news {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    /*align-items: flex-start;*/
}

@media (max-width: 991.98px) {
    .today-news {
        flex-direction: column;
    }
}

.today-news__image {
    position: relative;
    flex: 0 0 310px;
    width: 100%;
    max-width: 310px;
    border-radius: 20px;
    overflow: hidden;
    margin: 0 28px 0 0;
}

.today-news__image:hover img {
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
    transform: scale(1.02);
}

.today-news__image img {
    max-width: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 991.98px) {
    .today-news__image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-width: 991.98px) {
    .today-news__image {
        order: 2;
        flex: auto;
        max-width: 50%;
        padding: 0 0 35% 0;
    }
}

@media (max-width: 767.98px) {
    .today-news__image {
        max-width: 75%;
        padding: 0 0 52.5% 0;
    }
}

@media (max-width: 479.98px) {
    .today-news__image {
        max-width: 100%;
        padding: 0 0 70% 0;
    }
}

.today-news__body {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: flex-start;
}

@media (max-width: 1200px) {
    .today-news__body {
        flex-direction: column;
    }
}

@media (max-width: 991.98px) {
    .today-news__body {
        order: 1;
        margin: 0 0 20px 0;
    }
}

.today-news__title {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.today-news__title:hover {
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
}

.today-news__title span {
    white-space: nowrap;
}

.today-news__title:not(:last-child) {
    margin: 0 0 3px 0;
}

.today-news__text {
    font-family: Barlow;
    font-size: 18px;
    font-weight: 400;
    line-height: 155.5555555556%;
    color: #FFFFFF;
    height: 117px;
    overflow: hidden;
}

.today-news__text:not(:last-child) {
    margin: 0 0 14px 0;
}

@media (max-width: 1700px) {
    .today-news__text {
        font-size: 17px;
    }
}

@media (max-width: 1200px) {
    .today-news__text {
        font-size: 16px;
    }
}

@media (max-width: 991.98px) {
    .today-news__text {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .today-news__text {
        font-size: 14px;
    }
}

.today-news__btn {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    width: 141.5px;
    background: #FF6C3D;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 600;
    line-height: 156.25%;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 10px;
    z-index: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.today-news__btn:hover {
    background: #e76134;
}

.today-news__btn::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 4px;
    left: -2px;
    background: #FF6C3D;
    z-index: -1;
    pointer-events: none;
    filter: blur(40px);
}

.today-news__date {
    margin: 0 0 0 170px;
    padding: 55px 0 0 0;
}

@media (max-width: 1700px) {
    .today-news__date {
        margin: 0 0 0 120px;
    }
}

@media (max-width: 1550px) {
    .today-news__date {
        margin: 0 0 0 80px;
    }
}

@media (max-width: 1300px) {
    .today-news__date {
        margin: 0 0 0 50px;
    }
}

@media (max-width: 1200px) {
    .today-news__date {
        padding: 20px 0 0 0;
        margin: 0;
    }
}

@media (max-width: 991.98px) {
    .today-news__date {
        padding: 15px 0 0 0;
    }
}

@media (max-width: 479.98px) {
    .today-news__date {
        padding: 10px 0 0;
    }
}

.date-today-news {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.date-today-news__icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #071D39;
}

.date-today-news__icon img {
    width: 20px;
    height: 18px;
}

@media (max-width: 1550px) {
    .date-today-news__icon {
        width: 50px;
        height: 50px;
    }

    .date-today-news__icon img {
        width: 18.2px;
        height: 16.35px;
    }
}

@media (max-width: 991.98px) {
    .date-today-news__icon {
        width: 45px;
        height: 45px;
    }

    .date-today-news__icon img {
        width: 14.1px;
        height: 12.75px;
    }
}

@media (max-width: 479.98px) {
    .date-today-news__icon {
        width: 39px;
        height: 39px;
    }

    .date-today-news__icon img {
        width: 16.35px;
        height: 14.7px;
    }
}

.date-today-news__text {
    font-family: Barlow;
    font-size: 18px;
    font-weight: 600;
    line-height: 155.5555555556%;
    color: #fff;
    margin: 0 0 0 12px;
}

@media (max-width: 1200px) {
    .date-today-news__text {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .date-today-news__text {
        font-size: 16px;
        margin: 0 0 0 10px;
    }
}

.slide-news__image-block {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    padding: 0 0 70% 0;
}

.slide-news__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-news__image-block:not(:last-child) {
    margin: 0 0 10px 0;
}

.slide-news__image-block:hover .slide-news__image {
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
    transform: scale(1.02);
}

.slide-news__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.slide-news__content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    flex-direction: column;
}

.slide-news__title {
    font-family: Barlow;
    font-size: 18px;
    font-weight: 600;
    line-height: 138.8888888889%;
    color: #FFFFFF;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.slide-news__title:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

.slide-news__title span {
    white-space: nowrap;
}

.slide-news__title:not(:last-child) {
    margin: 0 0 10px 0;
}

@media (max-width: 1700px) {
    .slide-news__title {
        font-size: 17px;
    }
}

@media (max-width: 1550px) {
    .slide-news__title {
        font-size: 16px;
    }
}

@media (max-width: 1200px) {
    .slide-news__title {
        font-size: 15px;
    }
}

@media (max-width: 991.98px) {
    .slide-news__title {
        font-size: 14px;
    }
}

@media (max-width: 767.98px) {
    .slide-news__title {
        font-size: 13px;
    }
}

@media (max-width: 479.98px) {
    .slide-news__title {
        font-size: 12px;
    }
}

.slide-news__date:not(:last-child) {
    margin: 0 0 10px 0;
}

.slide-news__text {
    font-family: Barlow;
    font-size: 13px;
    font-weight: 400;
    line-height: 135.7142857143%;
    letter-spacing: 0.01em;
    color: #E3E3E3;
    height: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
    /*white-space: nowrap;*/
}

@media (max-width: 479.98px) {
    .slide-news__text {
        font-size: 12px;
    }
}

.slide-news__link {
    font-family: Barlow;
    font-size: 14px;
    font-weight: 600;
    line-height: 156.25%;
    text-decoration: underline;
    color: #FF6C3D;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: transparent;
    border: none;
    text-align: left;
}

.slide-news__link:hover {
    text-decoration: none;
}

.date-slide-news {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.date-slide-news__icon {
    width: 12px;
    height: 11px;
    margin: 0 7px 0 0;
}

.date-slide-news__icon img {
    max-width: 100%;
}

.date-slide-news__text {
    font-family: Barlow;
    font-size: 12px;
    font-weight: 500;
    line-height: 133.3333333333%;
    color: #FFFFFF;
}

.news-open {
    position: fixed;
    top: 0;
    right: -120%;
    z-index: 200;
    width: 50%;
    height: 100%;
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
}

.news-open.active {
    right: 0;
}

.news-open__body {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #031228;
    width: 100%;
    height: 100%;
    padding: 30px 90px 50px 70px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 50px 0 0 0;
}

@media (max-width: 1700px) {
    .news-open {
        width: 56.5%;
    }

    .news-open__body {
        padding: 28.3px 79px 46px 61px;
    }
}

@media (max-width: 1550px) {
    .news-open {
        width: 62.5%;
    }

    .news-open__body {
        padding: 26.65px 68px 43px 52px;
        border-radius: 40px 0 0 0;
    }
}

@media (max-width: 1200px) {
    .news-open {
        width: 68%;
    }

    .news-open__body {
        padding: 25px 56px 40px 44px;
        border-radius: 30px 0 0 0;
    }
}

@media (max-width: 991.98px) {
    .news-open {
        width: 74%;
    }

    .news-open__body {
        padding: 23.3px 44px 36px 36px;
        border-radius: 20px 0 0 0;
    }
}

@media (max-width: 767.98px) {
    .news-open {
        width: 79.5%;
    }

    .news-open__body {
        padding: 21.65px 32px 33px 28px;
        border-radius: 10px 0 0 0;
    }
}

@media (max-width: 479.98px) {
    .news-open {
        width: 85%;
    }

    .news-open__body {
        padding: 20px 20px 30px 20px;
        border-radius: 0;
    }
}

.news-open__top:not(:last-child) {
    margin: 0 0 50px 0;
}

@media (max-width: 1700px) {
    .news-open__top:not(:last-child) {
        margin: 0 0 45px 0;
    }
}

@media (max-width: 1550px) {
    .news-open__top:not(:last-child) {
        margin: 0 0 40px 0;
    }
}

@media (max-width: 1200px) {
    .news-open__top:not(:last-child) {
        margin: 0 0 35px 0;
    }
}

@media (max-width: 991.98px) {
    .news-open__top:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 767.98px) {
    .news-open__top:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 479.98px) {
    .news-open__top:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

.news-open__content {
    padding: 0 0 0 40px;
}

@media (max-width: 1550px) {
    .news-open__content {
        padding: 0 0 0 30px;
    }
}

@media (max-width: 1200px) {
    .news-open__content {
        padding: 0 0 0 20px;
    }
}

@media (max-width: 991.98px) {
    .news-open__content {
        padding: 0 0 0 10px;
    }
}

@media (max-width: 767.98px) {
    .news-open__content {
        padding: 0;
    }
}

.news-open__btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.news-open__btn:not(:last-child) {
    margin: 0 0 40px 0;
}

.news-open__btn:hover {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.news-open__btn img {
    width: 14px;
    height: 8.9px;
}

@media (max-width: 1550px) {
    .news-open__btn:not(:last-child) {
        margin: 0 0 35px 0;
    }
}

@media (max-width: 1200px) {
    .news-open__btn:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 991.98px) {
    .news-open__btn {
        width: 44px;
        height: 44px;
    }

    .news-open__btn:not(:last-child) {
        margin: 0 0 25px 0;
    }

    .news-open__btn img {
        width: 11px;
        height: 7px;
    }
}

@media (max-width: 767.98px) {
    .news-open__btn:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 479.98px) {
    .news-open__btn:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

.news-open__title:not(:last-child) {
    margin: 0 0 8px 0;
}

.news-open__tegs:not(:last-child) {
    margin: 0 0 12px 0;
}

.news-open__image {
    width: 100%;
    position: relative;
    padding: 0 0 71% 0;
    border-radius: 20px;
    overflow: hidden;
}

.news-open__image:not(:last-child) {
    margin: 0 0 30px 0;
}

.news-open__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 991.98px) {
    .news-open__image:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 767.98px) {
    .news-open__image:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 479.98px) {
    .news-open__image:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

.news-open__date:not(:last-child) {
    margin: 0 0 25px 0;
}

@media (max-width: 991.98px) {
    .news-open__date:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 767.98px) {
    .news-open__date:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 479.98px) {
    .news-open__date:not(:last-child) {
        margin: 0 0 10px 0;
    }
}

.news-open__text {
    font-size: 18px;
    line-height: 138.8888888889%;
    color: #DFDFDF;
}

.news-open__text span {
    font-weight: 700;
}

.news-open__text p:not(:last-child) {
    margin: 0 0 20px 0;
}

@media (max-width: 479.98px) {
    .news-open__text p:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 1550px) {
    .news-open__text {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .news-open__text {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .news-open__text {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .news-open__text {
        font-size: 14px;
    }
}

.tegs-news-open__item {
    color: #fff;
    opacity: 0.9;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.tegs-news-open__item:hover {
    text-decoration: underline;
}

.tegs-news-open__item:not(:last-child) {
    margin: 0 5px 0 0;
}

.news-open-btn {
    cursor: pointer;
}

.date-news-open {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
}

.date-news-open__icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #071D39;
    margin: 0 12px 0 0;
}

@media (max-width: 991.98px) {
    .date-news-open__icon {
        width: 44px;
        height: 44px;
    }

    .date-news-open__icon img {
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@media (max-width: 479.98px) {
    .date-news-open__icon {
        margin: 0 10px 0 0;
    }
}

.date-news-open__text {
    font-family: Barlow;
    font-size: 18px;
    font-weight: 600;
    line-height: 155.5555555556%;
    color: #FFFFFF;
}

@media (max-width: 1550px) {
    .date-news-open__text {
        font-size: 17px;
    }
}

@media (max-width: 991.98px) {
    .date-news-open__text {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .date-news-open__text {
        font-size: 15px;
    }
}

@media (max-width: 479.98px) {
    .date-news-open__text {
        font-size: 14px;
    }
}

.faq {
    margin: 0 0 0 165px;
    padding: 80px 0 135px 0;
    position: relative;
}

.faq::before {
    position: absolute;
    /*content: "";*/
    width: 655px;
    height: 655px;
    border-radius: 50%;
    top: -50%;
    left: -155px;
    background: rgba(0, 55, 128, 0.8);
    filter: blur(250px);
}

@media (min-width: 991.98px) {
    .faq::before {
        animation: circle 5s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .faq::before {
        filter: blur(350px);
    }
}

.faq::after {
    position: absolute;
    /*content: "";*/
    width: 655px;
    height: 655px;
    border-radius: 50%;
    bottom: -278px;
    right: 0px;
    background: rgba(255, 108, 61, 0.3019607843);
    filter: blur(300px);
}

@media (min-width: 991.98px) {
    .faq::after {
        animation: circle 6s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .faq::after {
        background: #0060DE;
        filter: blur(600px);
        right: -300px;
        width: 500px;
        height: 500px;
        bottom: -125px;
    }
}

@media (max-width: 1700px) {
    .faq {
        padding: 71px 0 119.5px 0;
    }
}

@media (max-width: 1550px) {
    .faq {
        margin: 0 0 0 135px;
        padding: 62px 0 104px 0;
    }
}

@media (max-width: 1500px) {
    .faq {
        margin: 0 0 0 122px;
    }
}

@media (max-width: 1200px) {
    .faq {
        padding: 53.5px 0 88.5px 0;
    }
}

@media (max-width: 991.98px) {
    .faq {
        margin: 0;
        padding: 45px 0 72px 0;
    }
}

@media (max-width: 767.98px) {
    .faq {
        padding: 36.5px 0 56.5px 0;
    }
}

@media (max-width: 479.98px) {
    .faq {
        padding: 28px 0 41px 0;
    }
}

.faq__body {
    position: relative;
    z-index: 5;
    padding: 0 325px 0 100px;
}

@media (max-width: 1700px) {
    .faq__body {
        padding: 0 300px 0 80px;
    }
}

@media (max-width: 1550px) {
    .faq__body {
        padding: 0 250px 0 70px;
    }
}

@media (max-width: 1300px) {
    .faq__body {
        padding: 0 100px 0 60px;
    }
}

@media (max-width: 991.98px) {
    .faq__body {
        padding: 0 100px 0 0px;
    }
}

@media (max-width: 767.98px) {
    .faq__body {
        padding: 0 50px 0 0px;
    }
}

@media (max-width: 600px) {
    .faq__body {
        padding: 0;
    }
}

.faq__label:not(:last-child) {
    margin: 0 0 9px 0;
}

.faq__title:not(:last-child) {
    margin: 0 0 18px 0;
}

@media (max-width: 1550px) {
    .faq__title:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 1200px) {
    .faq__title:not(:last-child) {
        margin: 0 0 12px 0;
    }
}

@media (max-width: 991.98px) {
    .faq__title:not(:last-child) {
        margin: 0 0 9px 0;
    }
}

@media (max-width: 767.98px) {
    .faq__title:not(:last-child) {
        margin: 0 0 6px 0;
    }
}

@media (max-width: 479.98px) {
    .faq__title {
        font-size: 26px;
    }

    .faq__title:not(:last-child) {
        margin: 0 0 3px 0;
    }
}

@media (max-width: 350px) {
    .faq__title {
        font-size: 24px;
    }
}

.faq__subtitle {
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

@media (max-width: 1700px) {
    .faq__subtitle {
        font-size: 19px;
    }
}

@media (max-width: 1500px) {
    .faq__subtitle:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 1200px) {
    .faq__subtitle {
        font-size: 18px;
    }
}

@media (max-width: 991.98px) {
    .faq__subtitle {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .faq__subtitle {
        font-size: 16px;
    }
}

@media (max-width: 350px) {
    .faq__subtitle {
        font-size: 15px;
    }
}

.faq__btn-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    justify-content: flex-end;
}

.faq__btn span {
    margin: 0 10px;
}
.faq__btn img {
    width: 46px;
    height: 46px;
}

@media (max-width: 1700px) {
    .faq__btn img {
        width: 43px;
        height: 43px;
    }
}

@media (max-width: 1550px) {
    .faq__btn img {
        width: 41px;
        height: 41px;
    }
}

@media (max-width: 1200px) {
    .faq__btn img {
        width: 39px;
        height: 39px;
    }
}

@media (max-width: 991.98px) {
    .faq__btn img {
        width: 37px;
        height: 37px;
    }
}

@media (max-width: 767.98px) {
    .faq__btn img {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 479.98px) {
    .faq__btn img {
        width: 33px;
        height: 33px;
    }
}

@keyframes circle {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.contacto {
    margin: 0 0 0 165px;
    padding: 0 0 100px 0;
}

@media (max-width: 1550px) {
    .contacto {
        margin: 0 0 0 135px;
        padding: 0 0 90px 0;
    }
}

@media (max-width: 1500px) {
    .contacto {
        margin: 0 0 0 122px;
        padding: 0 0 80px 0;
    }
}

@media (max-width: 1200px) {
    .contacto {
        padding: 0 0 70px 0;
    }
}

@media (max-width: 991.98px) {
    .contacto {
        margin: 0;
        padding: 0 0 60px 0;
    }
}

@media (max-width: 767.98px) {
    .contacto {
        padding: 0 0 50px 0;
    }
}

@media (max-width: 479.98px) {
    .contacto {
        padding: 0 0 40px 0;
    }
}

@media (max-width: 767.98px) {
    .contacto__container_2 {
        padding: 0;
    }
}

.contacto__images {
    position: relative;
    padding: 0 0 57.4% 0;
}

.contacto__body {
    padding: 0 108px 0 89px;
}

@media (max-width: 1700px) {
    .contacto__body {
        padding: 0 100px 0 89px;
    }
}

@media (max-width: 1550px) {
    .contacto__body {
        padding: 0 100px 0 70px;
    }
}

@media (max-width: 1300px) {
    .contacto__body {
        padding: 0 60px 0 60px;
    }
}

@media (max-width: 1200px) {
    .contacto__body {
        padding: 0 140px 0 60px;
    }
}

@media (max-width: 991.98px) {
    .contacto__body {
        padding: 0 108px 0 0;
    }
}

@media (max-width: 767.98px) {
    .contacto__body {
        padding: 0 80px 0 0;
    }
}

@media (max-width: 600px) {
    .contacto__body {
        padding: 0;
    }
}

.contacto__image-block {
    position: absolute;
}

.contacto__image-block::before {
    position: absolute;
    content: "";
    width: 703px;
    height: 703px;
    bottom: -94px;
    left: -20px;
    border-radius: 50%;
    background-color: #154C93;
    filter: blur(500px);
    z-index: 1;
}

@media (min-width: 991.98px) {
    .contacto__image-block::before {
        animation: circle 6s linear infinite;
    }
}

@media (max-width: 479.98px) {
    .contacto__image-block::before {
        display: none;
    }
}

.contacto__image {
    position: absolute;
    background-size: contain;
    background-position: bottom;
}

.contacto__image_cloud {
    width: 93.8%;
    height: 76.4%;
}

@media (min-width: 991.98px) {
    .contacto__image_cloud {
        z-index: 0;
    }
}

@media (max-width: 479.98px) {
    .contacto__image_cloud {
        width: 100%;
    }
}

.contacto__image_dragon {
    width: 70%;
    height: 87.3%;
    bottom: 3.5%;
    left: 7.5%;
}

.contacto__row {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: flex-start;
}

@media (max-width: 1200px) {
    .contacto__row {
        flex-direction: column;
    }
}

.contacto__content {
    position: relative;
    padding: 30px 0 0 0;
}

@media (max-width: 1200px) {
    .contacto__content {
        padding: 0;
    }

    .contacto__content:not(:last-child) {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 991.98px) {
    .contacto__content:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

.contacto__title:not(:last-child) {
    margin: 0 0 30px 0;
}

@media (max-width: 1700px) {
    .contacto__title:not(:last-child) {
        margin: 0 0 25px 0;
    }
}

@media (max-width: 1200px) {
    .contacto__title:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 991.98px) {
    .contacto__title:not(:last-child) {
        margin: 0 0 15px 0;
    }
}

@media (max-width: 767.98px) {
    .contacto__title:not(:last-child) {
        margin: 0 0 10px 0;
    }
}

@media (max-width: 479.98px) {
    .contacto__title:not(:last-child) {
        margin: 0 0 5px 0;
    }
}

.contacto__subtitle {
    font-size: 20px;
    line-height: 175%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

@media (max-width: 1700px) {
    .contacto__subtitle {
        font-size: 19px;
    }
}

@media (max-width: 1200px) {
    .contacto__subtitle {
        font-size: 18px;
    }
}

@media (max-width: 991.98px) {
    .contacto__subtitle {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .contacto__subtitle {
        font-size: 16px;
    }
}

.contacto__btns {
    display: flex;
    align-items: center;
}

.contacto__content {
    /*margin-left: 210px;*/
}


/*@media (max-width: 1700px) {*/
/*    .contacto__content {*/
/*        margin-left: 160px;*/
/*    }*/
/*}*/

/*@media (max-width: 1550px) {*/
/*    .contacto__content {*/
/*        margin-left: 100px;*/
/*    }*/
/*}*/

/*@media (max-width: 1300px) {*/
/*    .contacto__content {*/
/*        margin-left: 70px;*/
/*    }*/
/*}*/

/*@media (max-width: 1200px) {*/
/*    .contacto__content {*/
/*        margin-left: 50px;*/
/*    }*/
/*}*/

/*@media (max-width: 479.98px) {*/
/*    .contacto__content {*/
/*        margin-left: 30px;*/
/*    }*/
/*}*/

/*@media (max-width: 350px) {*/
/*    .contacto__content {*/
/*        margin-left: 10px;*/
/*    }*/
/*}*/

.contacto__btn:first-child img {
    width: 36px;
    height: 36px;
}

@media (max-width: 1200px) {
    .contacto__btn:first-child img {
        width: 35px;
        height: 33px;
    }
}

@media (max-width: 991.98px) {
    .contacto__btn:first-child img {
        width: 34px;
        height: 31px;
    }
}

@media (max-width: 479.98px) {
    .contacto__btn:first-child img {
        width: 33px;
        height: 29px;
    }
}

.contacto__btn:nth-child(2) {
    margin: 0 0 0 40px;
}

.contacto__btn:nth-child(2) img {
    width: 38px;
    height: 30px;
}

@media (max-width: 1200px) {
    .contacto__btn:nth-child(2) {
        margin: 0 0 0 30px;
    }

    .contacto__btn:nth-child(2) img {
        width: 34px;
        height: 27px;
    }
}

@media (max-width: 767.98px) {
    .contacto__btn:nth-child(2) img {
        width: 31px;
        height: 24px;
    }
}

@media (max-width: 479.98px) {
    .contacto__btn:nth-child(2) {
        margin: 0 0 0 20px;
    }

    .contacto__btn:nth-child(2) img {
        width: 28px;
        height: 22px;
    }
}

.donaciones {
    position: relative;
    z-index: 1;
    margin: -1.7% 0 0 165px;
    padding: 0 0 100px 0;
}

@media (max-width: 1700px) {
    .donaciones {
        padding: 0 0 90px 0;
    }
}

@media (max-width: 1550px) {
    .donaciones {
        margin: -1.7% 0 0 135px;
        padding: 0 0 80px 0;
    }
}

@media (max-width: 1500px) {
    .donaciones {
        margin: -1.7% 0 0 122px;
    }
}

@media (max-width: 1200px) {
    .donaciones {
        padding: 0 0 70px 0;
    }
}

@media (max-width: 991.98px) {
    .donaciones {
        margin: -1.7% 0 0 0;
        padding: 0 0 60px 0;
    }
}

@media (max-width: 767.98px) {
    .donaciones {
        padding: 0 0 50px 0;
    }
}

@media (max-width: 479.98px) {
    .donaciones {
        margin: 0;
        padding: 0 0 40px 0;
    }
}

.donaciones__body {
    border-radius: 50px;
    background: rgba(8, 28, 54, 0.6980392157);
    padding: 90px 100px;
}

@media (max-width: 1700px) {
    .donaciones__body {
        padding: 80px 86px;
    }
}

@media (max-width: 1550px) {
    .donaciones__body {
        padding: 70px 73px;
    }
}

@media (max-width: 1200px) {
    .donaciones__body {
        padding: 60px 60px;
    }
}

@media (max-width: 991.98px) {
    .donaciones__body {
        padding: 50px 46px;
    }
}

@media (max-width: 767.98px) {
    .donaciones__body {
        padding: 40px 33px;
    }
}

@media (max-width: 479.98px) {
    .donaciones__body {
        padding: 30px 20px;
    }
}

.donaciones__label:not(:last-child) {
    margin: 0 0 28px 0;
}

@media (max-width: 1700px) {
    .donaciones__label:not(:last-child) {
        margin: 0 0 24px 0;
    }
}

@media (max-width: 1550px) {
    .donaciones__label:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 1200px) {
    .donaciones__label:not(:last-child) {
        margin: 0 0 16px 0;
    }
}

@media (max-width: 991.98px) {
    .donaciones__label:not(:last-child) {
        margin: 0 0 12px 0;
    }
}

@media (max-width: 767.98px) {
    .donaciones__label:not(:last-child) {
        margin: 0 0 8px 0;
    }
}

@media (max-width: 479.98px) {
    .donaciones__label:not(:last-child) {
        margin: 0 0 4px 0;
    }
}

.donaciones__title:not(:last-child) {
    margin: 0 0 24px 0;
}

@media (max-width: 1550px) {
    .donaciones__title:not(:last-child) {
        margin: 0 0 20px 0;
    }
}

@media (max-width: 1200px) {
    .donaciones__title:not(:last-child) {
        margin: 0 0 16px 0;
    }
}

@media (max-width: 991.98px) {
    .donaciones__title:not(:last-child) {
        margin: 0 0 12px 0;
    }
}

@media (max-width: 767.98px) {
    .donaciones__title:not(:last-child) {
        margin: 0 0 8px 0;
    }
}

@media (max-width: 479.98px) {
    .donaciones__title:not(:last-child) {
        margin: 0 0 4px 0;
    }
}

.donaciones__sibtitle {
    max-width: 1000px;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.donaciones__sibtitle a {
    color: #7289da;
}

.donaciones__sibtitle:not(:last-child) {
    margin: 0 0 26px 0;
}

@media (max-width: 1700px) {
    .donaciones__sibtitle {
        font-size: 19px;
    }
}

@media (max-width: 1200px) {
    .donaciones__sibtitle {
        font-size: 18px;
    }
}

@media (max-width: 991.98px) {
    .donaciones__sibtitle {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .donaciones__sibtitle {
        font-size: 16px;
    }

    .donaciones__sibtitle:not(:last-child) {
        margin: 0 0 22px 0;
    }
}

.donaciones__row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
}

.donaciones__row:not(:last-child) {
    margin: 0 0 44px 0;
}

@media (max-width: 1700px) {
    .donaciones__row {
        margin: 0 0 42px 0;
    }
}

@media (max-width: 1550px) {
    .donaciones__row {
        margin: 0 0 40px 0;
    }
}

@media (max-width: 1200px) {
    .donaciones__row {
        margin: 0 0 38px 0;
    }
}

@media (max-width: 991.98px) {
    .donaciones__row {
        margin: 0 0 36px 0;
    }
}

@media (max-width: 767.98px) {
    .donaciones__row {
        margin: 0 0 34px 0;
    }
}

@media (max-width: 479.98px) {
    .donaciones__row {
        margin: 0 0 32px 0;
    }
}

.donaciones__column:not(:last-child) {
    margin: 0 25px 0 0;
}

@media (max-width: 1200px) {
    .donaciones__column:not(:last-child) {
        margin: 0 22px 0 0;
    }
}

@media (max-width: 767.98px) {
    .donaciones__column:not(:last-child) {
        margin: 0 18px 0 0;
    }
}

.donaciones__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    width: 172px;
    height: 172px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    overflow: hidden;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.donaciones__item:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}

@media (max-width: 1700px) {
    .donaciones__item {
        width: 141.5px;
        height: 141.5px;
    }

    /*.donaciones__item img {*/
    /*    -moz-transform: scale(0.82);*/
    /*    -ms-transform: scale(0.82);*/
    /*    -webkit-transform: scale(0.82);*/
    /*    -o-transform: scale(0.82);*/
    /*    transform: scale(0.82);*/
    /*}*/
}

@media (max-width: 1550px) {
    .donaciones__item {
        width: 121px;
        height: 121px;
    }

    /*.donaciones__item img {*/
    /*    -moz-transform: scale(0.7);*/
    /*    -ms-transform: scale(0.7);*/
    /*    -webkit-transform: scale(0.7);*/
    /*    -o-transform: scale(0.7);*/
    /*    transform: scale(0.7);*/
    /*}*/
}

@media (max-width: 1200px) {
    .donaciones__item {
        width: 110.5px;
        height: 110.5px;
    }

    /*.donaciones__item img {*/
    /*    -moz-transform: scale(0.64);*/
    /*    -ms-transform: scale(0.64);*/
    /*    -webkit-transform: scale(0.64);*/
    /*    -o-transform: scale(0.64);*/
    /*    transform: scale(0.64);*/
    /*}*/
}

@media (max-width: 991.98px) {
    .donaciones__item {
        width: 90px;
        height: 90px;
    }

    /*.donaciones__item img {*/
    /*    -moz-transform: scale(0.52);*/
    /*    -ms-transform: scale(0.52);*/
    /*    -webkit-transform: scale(0.52);*/
    /*    -o-transform: scale(0.52);*/
    /*    transform: scale(0.52);*/
    /*}*/
}

@media (max-width: 767.98px) {
    .donaciones__item {
        width: 69.5px;
        height: 69.5px;
    }

    /*.donaciones__item img {*/
    /*    -moz-transform: scale(0.4);*/
    /*    -ms-transform: scale(0.4);*/
    /*    -webkit-transform: scale(0.4);*/
    /*    -o-transform: scale(0.4);*/
    /*    transform: scale(0.4);*/
    /*}*/
}

@media (max-width: 479.98px) {
    .donaciones__item {
        width: 49px;
        height: 49px;
    }

    /*.donaciones__item img {*/
    /*    -moz-transform: scale(0.28);*/
    /*    -ms-transform: scale(0.28);*/
    /*    -webkit-transform: scale(0.28);*/
    /*    -o-transform: scale(0.28);*/
    /*    transform: scale(0.28);*/
    /*}*/
}

.link-donaciones {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    width: 100%;
    max-width: 575px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    padding: 20px;
}

@media (max-width: 1200px) {
    .link-donaciones {
        padding: 17.5px;
    }
}

@media (max-width: 479.98px) {
    .link-donaciones {
        padding: 15px;
    }
}

.link-donaciones__image {
    flex: 0 0 112px;
    width: 112px;
    height: 112px;
    border-radius: 15px;
    overflow: hidden;
    margin: 0 30px 0 0;
}

.link-donaciones__image img {
    max-width: 100%;
}

@media (max-width: 1700px) {
    .link-donaciones__image {
        flex: 0 0 103.5px;
        width: 103.5px;
        height: 103.5px;
    }
}

@media (max-width: 1550px) {
    .link-donaciones__image {
        margin: 0 25px 0 0;
        flex: 0 0 96px;
        width: 96px;
        height: 96px;
    }
}

@media (max-width: 1200px) {
    .link-donaciones__image {
        flex: 0 0 88.5px;
        width: 88.5px;
        height: 88.5px;
    }
}

@media (max-width: 991.98px) {
    .link-donaciones__image {
        margin: 0 20px 0 0;
        flex: 0 0 81px;
        width: 81px;
        height: 81px;
    }
}

@media (max-width: 767.98px) {
    .link-donaciones__image {
        flex: 0 0 73.5px;
        width: 73.5px;
        height: 73.5px;
    }
}

@media (max-width: 479.98px) {
    .link-donaciones__image {
        margin: 0 15px 0 0;
        flex: 0 0 66px;
        width: 66px;
        height: 66px;
    }
}

.link-donaciones__text {
    font-size: 20px;
    line-height: 160%;
    color: #FFFFFF;
    text-decoration: underline;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.link-donaciones__text:hover {
    text-decoration: none;
}

@media (max-width: 1700px) {
    .link-donaciones__text {
        font-size: 19px;
    }
}

@media (max-width: 1200px) {
    .link-donaciones__text {
        font-size: 18px;
    }
}

@media (max-width: 991.98px) {
    .link-donaciones__text {
        font-size: 17px;
    }
}

@media (max-width: 479.98px) {
    .link-donaciones__text {
        font-size: 16px;
    }
}


main {
    min-height: 100vh;
    right: 0;
    width: 100%;
}

.fade-enter-active,
.fade-enter {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
}

.fade-exit {
    opacity: 0;
    transition: all .3s ease;
}

.fade-enter-done {
    opacity: 1;
    transition: all .3s ease;
}

.game-users {
    margin-left: 0;
}

.users__name {
    margin-left: 20px;
}


.kassa_methods li:hover {
    background: #ff6d3d54;
}
.kassa_methods li {
    border: 1px solid #000;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: all .2s ease;
}
.inner-popup__payment-kassa_methods legend {
    display: flex;
    align-items: center;
    /* margin: 15px 0; */
}
.inner-popup__payment-kassa_methods label {
    width: 100%;
    display: block;
    max-width: 120px;
}
.minmaxlimits {
    font-size: 16px;
    width: 100%;
    line-height: 1.5;
}
.inner-popup__payment-kassa_methods input,
.inner-popup__payment-kassa_methods legend {
    width: 100%;
    position: relative;
}
.inner-popup__payment-kassa_methods {
    margin-bottom: 20px;
}
.error-kassa-phone {
    color: red;
    font-size: 16px;
    position: absolute;
    bottom: -20px;
}
.inner-popup__payment-kassa_methods select {
    border: 2px solid #bebebe;
    padding: 10px 15px;
    border-radius: 6px;
    font-size: 16px;
}
.kassa_methods li.kassa_method_item.active {
    background: #FF6C3D;
    color: #fff;
}
.inner-popup__payment-kassa_methods h3 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}
.kassa_methods {
    /* display: flex;
    justify-content: space-between;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
}

.inner-popup__payment-kassa_methods {
    display: block;
}

.inner-popup__payment-kassa_methods .characteristics-select-product__dropdown.dropdown.active .dropdown__button::before {
    transform: rotate(0deg);
}


